<template>
  <div>
    <v-container fluid >
      <v-row class="align-center justify-center">
        <v-col cols="8">
          <div style="height: 890px; overflow-y: auto;">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>Locations</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <!-- Falls ein zweiter Such-Input benötigt wird, kann er hier eingeblendet werden -->
              <v-btn color="green" fab small dark class="mb-2" @click="createItem">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-dialog v-model="dialog" max-width="750px" persistent>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" v-if="locationTypes">
                            <v-autocomplete
                              :items="locationTypes"
                              item-text="value"
                              item-value="_id"                             
                              label="Location Type" 
                              v-model="editedItem.type"
                              v-validate="'required'"
                              data-vv-name="form_location_type"
                              :error-messages="errors.collect('form_location_type')"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-autocomplete
                              :items="locationNames" 
                              label="Parent_Location" 
                              v-model="parent_location_index"
                              @change="set_parent_location"
                              item-text="name"
                              item-value="index"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.icon" label="icon"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.active" label="active"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.sortorder" label="sortorder"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.attributes" label="attributes"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" v-if="check_room(editedItem.type)">
                            <v-checkbox v-model="editedItem.createHA" label="Create HeatArea"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="filteredLocations"
              class="elevation-1"
              :search="search"
              :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
              :items-per-page="50"
              sort-by="name"
              must-sort
            >
              <template v-slot:item="{ item }" class="text-xs-right">
                <tr>
                  <td>{{ item.name }}</td>
                  <td>{{ item.active }}</td>
                  <!-- <td>{{ comp_location(item.parent) }}</td> -->
                  <td>{{ item.projectName }}</td>
                  <td>{{ item.parentParentName }}</td>
                  <td>{{ item.parentName }}</td>
                  <!-- <td>{{ comp_locationTypes(item.type) }}</td> -->
                  <td>{{ item.typeName }}</td>
                  <!-- Falls user-Daten benötigt werden, können diese hier eingeblendet werden -->
                  <td>
                    <v-list dense>
                      <draggable 
                        v-model="item.user" 
                        :options="{ group: 'people' }" 
                        style="min-height: 10px"
                        @change="logDrop(item._id)"
                      >
                        <div v-for="usr in item.user"  :key="usr._id">
                          <v-list-item>
                            <v-list-item-content class="caption">
                              <v-list-item-title>{{ comp_user_id(usr).displayname }}</v-list-item-title>
                              <v-list-item-subtitle>{{ comp_user_id(usr).email }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </draggable>
                    </v-list>
                  </td>
                  <td>
                    <v-list dense>
                      <draggable 
                        v-model="item.devices" 
                        :options="{ group: 'devices' }" 
                        style="min-height: 10px"
                        @change="logDropDevices($event, item._id)"
                      >
                        <div v-for="dev in item.devices" :key="dev._id">
                          <v-list-item >
                            <v-list-item-content class="caption">
                              <v-list-item-title>{{ dev.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </draggable>
                    </v-list>
                  </td>
                  <td>{{ item.icon }}</td>
                  <td>{{ item.sortorder }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small @click="deleteItem(item)">mdi-cancel</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-col>

        <!-- Users List -->
        <v-col cols="2" class="caption pa-0 ma-0 elevation-1">
          <div style="height: 890px; overflow-y: auto;">
            <v-list dense>
              <p class="title">Users</p>
              <v-divider></v-divider>
              <draggable 
                :list="sortedUsersId" 
                :options="{ group: { name: 'people', pull: 'clone', put: true } }"
                style="min-height: 10px"
              >
                <div v-for="usr in sortedUsers" :key="usr._id">
                  <v-list-item >
                    <v-list-item-content>
                      <div v-if="usr.location">
                        <v-list-item-title class="caption blue--text">
                          {{ usr.displayname }} ({{ usr.email }} / {{ usr.location }})
                        </v-list-item-title>
                      </div>
                      <div v-else>
                        <v-list-item-title class="caption">
                          {{ usr.displayname }} ({{ usr.email }})
                        </v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </draggable>
            </v-list>
          </div>
        </v-col>

        <!-- Devices List -->
        <v-col cols="2" class="caption pa-0 ma-0 elevation-1">
          <div style="height: 890px; overflow-y: auto;">
            <v-list dense>
              <p class="title">Devices</p>
              <v-divider></v-divider>
              <draggable 
                :list="devices" 
                :options="{ group: { name: 'devices', pull: 'clone', put: true } }"
                style="min-height: 10px"
              >
                <div v-for="dev in devices" :key="dev.id">
                  <v-list-item >
                    <v-list-item-content>
                      <div v-if="dev.location">
                        <v-list-item-title class="caption blue--text">
                          {{ dev.name }} ({{ dev.device_id }} / {{ dev.locationName }})
                        </v-list-item-title>
                      </div>
                      <div v-else>
                        <v-list-item-title class="caption">
                          {{ dev.name }} ({{ dev.device_id }})
                        </v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </draggable>
            </v-list>
          </div>
        </v-col>
      </v-row>

      <!-- Hierarchy & PowerDevices Section -->
      <v-row pt-5>
        <v-col cols="8">
          <br>
          <p class="title">Hierarchy</p>
          <v-divider></v-divider>
          <!-- Falls du eine verschachtelte Drag&Drop-Komponente nutzt: -->
          <nested-draggable :child="treeLocations" />
        </v-col>
        <v-col cols="2">
          <br>
          <p class="title">My PowerDevices</p>
          <v-divider></v-divider>
          <div v-for="dev in powerDevicesOnly" :key="dev._id">
            {{ dev.name }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
/* eslint-disable */
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import api from "../api";
import draggable from 'vuedraggable'
import nestedDraggable from "./nested";

export default {
  name: "location",
  components: {
    draggable,
    nestedDraggable
  },
  data() {
    return {
      devices: [],
      /*
      treeDrop: {
        item: "",
        parent: ""
      },*/
      location: {},
      locations: [],
      dialog: false,
      search: '',
      search2: '',
      parent_location_index: -1,
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        { text: 'active', value: 'active' },
        // { text: 'Parent', value: 'parent' },
         { text: 'Project', value: 'projectName' },
        { text: 'GrandParent', value: 'parentParentName' },
        { text: 'Parent', value: 'parentName' },
        // { text: 'type', value: 'type' },
        { text: 'typeName', value: 'typeName' },
        { text: 'user', value: 'user' },      
        { text: 'devices', value: 'device' },
        { text: 'icon', value: 'icon' },
        { text: 'sortorder', value: 'sortorder' },
        { text: 'Actions', value: 'name', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        active: true,
        parent: null,
        type: '',
        user: [],
        icon: null,
        sortorder: null,
        attributes: null,
        createHA: true
      },
      defaultItem: {
        name: '',
        active: true,
        parent: null,
        type: '',
        user: [],
        icon: null,
        sortorder: null,
        attributes: null,
        createHA: true
      },
      sortedUsersId: [],
      sortedUsers: [],
      /*items2: [] ,*/
      treeLocations: [],
      projectId: "",
      locationNames: [],
     /* treeLocations2:  [
        {
          name: "task 1",
          _id: 1,
          child: [
            {
              name: "task 2",
              _id: 2,
              child: []
            }
          ]
        },
        {
          name: "task 3",
          _id: 3,
          child: [
            {
              name: "task 4",
              _id: 4,
              child: [
                {
                  name: "task 6",
                  _id: 6,
                  child: [
                    {
                      name: "task 7",
                      _id: 7,
                      child: []
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: "task 5",
          _id: 5,
          child: []
        }
      ]*/
    }
    
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(
      "location", [
        "getLocation",
        "deleteLocation",
        "updateLocation",
        "createLocation",
        "deactivateLocation"
        ]
      ),
    ...mapActions(
      "user", ["getUser"]
      ),
    ...mapActions(
      "device", [
        "getDevicesAll",
        "updateDeviceLocation",
        "getPowerDevicesOnly",
        ]
      ),
    ...mapActions(
      "global", ["getGlobal"]
      ),
    async initData() {
      if (this.Global.length == 0) {
        await this.getGlobal({});
      }
      this.projectId = (_.find(this.Global.locationTypes, {value: "project"}))._id;
      await this.getLocation({})
      this.genLocationNamesWithProject();
      await this.getUser({})
      await this.getDevicesAll({})
      await this.getPowerDevicesOnly({})  
    },
    editItem (item) {
      this.editedIndex = this.Locations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.parent_location_index = this.Locations.findIndex((loc) => {return loc._id == this.editedItem.parent})
      this.editedItem.attributes = JSON.stringify(this.editedItem.attributes)
      this.dialog = true
    },

    createItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.parent_location_index = -1;
      this.dialog = true
    },


    async deleteItem (item) {
      const index = this.Locations.indexOf(item)
      console.log("ITEM: " + item._id)
      confirm('Are you sure you want to delete this item?') && await this.deleteLocation(item._id)
      await this.getLocation({})
    },

    async deactivateItem (item) {
      const index = this.Locations.indexOf(item)
      //console.log("ITEM: " + item._id)
      //confirm('Are you sure you want to deactivate this item?') && 
      await this.deactivateLocation(item._id)
      await this.getLocation({})
    },

    async activateItem (item) {
      const index = this.Locations.indexOf(item)
      await this.deactivateLocation(item._id)
      await this.getLocation({})
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    async save () {
      //console.log(this.editItem)
      try {
        if (this.editedIndex > -1) {
          if (typeof this.editedItem.attributes == "string") {
            this.editedItem.attributes = JSON.parse(this.editedItem.attributes);
          }
          await this.updateLocation(this.editedItem)
        } else {  //new item
          if (typeof this.editedItem.attributes == "string") {
            this.editedItem.attributes = JSON.parse(this.editedItem.attributes);
          }
          await this.createLocation(this.editedItem);

          if (this.editedItem.createHA && this.check_room(this.editedItem.type)) {
            let ha = JSON.parse(JSON.stringify(this.editedItem));
            ha.name = "HA " + ha.name;
            ha.type = (_.find(this.Global.locationTypes, {value: "heatarea"}))._id;
            // console.log("CREATE HA: ", this.newLocation._id)
            ha.parent = this.newLocation._id;
            await this.createLocation(ha);
          }
        }
        await this.getLocation({});
        this.genLocationNamesWithProject();
        this.close()
      } catch (err) {
        console.log(err);
      }
    },

    comp_user (users) {
      try {
        let user_array = []
        //console.log(this.Users)
        users.forEach(obj => {
          user_array.push(this.Users.filter(fobj => {return fobj._id == obj})[0].email)
        }) 
        return user_array
      }
      catch {
        return []
      }
    },
    comp_user_id (id) {
      //console.log(id)
      try {
        return {displayname: this.Users.filter(fobj => {return fobj._id == id})[0].displayname , email: this.Users.filter(fobj => {return fobj._id == id})[0].email}
      }
      catch {
        return ""
      }
    },

    comp_location (id) {
      try {
        return this.locationNames.filter(obj => {return obj._id == id})[0].name      
      }
      catch {
        return ""
      }
    },

    comp_locationTypes (id) {
      //console.log(this.Global.locationTypes)
      try {
         return this.Global.locationTypes.filter(obj => {return obj._id == id})[0].value;
      }
      catch {
        return ""
      }
    },

    check_room (type) {
      if ((_.find(this.Global.locationTypes, {value: "room"}))._id == type) {
        return true;
      } else {
        return false;
      }
    },
    /*findItemNested(arr, itemId, nestingKey) {
      //console.log(arr)
      arr.reduce((a, item) => {
          if (a) return a
          if (item._id === itemId) return item
          if (item[nestingKey]) return this.findItemNested(item[nestingKey], itemId, nestingKey)
      })
    },

    findById(arr, id, nestingKey) {
      
      // if empty array then return
      if(arr.length == 0) return
      
      // return element if found else collect all children(or other nestedKey) array and run this function
      return arr.find(d => d._id == id) 
          || this.findById(arr.flatMap(d => d[nestingKey] || []), id)
          || null
    },*/

    customFilter(object, _id){
      if (object) {
        if(object.hasOwnProperty('_id') && object["_id"] == _id)
            return object

        for(let i=0; i<Object.keys(object).length; i++){
            if(typeof object[Object.keys(object)[i]] == "object"){
                let o = this.customFilter(object[Object.keys(object)[i]], _id)
                if(o != null)
                    return o
            }
        }
      }
      return null
    },

    comp_tree_location () {
        this.treeLocations = _.cloneDeep(this.Locations.filter(obj => {return obj.parent == null}))

        this.treeLocations.forEach(loc => {
          loc.type_name = this.comp_locationTypes(loc.type)
          loc.child = []
        })
        let tree = _.sortBy(_.cloneDeep(this.Locations.filter(obj => {return obj.parent != null})), "name");     
        let iteration = 0
        
        while (tree.length > 0) {
          let treeindex = []
          tree.forEach( (loc,index) => {

            let findobj = this.customFilter(this.treeLocations, loc.parent)

            if (findobj) {
              loc.child = []
              loc.type_name = this.comp_locationTypes(loc.type)
              findobj.child.push(loc)
              treeindex.push(index)
            }       
          })
          _.pullAt(tree, treeindex)
          iteration++
          if (iteration > 1000) break
        }
    },

    set_parent_location (index) {
      this.editedItem.parent = this.Locations[index]._id
    },

    cloneUser(item) {
      console.log("Clone")
      //console.log(item)
      //console.log(index)
      /*if(findIndex(this.items2, function(o) { return o._id == item._id; }) == -1) {
        return item._id
      }*/
    },

    async logDrop(item) {
      console.log("Drop")
      //console.log(item)
      this.editedIndex = _.findIndex(this.Locations, function(o) { return o._id == item; })
      this.editedItem = this.Locations[this.editedIndex]
      //console.log(this.editedIndex)
      //console.log(this.editedItem)
      await this.save()
      
      //console.log(this.editedIndex)
      this.editedIndex = -1
      this.editedItem = []
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId)
      
      this.getPowerDevicesOnly({})

    },
    async logDropDevices(item, locationId) {
      console.log("DropDevices ", item)
      if(item.added) {
        await this.saveDeviceLocation(item.added.element._id, locationId)
      }
      else if (item.removed) {
        await this.saveDeviceLocation(item.removed.element._id, null)
      }
      
      await this.getDevicesAll({})
      this.getPowerDevicesOnly({})

    },
    logEnd(item) {
      console.log("End")
      console.log(item)
    },

    logMove(item) {
      console.log("Move")
      console.log(item)
    },

    changeTree(item) {
      console.log("TREE")
      console.log(item)
    },
    async saveDeviceLocation(deviceId, locationId) {
      //console.log({deviceId, locationId})
      await this.updateDeviceLocation({deviceId, locationId})
    },
    getProjectOf(loc) {
      let _loc = JSON.parse(JSON.stringify(loc));
      try {
        if (_loc) {
          while (_loc.type != this.projectId) {
            _loc = _.find(this.Locations, {_id: _loc.parent});
            if (!_loc) break;
          }

          if (_loc) {
            return _loc.name;
          } else {
            return "";
          }
        } else {
          return "";
        }      
      } catch(err) {
        console.log("getProjectOf Error ", err, _loc);
        return "";
      } 
    },
    genLocationNamesWithProject () {
      // if (this.Locations.length > 0) {
        let locnames = [{name: "", index: -1}]
        this.Locations.forEach((loc, index) => {
          const projectname = this.getProjectOf(loc);
          let parent = "";
          if (loc.parent) {
            parent = this.Locations.filter(obj => {return obj._id == loc.parent})[0];
          }
          let grandParent = "";
          if (parent.parent) {
            grandParent = this.Locations.filter(obj => {return obj._id == parent.parent})[0];
          }
          //console.log(parentName, loc.parent)
          if (projectname == loc.name || projectname == "") {
            locnames.push({name: loc.name, index: index, _id: loc._id});
          } else {
            locnames.push({name: loc.name + " (" + projectname + "/" + grandParent.name + "/" + parent.name + ")", index: index, _id: loc._id});
          }
        })
        this.locationNames = _.sortBy(locnames,"name");
      // } else {
      //   return []
      // }
    },
  },
  computed: {
    ...mapState({
      Locations: ({ location }) => location.Locations,
      Users: ({ user }) => user.Users,
      moveLocation: ({ location }) => location.moveLocation,
      newLocation: ({ location }) => location.newLocation,
      devicesAll: ({device}) => device.devicesAll,
      powerDevicesOnly: ({ device }) => device.powerDevicesOnly,
      Global: ({ global }) => global.Global
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    filteredLocations() {
      /*return this.Locations.filter((p) => {
        return p.active === true
      })
      */
      //console.log("filteredLocations")
      this.locations = [];
      // const search1 = this.search.toString().toLowerCase();
      // const search2 = this.search2.toString().toLowerCase();

      for (let loc of this.Locations) {

        // const keys = Object.keys(loc);
        // let found = false;
        // for (const key of keys) {
        //   if (typeof loc[key] == "string") {
        //     if (loc[key].toLowerCase().includes(search1) || loc[key].toLowerCase().includes(search2)) {
        //       found = true;
        //     }
           
        //   }
        // }
        // if (found || (search1 == "" && search2 == "")) {
        const parentObject = this.Locations.filter(obj => {return obj._id == loc.parent})[0];
        if (parentObject) {
          loc.parentName = parentObject.name;
          if (parentObject.parent) {
            loc.parentParentName = this.Locations.filter(obj => {return obj._id == parentObject.parent})[0].name;
          }
        }
        loc.typeName = this.comp_locationTypes(loc.type);
        loc.projectName = this.getProjectOf(loc);
        this.locations.push(loc);
        // }
      }
      //this.locations = this.Locations
      //this.locations = _.cloneDeep(this.Locations)
      // let d = new Date();
      // let ms = d.getMilliseconds();
      //console.log(d, ms);
      this.locations.forEach(loc => {
        loc.devices = [];

        let devices = _.filter(this.devicesAll, {location:loc._id});

       
       /* this.devicesAll.forEach(device => {
          if(loc._id === device.location) {
            loc.devices.push(device);
          }
        })*/
        devices.forEach(device => {
          device.locationName = loc.name;
          loc.devices.push(device);
        })
       
        loc.user.forEach(user_id => {
          let user = _.find(this.Users, {_id: user_id});       
          if (user) {
            //console.log("Found User in Location: " + loc.name + " User: " + user.displayname);
            user.location = loc;
          }
        })
      })
      // d = new Date();
      // ms = d.getMilliseconds();
      // console.log(d, ms);
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId);
      this.sortedUsers = _.cloneDeep(this.compSortedUsers);
      this.devices = _.sortBy(_.cloneDeep(this.devicesAll), "name");
      
      // const f = this.locations.filter(obj => {return obj.name.toLowerCase().includes(search1) || obj.name.toLowerCase().includes(search2)})
      // return f;
      return this.locations;
    },

    

    // locationNames () {
    //   if (this.Locations.length > 0) {
    //     let locnames = [{name: "", index: -1}]
    //     this.Locations.forEach((loc, index) => {
    //       let projectname = this.getProjectOf(loc);
    //       locnames.push({name: projectname + loc.name, index: index})
    //     })
    //     return _.sortBy(locnames,"name");
    //   } else {
    //     return []
    //   }
    // },
    locationTypes () {
      return _.sortBy(this.Global.locationTypes,"value");
    },
    compSortedUsers () {
      const _users = _.filter(this.Users, {activated: true});
      let sortedUser = _.sortBy(_users, 'displayname');
      return sortedUser;
    },
    compSortedUsersId () {
      const _users = _.filter(this.Users, {activated: true});
      let sorted = _.sortBy(_users, 'displayname')
      let result = []
      sorted.forEach(obj => {
        result.push(obj._id)
      })
      this.sortedUsersId = _.cloneDeep(result)
      return result
    }
    
    /*,
    filteredUsers() {
      this.users = this.Users
      return this.users
    },*/

  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    locations () {
      this.comp_tree_location()
    },
    Users () {
      //console.log("Users loaded")
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId)
      this.sortedUsers = _.cloneDeep(this.compSortedUsers)
    },
    devicesAll () {
      this.devices = _.sortBy(_.cloneDeep(this.devicesAll), "name");
    },
    /*treeDrop: {   //funkt bei root nicht deshalb über store -> moveLocation
      deep: true,

      handler() {
        console.log ("WATCH LastChanged")
        console.log(this.treeDrop)   
        this.treeLocations = _.cloneDeep(this.treeLocations)
      }
    },*/
    moveLocation () {
      this.editedIndex = this.Locations.findIndex((loc) => {return loc._id == this.moveLocation.item})
      this.editedItem = this.Locations[this.editedIndex]
      this.editedItem.parent = this.moveLocation.parent
      this.treeLocations = _.cloneDeep(this.treeLocations)
      this.save()

      this.getPowerDevicesOnly({})
    }
  }
}

</script>