<template>
    <v-container fill-height>
        <v-row class="align-center justify-center" >
            <v-col  style="max-width:500px">
                <v-card v-if="registerSuccess" textcolor="transparent" flat>
                  <v-alert type="info" :value="registerSuccess" >Account created</v-alert>
                  <v-card-text class="text-center">Thank you for signing up. You will receive a verification e-mail.</v-card-text>
                  <v-btn outlined roundedcolor="primary"
                    @click="backToLogin()">{{$t("Back")}}
                  </v-btn>
                </v-card>
                
                <v-card v-else textcolor="transparent" flat>
                    <v-card-text>
                        <v-card>
                            <v-card-title class="title">{{$t("Sign Up")}}</v-card-title>
                            <v-card-text>
                                <v-alert type="error" :value="status === 'failure'" >Something went wrong</v-alert>
                                <v-alert type="info" :value="registerSuccess" >Account created</v-alert>
                            </v-card-text>
                            <v-card-text>
                                <v-form ref="form" v-model="valid" style="width:100%;height:100%;">
                                  <v-text-field :rules="requiredRule" required v-model="displayname" 
                                                label="Display Name"></v-text-field>
                                  <v-text-field :rules="emailRule" required v-model="email" 
                                                label="E-Mail"></v-text-field>
                                  <v-text-field :append-icon="show ? 'visibility_off' : 'visibility'" :type="show ? 'text' : 'password'" @click:append="show = !show" :rules="passwordRule" required 
                                                v-model="password" label="Password"></v-text-field>
                                  <v-checkbox
                                    v-model="dsgvo"
                                    :rules="requiredRule"
                                    required
                                  >
                                    <template v-slot:label>
                                      <div>
                                        Ich stimme bis auf Widerruf der 
                                            <a
                                              target="_blank"
                                              href="/dsgvo"
                                              @click.stop
                                            >
                                              Datenschutzerklärung
                                            </a>
                                        zu.
                                      </div>
                                    </template>
                                  </v-checkbox>
                                  <vue-hcaptcha 
                                    :sitekey="hcaptcha_sitekey" 
                                    size="normal"
                                    tabindex=1
                                    @verify="onVerify"
                                    @error="onError"
                                    @expired="onExpired">
                                  </vue-hcaptcha>
                                </v-form>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn outlined rounded  color="primary"
                                @click="startSignUp({email, password, displayname, dsgvo})">{{$t("Sign Up")}}
                              </v-btn>
                              <!-- :disabled="!valid" -->
                            </v-card-actions>
                        </v-card>
                        <p align="center">
                          <br/><router-link :to="{name: 'login'}">{{$t("Back to Login")}}</router-link>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { requiredRule, emailRule ,passwordRule} from "../utils/rules";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: "SignUp",
  components: { VueHcaptcha },
  data() {
    return {
      displayname: "",
      password: "",
      email: "",
      dsgvo: false,
      valid: false,
      requiredRule,
      emailRule,
      passwordRule,
      registerSuccess: false,
      hcaptcha_sitekey: "41ea54b0-dc28-442e-8d64-0f5e433c6c52",
      token: null,
      show: false
    };
  },
  methods: {
    ...mapActions("user", ["signUp"]),

    backToLogin() {

      this.$router.push({name: "login"}); 
    },

    async startSignUp(user) {
      this.$refs.form.validate();
      // console.log(this.valid, this.token);

      if (this.valid && this.token) {
        await this.signUp(user);
      }
    },
    onVerify(token) {
      // Dieser Callback wird aufgerufen, wenn das Captcha erfolgreich gelöst wurde
      // 'token' enthält den hCaptcha-Response-Token
      //console.log("hCaptcha verified:", token);
      this.token = token;
      // Hier kannst du z.B. den Token an deinen Server senden
    },
    onError(err) {
      // Falls ein Fehler auftritt (z.B. Netzwerkprobleme)
      // console.error("hCaptcha error:", err)
      this.token = null;
    },
    onExpired() {
      // Wird aufgerufen, wenn ein Token abgelaufen ist
      // console.log("hCaptcha token abgelaufen")
      this.token = null;
    }
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status
    })
  },
  watch: {
    status(obj) {
      if (obj === 'success') {
        this.registerSuccess = true;
      }
    }
  }
};
</script>

<style scoped>
</style>
