<template>
  <v-container fluid lazy v-if="status === 'success'">
    <v-row class="align-center justify-center">
      <!-- Erster Bereich: Indexes -->
      <v-col cols="12" md="6">
        <a href="https://www.energyagency.at/fileadmin/1_energyagency/presseaussendungen/strompreisindex/oespi_downloads/oespi_monatswerte.pdf" target="_blank">
          ÖSPI Tabelle
        </a>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Indexes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search_index"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="editedIndex = -1"
          ></v-text-field>
          <v-btn color="green" fab small dark class="mb-2" @click="createItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="750px" lazy persistent>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" v-if="Global.indexTypes">
                        <v-select 
                          :items="Global.indexTypes" 
                          item-text="value"
                          item-value="_id"  
                          label="Index Type" 
                          v-model="editedItem.typeOf"
                          v-validate="'required'"
                          data-vv-name="form_indexTypes"
                          :error-messages="errors.collect('form_indexTypes')"
                          autofocus
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field 
                          v-model="editedItem.valid_from" 
                          label="valid_from" 
                          v-validate="'required'"
                          type="date"
                          data-vv-name="form_valid_from"
                          :error-messages="errors.collect('form_valid_from')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field 
                          v-model="editedItem.value" 
                          label="Value"
                          type="number"
                          v-validate="'required'"
                          data-vv-name="form_value"
                          :error-messages="errors.collect('form_value')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>   
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers_index"
          :items="PriceIndex"
          class="elevation-1"
          :search="search_index"
          :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
          :items-per-page="50"
          sort-by="value"
          must-sort
        >
          <template v-slot:item="{ item }" class="text-xs-right">
            <tr >
              <td>{{ comp_priceIndex(item.typeOf) }}</td>
              <td>{{ item.valid_from | moment("MM-YYYY") }}</td>
              <td>{{ item.value }}</td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click.stop="editItem(item)">
                  mdi-pencil
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <!-- Zweiter Bereich: GlobalVars -->
      <v-row class="align-center justify-center">
        <v-col cols="12" md="6">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>GlobalVars</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search_globalvar"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="editedIndexGlobalVar = -1"
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createItemGlobalVar">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog_globalvar" max-width="750px" lazy persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitleGlobalVar }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItemGlobalVar.typeOf"
                            label="typeOf"
                            autofocus
                            type="String"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItemGlobalVar.value"
                            label="value"
                            type="String"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-checkbox
                            v-model="editedItemGlobalVar.active"
                            label="active"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select 
                            :items="GlobalAll" 
                            item-text="value"
                            item-value="_id"
                            label="Parent" 
                            v-model="editedItemGlobalVar.parent"
                            v-validate="'required'"
                            data-vv-name="form_invoice_index"
                            :error-messages="errors.collect('form_invoice_index')"
                            autofocus
                          ></v-select>
                        </v-col>                     
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>   
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeGlobalVar">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="saveGlobalVar">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers_globalvar"
            :items="GlobalAll"
            class="elevation-1"
            :search="search_globalvar"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            sort-by="typeOf"
            must-sort
          >
            <template v-slot:item="{ item }" class="text-xs-right">
              <tr>
                <td>{{ item.typeOf }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.active }}</td>
                <td>{{ comp_globalvar_parent(item.parent) }}</td>
                <td class="justify-center layout px-0">
                  <v-icon small class="mr-2" @click.stop="editItemGlobalVar(item)">
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";
import api from "../api";

export default {
  name: "administration",
  data() {
    return {
      dialog: false,
      dialog_globalvar: false,
      search_index: '',
      search_globalvar: '',
      headers_index: [
        { text: 'typeOf', align: 'left', sortable: true, value: 'typeOf'},
        { text: 'valid', align: 'left', sortable: true, value: 'valid_from'},
        { text: 'value', align: 'left', sortable: true, value: 'value'}
      ],
      headers_globalvar: [
        { text: 'typeOf', align: 'left', sortable: true, value: 'typeOf'},
        { text: 'value', align: 'left', sortable: true, value: 'value'},
        { text: 'active', align: 'left', sortable: true, value: 'active'},
        { text: 'parent', align: 'left', sortable: true, value: 'parent'}
      ],
      editedIndex: -1,
      editedIndexGlobalVar: -1,
      editedItem: {},
      editedItemGlobalVar: {},
      defaultItem: {
        typeOf: null,
        valid_from: this.$moment().format("YYYY-MM-DD"),
        valid_to: this.$moment().format("YYYY-MM-DD"),
        value: null
      },
      defaultItemGlobalVar: {
        typeOf: '',
        value: '',
        active: true,
        parent: null
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(
      "global", [
        "getGlobal",
        "getGlobalAll",
        "createGlobal",
        "updateGlobal"
        ]
      ),
    ...mapActions(
      "price_index", [
        "getPriceIndex",
        "createPriceIndex",
        "updatePriceIndex"
        ]
      ),
    async initData() {
      // await this.getGlobal({})
      if (this.Global.length == 0) {
        await this.getGlobal({});
      }
      if (this.GlobalAll.length == 0) {
        await this.getGlobalAll({})
      }
    
      await this.getPriceIndex({})
    },

    createItem () {
      this.editedItem = _.cloneDeep(this.defaultItem)
      this.editedIndex = -1
      this.dialog = true
    },

    editItem (item) {
      this.editedIndex = this.PriceIndex.indexOf(item)
      this.editedItem = item
      this.editedItem.valid_from = this.$moment(this.editedItem.valid_from).format("YYYY-MM-DD")
      this.dialog = true
    },

    close () {
      this.dialog = false
    },

    async save () {
      this.$validator.validateAll().then(async result => {
      if (result) {
        this.editedItem.valid_to = this.$moment(this.editedItem.valid_from).endOf('month').toDate()
        this.editedItem.valid_from = this.$moment(this.editedItem.valid_to).startOf('month').toDate()
        if (this.editedIndex > -1) {
            await this.updatePriceIndex(this.editedItem)
          } else {  //new item
            await this.createPriceIndex(this.editedItem)
          }
          this.getPriceIndex({})
          this.close()
        }
        else {

        }
      })
    },

    comp_priceIndex (id) {
      try {
         return this.Global.indexTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },

    comp_globalvar_parent (id) {
      try {  
        const query = _.find(this.GlobalAll, {_id: id})
         return query.typeOf + " - " + query.value
      }
      catch {
        return ""
      }
    },

    
    createItemGlobalVar () {
      this.editedItemGlobalVar = _.cloneDeep(this.defaultItemGlobalVar)
      this.editedIndexGlobalVar = -1
      this.dialog_globalvar = true
    },

    editItemGlobalVar (item) {
      this.editedIndexGlobalVar = this.GlobalAll.indexOf(item)
      this.editedItemGlobalVar = item
      this.dialog_globalvar = true
    },

    closeGlobalVar () {
      this.dialog_globalvar = false
    },

    async saveGlobalVar () {
     // this.$validator.validateAll().then(async result => {
     // if (result) {
        if (this.editedIndexGlobalVar > -1) {
            await this.updateGlobal(this.editedItemGlobalVar)
          } else {  //new item
            await this.createGlobal(this.editedItemGlobalVar)
          }
          this.getGlobalAll({})
          this.closeGlobalVar()
        }
     //   else {

       // }
    //  })
    //},
  },
  computed: {
    ...mapState({
      Global: ({ global }) => global.Global,
      GlobalAll: ({ global }) => global.GlobalAll,
      mySelf: ({auth}) => auth.mySelf,
      PriceIndex: ({price_index}) => price_index.PriceIndex,
      status: ({ price_index }) => price_index.status,
    }),

    formTitle () {
      return this.editedIndex === -1 ? 'New PriceIndex' : 'Edit PriceIndex'
    },

    formTitleGlobalVar() {
      return this.editedIndex === -1 ? 'New GlobalVar' : 'Edit GlobalVar'
    },
  
  },

  watch: {
    dialog () {
      this.$validator.reset()
    },
    status(val) {
      this.loading = val === "fetching";
      if (val === "success") {
       
      }
    },
    loading(val) {
      this.$store.state.loading = val;
    }
  }
}

</script>