const REQUEST_ENERGYUNIT = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_ENERGYUNIT = (state, { body }) => {
  state.EnergyUnit = body;
  state.status = "success";
};

const FAILURE_REQUEST_ENERGYUNIT = state => {
  state.status = "failure";
};


const REQUEST_DELETE_ENERGYUNIT = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_ENERGYUNIT = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_ENERGYUNIT = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_ENERGYUNIT = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_ENERGYUNIT = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_ENERGYUNIT = state => {
  state.statusUpdate = "failure";
};

const REQUEST_ENERGYUNIT_HISTORY = state => {
  state.statusHistory = "fetching";
};

const SUCCESS_REQUEST_ENERGYUNIT_HISTORY = (state, { body }) => {
  //console.log("SUCCESS_HISTORY")
  //console.log(body.history)
  state.History = body;
  state.statusHistory = "success";
};

const FAILURE_REQUEST_ENERGYUNIT_HISTORY = state => {
  state.statusHistory = "failure";
};

const REQUEST_CREATE_ENERGYUNIT = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_ENERGYUNIT = state => {
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_ENERGYUNIT = state => {
  state.statusCreate = "failure";
};

const REQUEST_DEACTIVATE_ENERGYUNIT = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_ENERGYUNIT = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_ENERGYUNIT = state => {
  state.statusDeactivate = "failure";
};


export default {
  REQUEST_ENERGYUNIT,
  SUCCESS_REQUEST_ENERGYUNIT,
  FAILURE_REQUEST_ENERGYUNIT,
  REQUEST_CREATE_ENERGYUNIT,
  SUCCESS_REQUEST_CREATE_ENERGYUNIT,
  FAILURE_REQUEST_CREATE_ENERGYUNIT,
  REQUEST_ENERGYUNIT_HISTORY,
  SUCCESS_REQUEST_ENERGYUNIT_HISTORY,
  FAILURE_REQUEST_ENERGYUNIT_HISTORY,
  REQUEST_DELETE_ENERGYUNIT,
  SUCCESS_REQUEST_DELETE_ENERGYUNIT,
  FAILURE_REQUEST_DELETE_ENERGYUNIT,
  REQUEST_UPDATE_ENERGYUNIT,
  SUCCESS_REQUEST_UPDATE_ENERGYUNIT,
  FAILURE_REQUEST_UPDATE_ENERGYUNIT,
  REQUEST_DEACTIVATE_ENERGYUNIT,
  SUCCESS_REQUEST_DEACTIVATE_ENERGYUNIT,
  FAILURE_REQUEST_DEACTIVATE_ENERGYUNIT
};
