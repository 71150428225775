<template>
  <v-container fluid>
    <v-row class="align-center justify-center">
      <!-- EnergyUnit Section -->
      <v-col md="12">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>EnergyUnit</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="editedIndex = -1; editedItem.billing_costs = []; editedItem.invest_costs = [];"
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog" max-width="750px" persistent lazy>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.name"
                            label="name"
                            autofocus
                            v-validate="'required'"
                            data-vv-name="form_name"
                            :error-messages="errors.collect('form_name')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.running_costs_energy"
                            label="running net costs energy per kWh"
                            type="number"
                            :prefix="currency"
                            v-validate="'required'"
                            data-vv-name="form_running_costs_energy"
                            :error-messages="errors.collect('form_running_costs_energy')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" v-if="Global.deviceTypes">
                          <v-select
                            :items="Global.deviceTypes"
                            item-text="value"
                            item-value="_id"
                            label="Device Type"
                            v-model="editedItem.device_type"
                            v-validate="'required'"
                            data-vv-name="form_device_type"
                            :error-messages="errors.collect('form_device_type')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="locationNames"
                            label="location"
                            v-model="location_index"
                            @change="set_location"
                            item-text="name"
                            item-value="index"
                            v-validate="'required'"
                            data-vv-name="form_location"
                            :error-messages="errors.collect('form_location')"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <!-- EnergyUnit DataTable -->
          <v-data-table
            :headers="headers"
            :items="energyunit"
            class="elevation-1"
            :search="search"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            sort-by="name"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr @click="on_click(item)" :class="{ 'grey--text': !item.active }">
                <td>{{ item.name }}</td>
                <td>{{ currency }} {{ item.running_costs_energy }}</td>
                <td>{{ comp_deviceTypes(item.device_type) }}</td>
                <td>{{ comp_location(item.location) }}</td>
                <td class="justify-center layout px-0">
                  <v-icon small class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="item.active && mySelf.permissions.includes('deactivate')"
                    small
                    @click.stop="deactivateItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    v-if="mySelf.permissions.includes('delete')"
                    small
                    @click.stop="activateItem(item)"
                  >
                    mdi-eye
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>

      <!-- Invest Costs Section -->
      <v-col md="12" v-if="editedIndex > -1">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title v-if="EnergyUnit[editedIndex]">
              Invest Costs of {{ EnergyUnit[editedIndex].name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="editedIndex = -1"
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createInvestCosts">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog_invest" max-width="750px" persistent lazy>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_invest_costs.name"
                            label="name"
                            autofocus
                            v-validate="'required'"
                            data-vv-name="form_invest_name"
                            :error-messages="errors.collect('form_invest_name')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" v-if="Global.investTypes">
                          <v-select
                            :items="Global.investTypes"
                            item-text="value"
                            item-value="_id"
                            label="Invest Type"
                            v-model="edited_invest_costs.invest_type"
                            v-validate="'required'"
                            data-vv-name="form_invest_type"
                            :error-messages="errors.collect('form_invest_type')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_invest_costs.value"
                            label="net value"
                            type="number"
                            :prefix="currency"
                            v-validate="'required'"
                            data-vv-name="form_invest_value"
                            :error-messages="errors.collect('form_invest_value')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_invest_costs.vat"
                            label="vat"
                            type="number"
                            suffix="%"
                            v-validate="'required'"
                            data-vv-name="form_invest_vat"
                            :error-messages="errors.collect('form_invest_vat')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="comp_invest_costs_date"
                            label="date"
                            type="date"
                            v-validate="'required'"
                            data-vv-name="form_invest_date"
                            :error-messages="errors.collect('form_invest_date')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_invest_costs.write_off_years"
                            label="write_off_years"
                            type="number"
                            v-validate="'required'"
                            data-vv-name="form_invest_write_off_years"
                            :error-messages="errors.collect('form_invest_write_off_years')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close_invest">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save_invest">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers_invest"
            :items="editedItem.invest_costs"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            sort-by="name"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr >
                <td>{{ item.name }}</td>
                <td>{{ comp_investTypes(item.invest_type) }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.vat }}</td>
                <td>{{ item.date | moment("DD.MM.YYYY") }}</td>
                <td>{{ item.write_off_years }}</td>
                <td class="justify-center layout px-0" v-if="item.editable">
                  <v-icon small class="mr-2" @click.stop="editItemInvest(item)">
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>

      <!-- Billing Costs Section -->
      <v-col md="12" v-if="editedIndex > -1">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title v-if="EnergyUnit[editedIndex]">
              Billing of {{ EnergyUnit[editedIndex].name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="editedIndex = -1"
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createBillingCosts">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog_billing" max-width="750px" persistent lazy>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="comp_billing_costs_period_from"
                            label="period_from"
                            autofocus
                            type="date"
                            @change="set_valid_to()"
                            v-validate="'required'"
                            data-vv-name="form_period_from"
                            :error-messages="errors.collect('form_period_from')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="comp_billing_costs_period_to"
                            label="period_to"
                            type="date"
                            v-validate="'required'"
                            data-vv-name="form_period_to"
                            :error-messages="errors.collect('form_period_to')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" v-if="Global.billingTypes">
                          <v-select
                            :items="sortGlobalBillingTypes"
                            item-text="value"
                            item-value="_id"
                            label="Billing Type"
                            v-model="edited_billing_costs.billing_type"
                            v-validate="'required'"
                            data-vv-name="form_billing_type"
                            :error-messages="errors.collect('form_billing_type')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_billing_costs.consumption"
                            label="consumption"
                            type="number"
                            v-validate="'required'"
                            data-vv-name="form_billing_consumption"
                            :error-messages="errors.collect('form_billing_consumption')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_billing_costs.value"
                            label="net value"
                            type="number"
                            :prefix="currency"
                            v-validate="'required'"
                            data-vv-name="form_billing_value"
                            :error-messages="errors.collect('form_billing_value')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="edited_billing_costs.vat"
                            label="vat"
                            type="number"
                            suffix="%"
                            v-validate="'required'"
                            data-vv-name="form_vat"
                            :error-messages="errors.collect('form_vat')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close_billing">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save_billing">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers_billing"
            :items="editedItem.billing_costs"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            sort-by="period_from"
            :sort-desc="true"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr >
                <td>{{ item.period_from | moment("DD.MM.YYYY") }}</td>
                <td>{{ item.period_to | moment("DD.MM.YYYY") }}</td>
                <td>{{ comp_billingTypes(item.billing_type) }}</td>
                <td>{{ item.consumption }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.vat }}</td>
                <td class="justify-center layout px-0" v-if="item.editable">
                  <v-icon small class="mr-2" @click.stop="editItemBilling(item)">
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import api from "../api";

export default {
  name: "energyUnit",
  data() {
    return {
      //Required: [v => !!v || "The input is required"],
      // pagination: {
      //   sortBy: 'period_to',
      //   descending: true
      // },
      energyunit: [],
      //person: {},
      //history: [],
      location_index: 0,
      //history: [],
      dialog: false,
      dialog_billing: false,
      dialog_invest: false,
      search: '',
      headers: [
        { text: 'Name', align: 'left', sortable: true, value: 'name'},
        { text: 'running net costs energy per kWh', align: 'left', sortable: true, value: 'running_costs_energy'},
        { text: 'type', value: 'device_type' },
        { text: 'location', value: 'location' },
        { text: 'Actions', value: 'name', sortable: false }
      ],
      headers_billing: [
        { text: 'period_from', value: 'period_from'},
        { text: 'period_to', value: 'period_to'},
        { text: 'billing_type', align: 'left', sortable: true, value: 'billing_type'},
        { text: 'consumption', align: 'left', sortable: true, value: 'consumption'},
        { text: 'net value', value: 'value' },
        { text: 'vat', value: 'vat' },
        { text: 'Actions', value: 'name', sortable: false }
      ],
      headers_invest: [
        { text: 'name', value: 'name'},
        { text: 'type', value: 'invest_type'},
        { text: 'net value', align: 'left', sortable: true, value: 'value'},
        { text: 'vat', value: 'vat' }, 
        { text: 'date', value: 'date' },
        { text: 'write_off_years', value: 'write_off_years' },
        { text: 'Actions', value: 'name', sortable: false }
      ],
      editedIndex: -1,
      //editedHistoryIndex: -1,
      editedInvestIndex: -1,
      editedBillingIndex: -1,
      editedItem: {
        name: '',
        device_type: '',
        running_costs_energy: '',
        location: null,
        billing_costs: [],
        invest_costs: []
      },
      defaultItem: {
        name: '',
        device_type: '',
        running_costs_energy: '',
        location: null,
        billing_costs: [],
        invest_costs: []
      },
      edited_billing_costs: {},
      default_edited_billing_costs: {
        period_from: this.$moment().subtract(1, 'months').startOf('month').toDate(),
        period_to: this.$moment().subtract(1, 'months').endOf('month').toDate(),
        billing_type: '',
        consumption: 0,
        value: 0,
        vat: 20,
        editable: true   
      },
      edited_invest_costs: {},
      default_edited_invest_costs: {
        name: '',
        invest_type: '',
        value: 0,
        vat: 20,
        date: this.$moment().toDate(),
        write_off_years: 30,
        editable: true
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions("person", [
      "getPersons"
      ]),
    ...mapActions(
      "location", ["getLocation"]
      ),
    ...mapActions(
      "energyunit", [
        "getEnergyUnit",
        "getEnergyUnitHistory",
        "deleteEnergyUnit",
        "updateEnergyUnit",
        "deactivateEnergyUnit",
        "createEnergyUnit"
        ]
      ),
    ...mapActions(
      "global", ["getGlobal"]
      ),
    ...mapActions(
      "price_index", ["getPriceIndex"]
      ),
    async initData() {
      // await this.getGlobal({})
      if (this.Global.length == 0) {
        await this.getGlobal({});
      }
      await this.getPriceIndex({})
      await this.getLocation({})
      await this.getPersons({})
      await this.getEnergyUnit({})
    },

    createItem () {

      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      //this.editedHistoryIndex = -1
      //this.history = []
      this.location_index = 0
      this.set_location(this.location_index)
      this.dialog = true
    },
    createBillingCosts () {
      this.editedBillingIndex = -1
      const old_consumption = this.edited_billing_costs.consumption;
      this.edited_billing_costs = _.cloneDeep(this.default_edited_billing_costs)
      this.edited_billing_costs.vat = 20
      this.edited_billing_costs.value = 0
      this.edited_billing_costs.consumption = old_consumption;
      this.edited_billing_costs.billing_type = ''
      this.dialog_billing = true
    },    
    createInvestCosts () {
      this.editedInvestIndex = -1
      this.edited_invest_costs = _.cloneDeep(this.default_edited_invest_costs)
      this.dialog_invest = true
    },    
    editItem (item) {
      //this.editedHistoryIndex = this.history.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.location_index = this.Locations.findIndex((loc) => {return loc._id == this.editedItem.location})
      this.dialog = true
    },
    editItemInvest (item) {
      this.edited_invest_costs = Object.assign({}, item)
      this.editedInvestIndex = this.energyunit[this.editedIndex].invest_costs.indexOf(item)
      console.log(this.editedInvestIndex)
      //this.edited_invest_costs.date = this.$moment(this.edited_invest_costs.date).format("YYYY-MM-DD")
      this.dialog_invest = true
    },
    editItemBilling (item) {
      console.log(item)
      this.edited_billing_costs = Object.assign({}, item)
      this.editedBillingIndex = this.energyunit[this.editedIndex].billing_costs.indexOf(item)
      console.log(this.editedBillingIndex)
      //this.editedHistoryIndex = this.history.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      //this.location_index = this.Locations.findIndex((loc) => {return loc._id == this.editedItem.location})
      //this.edited_billing_costs.period_from = this.$moment(this.edited_billing_costs.period_from).format("YYYY-MM-DD")
      //this.edited_billing_costs.period_to = this.$moment(this.edited_billing_costs.period_to).format("YYYY-MM-DD")
      this.dialog_billing = true
    },
    async deleteItem (item) {
      confirm('Are you sure you want to delete this item?') && await this.deleteEnergyUnit(item._id)
      this.history = []
      this.getEnergyUnit({})
    },

    async deactivateItem (item) {
      await this.deactivateEnergyUnit(item._id)
      this.getEnergyUnit({})
    },

    on_click (item) {
      this.editedIndex = this.energyunit.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
 

    async activateItem (item) {
      await this.deactivateEnergyUnit(item._id)
      this.getEnergyUnit({})
    },

    close () {
      this.dialog = false
    },
    close_billing () {
      this.dialog_billing = false
    },
    close_invest () {
      this.dialog_invest = false
    },

    async save () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.editedIndex > -1) {
            await this.updateEnergyUnit(this.editedItem)
          } else {  //new item
            await this.createEnergyUnit(this.editedItem)
          }
        
          this.getEnergyUnit({})
          this.close()
        }
        else {

        }
      })
    },
    async save_billing () {
    //  this.$validator.validateAll().then(async result => {
    //    if (result) {
      this.edited_billing_costs.period_to = this.$moment(this.edited_billing_costs.period_to).endOf("month").format()
          console.log("SAVE BILLING")
          console.log(this.edited_billing_costs)
          if (this.editedBillingIndex > -1) {
            console.log("SAVE UPDATE")
            this.editedItem.billing_costs[this.editedBillingIndex] = this.edited_billing_costs        
          } else {  //new item
            console.log("SAVE NEW")
            this.editedItem.billing_costs.push(this.edited_billing_costs)
          }
          await this.updateEnergyUnit(this.editedItem)
          //this.getEnergyUnit({})
          this.close_billing()
    /*    }
        else {
        }
      })*/
      await this.getEnergyUnit({})
    },
    async save_invest () { 
      
       console.log("SAVE INVEST")
       console.log(this.edited_invest_costs)
    //  this.$validator.validateAll().then(async result => {
      //  if (result) {
          console.log("SAVE INVEST")
          if (this.editedInvestIndex > -1) {
            console.log("SAVE UPDATE")
            this.editedItem.invest_costs[this.editedInvestIndex] = this.edited_invest_costs   
          } else {  //new item
          console.log("SAVE NEW")
            this.editedItem.invest_costs.push(this.edited_invest_costs)
          }
          console.log(this.editedItem)
          await this.updateEnergyUnit(this.editedItem)
          //this.getEnergyUnit({})
          this.close_invest()
        /*}
        else {
        }
      })*/
      await this.getEnergyUnit({})
    },
    comp_location (id) {
      try{
        return this.Locations.filter(obj => {return obj._id == id})[0].name
      }
      catch {}
    },
    comp_deviceTypes (id) {
      try {
         return this.Global.deviceTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },
    comp_billingTypes (id) {
      try {
         return this.Global.billingTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },
    comp_investTypes (id) {
      try {
         return this.Global.investTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },
    set_location (index) {
      this.editedItem.location = this.Locations[index]._id
    },
    set_valid_to () {
      this.edited_billing_costs.period_to = this.$moment(this.edited_billing_costs.period_from).endOf('month').toDate()
    },    
  },
  computed: {
    ...mapState({
      Locations: ({ location }) => location.Locations,
      EnergyUnit: ({ energyunit }) => energyunit.EnergyUnit,
      status: ({ energyunit }) => energyunit.status,
      statusUpdate: ({ energyunit }) => energyunit.statusUpdate,
      statusCreate: ({ energyunit }) => energyunit.statusCreate,
      statusDelete: ({ energyunit }) => energyunit.statusDelete,
      statusDeactivate: ({ energyunit }) => energyunit.statusDeactivate,
      Global: ({ global }) => global.Global,
      mySelf: ({auth}) => auth.mySelf,
      PriceIndex: ({ price_index }) => global.PriceIndex
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New EnergyUnit' : 'Edit EnergyUnit'
    },

    locationNames () {
      let locnames = []
      this.Locations.forEach((loc, index) => {
        locnames.push({name: loc.name, index: index})
      })
      locnames = _.sortBy(locnames, "name");
      return locnames
    },

    currency () {
      //return this.store.global.currency
      return "€"
    },
    comp_invest_costs_date: {    
			get(){
				return this.$moment(this.edited_invest_costs.date).format("YYYY-MM-DD")
			},
			set(newVal){
				if (this.$moment(newVal)._isValid) this.edited_invest_costs.date = this.$moment(newVal).format()
      }
    },
    comp_billing_costs_period_from: {    
			get(){
				return this.$moment(this.edited_billing_costs.period_from).format("YYYY-MM-DD")
			},
			set(newVal){
				if (this.$moment(newVal)._isValid) this.edited_billing_costs.period_from = this.$moment(newVal).format()
      }
    },
    comp_billing_costs_period_to: {    
			get(){
				return this.$moment(this.edited_billing_costs.period_to).format("YYYY-MM-DD")
			},
			set(newVal){
				if (this.$moment(newVal)._isValid) this.edited_billing_costs.period_to = this.$moment(newVal).format()
      }
    },
    sortGlobalBillingTypes () {
      return _.sortBy(this.Global.billingTypes, "value");
    }
  },

  watch: {
    dialog () {
      this.$validator.reset()
    },
    dialog_billing () {
      this.$validator.reset()
    },
    dialog_invest () {
      this.$validator.reset()
    },
    status(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        console.log("status success")
        this.energyunit = _.cloneDeep(this.EnergyUnit)
        let findunit = _.find(this.energyunit, {_id: this.editedItem._id})
        this.editedIndex = _.cloneDeep(this.energyunit.indexOf(findunit))
        if (this.editedIndex > -1) this.editedItem = this.energyunit[this.editedIndex]
        console.log(this.editedIndex)
        //this.editedItem = this.energyunit[this.editedIndex]
      }
    },
    statusCreate(val) {
      this.loading = val === "fetching";
      if (val === "success") {
       // this.initData();
      }
    },
    statusDelete (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
       // this.initData()
      }
    },
    statusUpdate (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
       // this.initData()
      }
    },
    statusDeactivate (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
        //this.initData()
      }
    },
    loading(val) {
      this.$store.state.loading = val;
    }
  }
}

</script>