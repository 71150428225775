<template>
  <v-container pa-0 ma-0  fluid>
    <v-card>
      <v-row >
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Refresh</div>
          <v-btn small icon color="accent" @click="getDeviceDebug()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">AutoRefresh</div>
          <v-switch
            height="0"
            v-model="autoRefresh"
            color="blue"
            @change="setAutoRefresh()"
            :label="autoRefresh.toString()"
          ></v-switch>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">DeviceID</div>
          <div class="body-1">{{ device_local.device_id }}</div>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">Alive</div>
          <div class="body-1">{{ device_local.alive | moment("DD.MM HH:mm:ss") }}</div>
        </v-col>
      </v-row>
    </v-card>

    <v-card >
      <v-row mt-3 row>
        <v-col   cols="12" py-2 ma-1 left>
          <div class="body-2">Manual Mode</div>
          <v-switch
            height="0"
            v-model="device.debug.f_manual"
            color="red"
            hide-details
            @change="flowManual()"
            :label='device.debug.f_manual ? "ON" : "OFF"'
          ></v-switch>
        </v-col>
        <v-col   cols="12" py-2 ma-1 left v-if="device.debug.f_manual">
          <v-btn color="red" @click="sendStop()">Stop</v-btn>
        </v-col>
        <v-col   cols="12" py-2 ma-1 left v-if="device.debug.f_manual">
          <v-btn @click="sendGoEast()">Go East</v-btn>
        </v-col>
        <v-col   cols="12" py-2 ma-1 left v-if="device.debug.f_manual">
          <v-btn @click="sendGoWest()">Go West</v-btn>
        </v-col>
        <!-- <v-col   cols="12" py-2 ma-1 left>
          <div class="body-2">Min PWM</div>
          <div class="body-1">{{ device_local.debug.min_pwm }}</div>
        </v-col>
        <v-col   cols="12" py-2 ma-1 left>
          <div class="body-2">Max PWM</div>
          <div class="body-1">{{ device_local.debug.max_pwm }}</div>
        </v-col>
        <v-col   cols="12" py-2 ma-1 left>
          <div class="body-2">Motor Mode</div>
          <div class="body-1">{{ device_local.debug.m_mode }}</div>
        </v-col> -->
      </v-row>
    </v-card>

    <br />
    <v-row style="min-height: 50px" >
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Azimut</div>
            <div class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_azimut }}°
            </div>
            <!-- <div class="subheader pt-3">S/N</div> -->
            <!-- <div class="subheader" pt-2>Seriennummer von Modul</div> -->
            <div  class="subheader pt-3">
              degrees
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Heading</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.st_heading).toFixed(0)  }}°
            
            </div>
            <div class="subheader pt-3">degrees</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Light East</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_east }}
            </div>
            <div class="subheader pt-3">analog value</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Light West</div>
            <div class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_west  }}
            </div>
            <div class="subheader pt-3">analog value</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Motor Controller</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.f_relais_1 ? "ON" : "OFF" }}
            </div>
            <div class="subheader pt-3">status</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Temp Rittal</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_temp_int }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Humidity Rittal</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_hum_int }} %
            </div>
            <div class="subheader pt-3">actual humidity</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Temp Magnetometer</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_temp_mag }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Humidity Magnetometer</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_hum_mag }} %
            </div>
            <div class="subheader pt-3">actual humidity</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Elevation</div>
            <div  class="headline font-weight-bold pt-3">
              {{ device_local.raw.st_elevation }} °
            </div>
            <div class="subheader pt-3">degrees</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row style="min-height: 50px">
      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.startDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="Start Date"
              :value="startDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              () => {
                modals.startDate = false;
              }
            "
            min="2020-01-01"
            v-model="startDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.startDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.endDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="End Date"
              :value="endDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              () => {
                modals.endDate = false;
              }
            "
            :min="startDate"
            :max="maxDate"
            v-model="endDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.endDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>
     
    </v-row>

    <apexchart v-if="showChart" ref="myChart" type="line" height="650"  :options="apexChartOptions" :series="apexChartData"></apexchart>
    <!-- <v-row >
      <v-col  cols="12" >
        <CrossFilterComponent :parentDevice="device"/>
      </v-col>
    </v-row> -->

    <v-row v-if='DeviceIFrame'>
      <v-col  cols="12">
        <iframe
          :src="DeviceIFrame"
          height="600"
          :width="window.width - 60"
        ></iframe>
      </v-col>
    </v-row>
   
    <v-dialog v-model="dialog_mqtt" lazy width="390px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Fehler</v-card-title>
        <v-card-text>{{dialog_mqtt_description}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_mqtt = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_mqtt_process" width="450px" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Sending Command to Device</v-card-title>
        <v-card-text>
          <v-progress-linear
            color="blue"
            indeterminate
            rounded 
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
const ExcelJS = require("exceljs");

//import CrossFilterComponent from "../components/CrossFilterComponent";

export default {
  name: "homePvTracker",
  components: {
   
  },
  props: {
    device: {},
    Locations: null
  },
  data() {
    return {
      device_local: undefined,
      autoRefresh: false,
      loadingEvent: false,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      modals: {
        startDate: false,
        endDate: false,
      },
      startDate: void 0,
      endDate: void 0,
      maxDate: void 0,

      dialog_mqtt: false,
      dialog_mqtt_description: "",
      dialog_mqtt_process: false,

      apexChartOptions: {
        
        theme: {
          mode: this.$vuetify.theme.isDark ? "dark" : "light",
        },
        legend: {
          position: "top"
        },
        colors: [this.$vuetify.theme.energy_primary, this.$vuetify.theme.energy_secondary],
        forecastDataPoints: {
          count: 0,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        },
        chart: {
          // events: {
          //   mounted: function(chart) {
          //     chart.toggleSeries('Power');
          //     chart.toggleSeries('Flow');
          //     chart.toggleSeries('Battery');
          //   }
          // },
          toolbar: {
            show: true,
            export: {
              csv: {
                headerCategory: 'Date',
                columnDelimiter: ',',
                dateFormatter: function(timestamp) {
                  let date = new Date(timestamp);
                  return date.toISOString();
                },
              }
            }
          },
          zoom: {
            enabled: true,
          },
          background: this.$vuetify.theme.background_secondary,
          height: 350,
          type: 'line',
        },
        stroke: {
          width: "1",
         curve: "smooth"
        },
        fill: {
          type:'solid',
          opacity: [0.2, 0.5],
        },
        labels: [],
        markers: {
          size: 0
        },
        yaxis: [
          {
            title: {
              text: 'Value',
            },
          },
          // {
          //   opposite: true,
          //   title: {
          //     text: 'Series B',
          //   },
          // },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
              datetimeUTC: false
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            format: 'dd MMM HH:mm',
            formatter: undefined,
          },
          y: {
            formatter: function (y) {
              // console.log("y: ",y)
              if(typeof y !== "undefined") {
                return  y.toFixed(1);
              }
              return y;
            }
          }
        }
      },
      showChart: true,
      data: {
        h_power1: [],
        bat: [],
        h_flow1: [],
        h_temp1: [],
        h_temp2: [],
        h_volume1: [],
        value_positive: [],
        value_total: [],
        value_negative: [],
        value_total2: [],
        cop: []
      },
    };
  },

  created() {
    // console.log("Created ", this.device);
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 7 * 1000 * 86400);

    this.startDate = startDate.toISOString().slice(0, 10);
    this.endDate = endDate.toISOString().slice(0, 10);
    this.maxDate = this.endDate;


    this.device_local = JSON.parse(JSON.stringify(this.device));
    // for (const [key, value] of Object.entries(this.device_local.raw)) {
    //   this.device_local[key] = value;
    // }

  },

  async mounted() {
    // console.log("Mounted");
    //window.myChart = this.$refs.myChart;
    this.$emit("refreshDevice");
    
    await this.getDeviceIFrame(this.device);
    
    
  },
  destroyed() {
    this.IntervalOff();
  },
  computed: {
    ...mapState({
      DeviceIFrame: ({ device }) => device.iframe,
      allEvents: ({ raw_event }) => raw_event.rawEvents,
      mqttResponse: ({ device }) => device.response,
    }),

    getSerNr() {
      const _sernr = _.find(this.Locations, {_id: this.device.location});
      if (_sernr) {
        return _sernr.name;
      } else {
        return "Not defined";
      }
    },
    apexChartData() {
      return [
        {
          name: 'Azimut',
          type: 'line',
          data: this.data.azimut
        }, 
        {
          name: 'Elevation',
          type: 'line',
          data: this.data.elevation
        },
        {
          name: 'Heading',
          type: 'line',
          data: this.data.heading
        },
        {
          name: 'Hum Mag',
          type: 'line',
          data: this.data.hum_mag
        },
        {
          name: 'Hum Int',
          type: 'line',
          data: this.data.hum_int
        },
        {
          name: 'Temp Int',
          type: 'line',
          data: this.data.temp_int
        },
       
        ]
    },

  },
  methods: {
    ...mapActions("device", ["getDevice", "setDevice", "getDeviceIFrame"]),
    ...mapActions("raw_event", ["getLocationRawEventsIndex"]),

    async getDeviceDebug() {
      // await this.setDevice({
      //   device: this.device.device_id,
      //   topic: "getDetails",
      //   message: 1,
      //   qos: 0,
      // });
      // if (this.mqttResponse.status == false) {

        this.$emit("refreshDevice");
        // await this.getDevice(this.device);

        //console.log("this.powerDevice: " , this.device);
        // this.device = this.powerDevice;
        // this.device.debug = this.device.status;

      // } else {
      //   try {
      //    this.device.debug = JSON.parse(this.mqttResponse.status);
      //   } catch (e) {
      //     //
      //   }
      // }
    },
    async setAutoRefresh() {
      if (this.autoRefresh) {
        this.$store.myInterval = setInterval(async () => {
          this.getDeviceDebug();
          //console.log("Interval heatmeter")
        }, 5000);
      } else {
        this.IntervalOff();
      }
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.myInterval)) {
          clearInterval(this.$store.myInterval);
          this.$store.myInterval = undefined;
          this.autoRefresh = false;
        }
      } catch (e) {
        //
      }
    },

    async flowManual() {
      this.dialog_mqtt_process = true;
      await this.setDevice({
        device: this.device.device_id,
        topic: "setFlowManual",
        message: Number(this.device.debug.f_manual),
        qos: 0,
      });
      this.dialog_mqtt_process = false;
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },

    async sendStop() {
      this.dialog_mqtt_process = true;
      await this.setDevice({
        device: this.device.device_id,
        topic: "STsetSpeed",
        message: "125,125",
        qos: 0,
      });
      this.dialog_mqtt_process = false;
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },
    calcSpeed(direction) {
      //from ESP
      //console.log(direction);
      let calc_speed = 125; 
      let calc_speed_inv = 125; 
      if (!direction) {
        calc_speed = this.device_local.debug.min_pwm;
        calc_speed_inv = this.device_local.debug.max_pwm;
      } else {
        calc_speed = this.device_local.debug.max_pwm;
        calc_speed_inv = this.device_local.debug.min_pwm;
      }
      let st_speed_pwm_m1  = 125;
      let st_speed_pwm_m2  = 125;
      const STmotorsMode = Number(this.device_local.debug.m_mode);

      if (STmotorsMode == 0) {
        st_speed_pwm_m2 = calc_speed;
        st_speed_pwm_m1 = calc_speed_inv;
      } 
      else if (STmotorsMode == 1) {
        st_speed_pwm_m2 = calc_speed;
        st_speed_pwm_m1 = calc_speed;
      }
      else if (STmotorsMode == 2) {
        st_speed_pwm_m2 = calc_speed_inv;
        st_speed_pwm_m1 = calc_speed;
      }
      else if (STmotorsMode == 3) {
        st_speed_pwm_m2 = calc_speed_inv;
        st_speed_pwm_m1 = calc_speed_inv;
      }

      const msg = String(st_speed_pwm_m1) + "," + String(st_speed_pwm_m2);
     
      return msg;
    },
    async sendGoEast() {
      const msg = this.calcSpeed(0);
      // console.log(msg);
      this.dialog_mqtt_process = true;
      await this.setDevice({
        device: this.device.device_id,
        topic: "STsetSpeed",
        message: msg,
        qos: 0,
      });
      this.dialog_mqtt_process = false;
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },
    async sendGoWest() {
      const msg = this.calcSpeed(1);
      // console.log(msg);
      this.dialog_mqtt_process = true;
      await this.setDevice({
        device: this.device.device_id,
        topic: "STsetSpeed",
        message: msg,
        qos: 0,
      });
      this.dialog_mqtt_process = false;
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },

    loadEvent: async function () {

      await this.getLocationRawEventsIndex({
        deviceId: this.device._id,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      let data = {};
      data.azimut = [];
      data.elevation = [];
      data.heading = [];
      data.hum_mag = [];
      data.hum_int = [];
      data.temp_int = [];
      

      for (let el of this.allEvents) {
        const _time = this.$moment(el.raw.time, "X");        
        const _time_x = parseInt(_time.format("x"));
        
        data.azimut.push([_time_x, (el.raw.st_azimut).toFixed(0)]);
        data.elevation.push([_time_x, (el.raw.st_elevation).toFixed(0)]);
        data.heading.push([_time_x, (el.raw.st_heading).toFixed(0)]);
        data.hum_mag.push([_time_x, (el.raw.st_hum_mag ).toFixed(0)]);
        data.hum_int.push([_time_x, (el.raw.st_hum_int ).toFixed(0)]);
        data.temp_int.push([_time_x, (el.raw.st_temp_int).toFixed(0)]);
        
      }
      this.data = data;
      // console.log("event loaded ");
    },
  },
  watch: {
    mqttResponse(res) {
      if (res.status == false) {
        this.dialog_mqtt_description = "Das Gerät hat auf Ihren Befehl nicht reagiert. Versuchen Sie es erneut oder führen Sie vorher einen Awake aus und warten etwas (5sec - 2min)."
        this.dialog_mqtt = true;
      }
      else if (res.status == 0) {
        this.dialog_mqtt_description = "Das Gerät hat Ihren Befehl zurückgewiesen."
        this.dialog_mqtt = true;
      }
    },

    async device(item) {
      //console.log("device changed ", this.loadingEvent);
      this.device_local = JSON.parse(JSON.stringify(this.device));
      // for (const [key, value] of Object.entries(this.device_local.raw)) {
      //   // console.log(key, value);
      //   this.device_local[key] = value;
      // }
      // console.log("Device watch: ", this.device_local);
      await this.loadEvent();

      // this.$refs.myChart.hideSeries(["Flow"]);
      // this.$refs.myChart.hideSeries("Battery");
      // this.$refs.myChart.hideSeries("Temp 1");
      // this.$refs.myChart.hideSeries("Temp 2");
      // this.$refs.myChart.hideSeries("Volume");
      // this.$refs.myChart.hideSeries("Heat Sum");
      // this.$refs.myChart.hideSeries("COP");

      if (!item.debug && item.status) {
        item.debug = item.status;
      }
      await this.getDeviceIFrame(this.device);


    },
    async startDate() {
      // this.applyDateRange();
      if (this.$moment(this.endDate, "YYYY-MM-DD").diff(this.startDate, "days") > 31) {
        let _endDate = this.$moment(this.startDate, "YYYY-MM-DD").add(31, "days");
        this.endDate = _endDate.format("YYYY-MM-DD");
      }
      
      await this.loadEvent();
      // this.$refs.myChart.hideSeries(["Flow"]);
      // this.$refs.myChart.hideSeries("Battery");
      // this.$refs.myChart.hideSeries("Temp 1");
      // this.$refs.myChart.hideSeries("Temp 2");
      // this.$refs.myChart.hideSeries("Volume");
      // this.$refs.myChart.hideSeries("Heat Sum");
      // this.$refs.myChart.hideSeries("COP");
    },
    async endDate() {
      if (this.$moment(this.endDate, "YYYY-MM-DD").diff(this.startDate, "days") > 31) {
        let _startDate = this.$moment(this.endDate, "YYYY-MM-DD").subtract(31, "days");
        this.startDate = _startDate.format("YYYY-MM-DD");
      }
      // this.applyDateRange();
      //console.log("end Date");
      await this.loadEvent();
      // this.$refs.myChart.hideSeries('Power');
      // this.$refs.myChart.hideSeries(["Flow"]);
      // this.$refs.myChart.hideSeries("Battery");
      // this.$refs.myChart.hideSeries("Temp 1");
      // this.$refs.myChart.hideSeries("Temp 2");
      // this.$refs.myChart.hideSeries("Volume");
      // this.$refs.myChart.hideSeries("Heat Sum");
      // this.$refs.myChart.hideSeries("COP");
    },
  }
};
</script>

<style>
.box {
  border: solid 1px red;
}


</style>
