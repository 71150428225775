const REQUEST_LOCATIONS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_LOCATIONS = (state, { body }) => {
  state.Locations = body;
  state.status = "success";
};

const FAILURE_REQUEST_LOCATIONS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_LOCATION = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_LOCATION = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_LOCATION = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_LOCATION = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_LOCATION = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_LOCATION = state => {
  state.statusUpdate = "failure";
};

const REQUEST_DEACTIVATE_LOCATION = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_LOCATION = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_LOCATION = state => {
  state.statusDeactivate = "failure";
};


const REQUEST_CREATE_LOCATION = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_LOCATION = (state, {body}) => {
  state.newLocation = body;
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_LOCATION = state => {
  state.statusCreate = "failure";
};

const REQUEST_MOVE_LOCATION = state => {
  state.statusMove = "fetching";
};

const SUCCESS_REQUEST_MOVE_LOCATION = (state, payload) => {
  state.moveLocation = payload
  state.statusMove = "success";
};

const FAILURE_REQUEST_MOVE_LOCATION = state => {
  state.statusMove = "failure";
};

export default {
  REQUEST_LOCATIONS,
  SUCCESS_REQUEST_LOCATIONS,
  FAILURE_REQUEST_LOCATIONS,
  REQUEST_DELETE_LOCATION,
  SUCCESS_REQUEST_DELETE_LOCATION,
  FAILURE_REQUEST_DELETE_LOCATION,
  REQUEST_UPDATE_LOCATION,
  SUCCESS_REQUEST_UPDATE_LOCATION,
  FAILURE_REQUEST_UPDATE_LOCATION,
  REQUEST_CREATE_LOCATION,
  SUCCESS_REQUEST_CREATE_LOCATION,
  FAILURE_REQUEST_CREATE_LOCATION,
  REQUEST_MOVE_LOCATION,
  SUCCESS_REQUEST_MOVE_LOCATION,
  FAILURE_REQUEST_MOVE_LOCATION,
  REQUEST_DEACTIVATE_LOCATION,
  SUCCESS_REQUEST_DEACTIVATE_LOCATION,
  FAILURE_REQUEST_DEACTIVATE_LOCATION
};
