import api from "../../../api";

export default {
  async getContract({ commit }) {
    commit("REQUEST_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CONTRACT",
        await api.getContract()
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getContractHistory({ commit }, id) {
    commit("REQUEST_CONTRACT_HISTORY");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CONTRACT_HISTORY",
        await api.getContractHistory(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CONTRACT_HISTORY");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteContract({ commit }, id) {
    commit("REQUEST_DELETE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_CONTRACT",
        await api.deleteContract(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateContract({ commit }, item ) {
    commit("REQUEST_UPDATE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_CONTRACT",
        await api.updateContract(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createContract({ commit }, item ) {
    commit("REQUEST_CREATE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_CONTRACT",
        await api.createContract( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deactivateContract({ commit }, id) {
    commit("REQUEST_DEACTIVATE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEACTIVATE_CONTRACT",
        await api.deactivateContract(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DEACTIVATE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
