<template>
  <v-container fluid v-if="status_device_command === 'success'">
    <v-row class="align-center justify-center">
      <v-col md="6">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Device Commands</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search_device_command"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="editedIndex = -1"
          ></v-text-field>
          <v-btn color="green" fab small dark class="mb-2" @click="createItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="750px" persistent>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6" v-if="GlobalAll">
                        <v-text-field
                          v-model="editedItem.cmd"
                          label="Command"
                          autofocus
                          type="string"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          type="string"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.cmd_counter"
                          label="cmd_counter"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.cmd_counter_header"
                          label="cmd_counter_header"
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>   
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="headers_device_command"
          :items="DeviceCommand"
          class="elevation-1"
          :search="search_device_command"
          :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
          :items-per-page="50"
          sort-by="cmd"
          must-sort
        >
          <template v-slot:item="{ item }" class="text-xs-right">
            <tr >
              <td>{{ item.cmd }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.cmd_counter }}</td>
              <td>{{ item.cmd_counter_header }}</td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click.stop="editItem(item)">
                  mdi-pencil
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import api from "../api";

export default {
  name: "device",
  data() {
    return {
      dialog: false,
      dialog_globalvar: false,
      search_device_command: '',
      search_globalvar: '',
      headers_device_command: [
        { text: 'Command', align: 'left', sortable: true, value: 'cmd'},
        { text: 'Name', align: 'left', sortable: true, value: 'name'},
        { text: 'cmd_counter', align: 'left', sortable: true, value: 'cmd_counter'},
        { text: 'cmd_counter_header', align: 'left', sortable: true, value: 'cmd_counter_header'}
      ],
      headers_globalvar: [
        { text: 'typeOf', align: 'left', sortable: true, value: 'typeOf'},
        { text: 'value', align: 'left', sortable: true, value: 'value'},
        { text: 'active', align: 'left', sortable: true, value: 'active'},
        { text: 'parent', align: 'left', sortable: true, value: 'parent'}
      ],
      editedIndex: -1,
      editedIndexGlobalVar: -1,
      editedItem: {},
      editedItemGlobalVar: {},
      defaultItem: {
        typeOf: null,
        params: [],
        parentTypeOf: null
      },
      defaultItemGlobalVar: {
        typeOf: '',
        value: '',
        active: true,
        parent: null
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(
      "global", [
        "getGlobal",
        "getGlobalAll",
        "createGlobal",
        "updateGlobal"
        ]
      ),
    ...mapActions(
      "device_command", [
        "getDeviceCommand",
        "createDeviceCommand",
        "updateDeviceCommand"
        ]
      ),
    async initData() {
      // await this.getGlobal({})
      // await this.getGlobalAll({})
      if (this.Global.length == 0) {
        await this.getGlobal({});
      }
      if (this.GlobalAll.length == 0) {
        await this.getGlobalAll({})
      }
      await this.getDeviceCommand({})
    },

    createItem () {
      this.editedItem = _.cloneDeep(this.defaultItem)
      this.editedIndex = -1
      this.dialog = true
    },

    editItem (item) {
      this.editedIndex = this.DeviceCommand.indexOf(item)
      this.editedItem = item
      this.dialog = true
    },

    close () {
      this.dialog = false
    },

    async save () {
      console.log("SAVE ",this.editedIndex )
      this.$validator.validateAll().then(async result => {
      if (result) {
        if (this.editedIndex > -1) {
          console.log("UPDATE")
            await this.updateDeviceCommand(this.editedItem)
          } else {  //new item
            console.log("CREATE")
            await this.createDeviceCommand(this.editedItem)
          }
          this.getDeviceCommand({})
          this.close()
        }
        else {

        }
      })
    },

    comp_priceIndex (id) {
      try {
         return this.Global.indexTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },

    comp_globalvar_parent (id) {
      try {  
        const query = _.find(this.GlobalAll, {_id: id})
         return query.typeOf + " - " + query.value
      }
      catch {
        return ""
      }
    },

    
    createItemGlobalVar () {
      this.editedItemGlobalVar = _.cloneDeep(this.defaultItemGlobalVar)
      this.editedIndexGlobalVar = -1
      this.dialog_globalvar = true
    },

    editItemGlobalVar (item) {
      this.editedIndexGlobalVar = this.GlobalAll.indexOf(item)
      this.editedItemGlobalVar = item
      this.dialog_globalvar = true
    },

    closeGlobalVar () {
      this.dialog_globalvar = false
    },

    async saveGlobalVar () {
     // this.$validator.validateAll().then(async result => {
     // if (result) {
        if (this.editedIndexGlobalVar > -1) {
            await this.updateGlobal(this.editedItemGlobalVar)
          } else {  //new item
            await this.createGlobal(this.editedItemGlobalVar)
          }
          this.getGlobalAll({})
          this.closeGlobalVar()
        },
     //   else {

       // }
    //  })
    //},
    comp_subdevice_type (id) {
      try {  
        const query = _.filter(this.GlobalAll, {parent: id})
        return query
      }
      catch {
        return ""
      }
    },
    comp_subdevice_parent (id) {
      try {  
        let query = _.find(this.GlobalAll, {_id: id})
        query = _.find(this.GlobalAll, {_id: query.parent})
        return query.value
      }
      catch {
        return ""
      }
    },
    comp_globalvar (id) {
      try {  
        const query = _.find(this.GlobalAll, {_id: id})
        return query
      }
      catch {
        return ""
      }
    },
  },
  computed: {
    ...mapState({
      Global: ({ global }) => global.Global,
      GlobalAll: ({ global }) => global.GlobalAll,
      mySelf: ({auth}) => auth.mySelf,
      status_device_command: ({ device_command }) => device_command.status,
      DeviceCommand: ({ device_command }) => device_command.DeviceCommand
    }),

    formTitle () {
      return this.editedIndex === -1 ? 'New DeviceCommand' : 'Edit DeviceCommand'
    },
    
     formTitleGlobalVar() {
      return this.editedIndex === -1 ? 'New GlobalVar' : 'Edit GlobalVar'
    },

    globalDeviceType () {
      const query = _.filter(this.GlobalAll, {typeOf: "deviceTypes"})
      return query
    },

    all_device_types() {
      let query = []
      if (this.editedItem.parentTypeOf) {
        query = _.filter(this.GlobalAll, {parent: this.editedItem.parentTypeOf})
      }
      else {
        query = _.filter(this.GlobalAll, {typeOf: "deviceTypes"})
        //ToDo: Full Tree
        const query2 = _.filter(this.GlobalAll, {typeOf: "subDeviceTypes"})
        for (let item of query2) {
          query.push(item)
        }
      }

      return query
    },
  
  },

  watch: {
    dialog () {
      this.$validator.reset()
    },
    status(val) {
      this.loading = val === "fetching";
      if (val === "success") {
       
      }
    },
    loading(val) {
      this.$store.state.loading = val;
    }
  }
}

</script>