import Vue from "vue";
//import Vuetify from "vuetify";
import Vuetify from 'vuetify/lib';
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#FFC107",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        energy_primary: "#123456",
        energy_secondary: "#654321",
        background_secondary: "333333"
      },
      dark: {
        primary: "#FFC107",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        energy_primary: "#123456",
        energy_secondary: "#654321",
        background_secondary: "333333"
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {},
  },
};

export default new Vuetify(opts);
