import VueI18n from 'vue-i18n'

export default {
  messages: {
    "en": {
      "ok": "OK",
      "Menu":"Menu"    
    },
    "de": {
      "ok": "OK",
      "Menu": "Menü",
      "person": "Person",
      "Persons": "Personen",
      "energyUnit": "Energieerzeuger",
      "location": "Location",
      "contract": "Vertrag",
      "Dashboard": "Dashboard",
      "device": "Device",
      "admin": "Admin",
      "home": "Home",
      "administration": "Administration",
      "Sign Up": "Registrieren",
      "Forgot password?": "Passwort vergessen?",
      "You don't have an account yet and you want to sign up?": "Sie haben noch keinen User Zugang und wollen sich registrieren?",
      "Back to Login": "Zurück zum Login",
      "invoice": "Rechnung",
      "Reset password": "Passwort zurücksetzen",
      "Logout": "Abmelden",
      "Set New Password": "Neues Passwort festlegen",
      "Password reset error": "Fehler im Rücksetzen des Passwortes",
      "New Password successfully set": "Neues Passwort wurde erfolgreich gesetzt",
      "Account activated": "Account aktiviert",
      "You can now log in with your account.": "Sie können sich jetzt mit Ihrem Account anmelden.",
      "Account activation error": "Aktivierung des Accounts fehlgeschlagen",
      "Your request for password change successfully submitted.": "Ihre Anfrage zur Passwortänderung wurde erfolgreich übermittelt.",
      "Something went wrong": "Ein Fehler ist aufgetreten",
      "Password change": "Passwort ändern",
      "Your password has been changed": "Ihr Passwort wurde geändert",
      "Please check your e-mail.": "Bitte überprüfen Sie Ihren email Account",
      "user": "User",
      "login": "Anmelden",
    }
  }
}