<template>
  <!-- <v-container :style="cssVars" > -->
  <div :style="cssVars">
    <v-row class="text-center" >
      <v-col  > 
        <v-btn-toggle v-model="toggle_calendar" mandatory>
          <v-btn small value="day" class="pl-4 pr-4" text>{{$t('Day')}}</v-btn>
          <v-btn small value="month" class="pl-4 pr-4" text>{{$t('Month')}}</v-btn>
          <v-btn small value="year" class="pl-4 pr-4" text>{{$t('Year')}}</v-btn>
        </v-btn-toggle>
      </v-col> 
    </v-row>

    <v-row class="text-center">
      <v-col  cols="2" md="5" mt-0 >
        <v-icon @click="date_left()">chevron_left</v-icon>
      </v-col>  
      <v-col cols="8" md="2" mt-0 @click="showPicker = true"> 
        <!-- date_now() -->
        <div v-if="toggle_calendar == 'day'">
          {{startDate | moment("DD.MM.YYYY")}}<v-icon>arrow_drop_down</v-icon>
        </div>
        <div v-else-if="toggle_calendar == 'month'">
          {{startDate | moment("MM.YYYY")}}<v-icon>arrow_drop_down</v-icon>
        </div>
        <div v-else>
          {{startDate | moment("YYYY")}}<v-icon>arrow_drop_down</v-icon>
        </div> 
      </v-col>
      <v-col cols="2"  md="5" mt-0>
        <v-icon @click="date_right()">chevron_right</v-icon>
      </v-col>  

    </v-row>

    <v-dialog v-model="showPicker" >
      <v-row style="border-radius: 20px" class="pickerStyle">
        <v-col  v-if="toggle_calendar == 'year'"> 
            <van-picker     
              ref="myPicker"
              show-toolbar
              title=""
              @confirm="date_selected_year"
              @cancel="cancel_picker"
              :columns="yearColumns"
              :default-index="pickerIndex"
              confirm-button-text="OK"
              cancel-button-text="Abbrechen"
            />   
        </v-col>
        <v-col  v-else> 
            <van-datetime-picker     
              v-model="pickDate"
              :type="pickerType"
              title=""
              :max-date="maxDate"
              :min-date="minDate"
              @confirm="date_selected()"
              @cancel="cancel_picker()"
              :columns-order="['day', 'month', 'year']"
              confirm-button-text="OK"
              cancel-button-text="Abbrechen"
            />   
        </v-col>
      </v-row>
    </v-dialog>
    
    <v-row class="text-center">
      <v-col  v-if="selectedDevice" class="pa-0">      
        <v-btn class="mb-0 mx-2 " icon small color="white" @click="homeChart()"><v-icon>home</v-icon></v-btn>
        <v-btn v-if="datasetToggle[0]" class="mb-0 caption white--text" small color="#2E93fA" @click="dataOnOff(0)">Strombezug</v-btn>
        <v-btn v-else class="mb-0 caption white--text " small color="#DDDDDD" @click="dataOnOff(0)">Strombezug</v-btn>
        <v-btn v-if="datasetToggle[1]" class="mb-0 caption white--text" small color="#66DA26" @click="dataOnOff(1)">Einspeisung</v-btn>
        <v-btn v-else class="mb-0 caption white--text" small color="#DDDDDD"  @click="dataOnOff(1)">Einspeisung</v-btn>     
        <v-btn class="mb-0 mx-2 " icon small color="white" @click="loadEvents()"><v-icon>refresh</v-icon></v-btn>

        <div v-if="isMobile" v-hammer:pinch="handlePinch" v-hammer:pan="handlePan">
          <apexchart 
            class="ma-1 ml-2 mb-0"
            ref="chart" :height="chartHeight"  :options="apexChartOptions" :series="apexChartData">
          </apexchart>
        </div> 
        <div v-else>
          <apexchart 
            class="ma-1 ml-2 mb-0"
            ref="chart" :height="chartHeight"  :options="apexChartOptions" :series="apexChartData">
          </apexchart>
        </div> 
      </v-col>
    </v-row>
   
  </div>
  <!-- </v-container> -->
</template>

<script>

import { mapState, mapActions } from "vuex";
// import map from "lodash/map";
import _ from "lodash";
import Vue from 'vue';
import { VueHammer } from 'vue2-hammer';

// Hammer.js in Vue registrieren
Vue.use(VueHammer);

export default {

  name: "PowerChart",
  props: {selectedDevice: null},
  components: {
    VueHammer
  },
  data() {
    return {

      maxDate: this.$moment().toDate(),
      minDate: this.$moment('2023', 'YYYY').toDate(),
      pickDate: this.$moment().toDate(),
      showPicker: 0,
      yearColumns: [],
      pickerIndex: 0,
      toggle_calendar: "day",
      startDate: this.$moment().subtract(0,"days").format("YYYY-MM-DD"),
      endDate: this.$moment().format("YYYY-MM-DD"),
      chartData: {consumption: [], production: []},
      myEvents: [],
      datasetToggle: [true,true],
     
      apexChartOptions: {
        
        theme: {
          mode: "light",
        },
        legend: {
          show: false
        },
        colors: ['#2E93fA', '#66DA26'],

        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false, // true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [
              ]
            },
            autoSelected: 'zoom' // accepts -> zoom, pan, selection
          },
          zoom: {
            enabled: true,
          },
  
        },
        stroke: {
          width: "1",
          curve: "smooth"
        },
        fill: {
          type:'solid',
          opacity: [0.2, 0.5],
        },
        labels: [],
        markers: {
          size: 0
        },
        yaxis: [
          {
            title: {
              text: 'kW',
            },
            labels: {
              formatter: function (value) {
                return value.toFixed(2);
              }
            },
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
              datetimeUTC: false
          }
        },

        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            format: 'dd MMM HH:mm',
            formatter: undefined,
          },
          y: {
            formatter: function (y) {
              if(typeof y !== "undefined") {
                return  y.toFixed(2);
              }
              return y.toFixed(2);
            }
          }
        }

      },
      lastScale: 0,
      // eventInterval: null,
      // isWatch: false,
      refreshKey: 0,
      // apexChartData2: [{
      //     name: this.$t('Consumption'),
      //     type: 'area',
      //     data: []
      //   },
      //   {
      //     name: this.$t("Production"),
      //     type: 'area',
      //     data: []
      //   }],
      sum_total_positive: 0,
      sum_total_negative: 0,
        
    };
  },

  created: function () {
    // this.windowWidth = window.innerWidth;
    // this.windowHeight = window.innerHeight;
    this.yearColumns = this.getYears();

    if (!this.isMobile) {
      this.apexChartOptions.chart.toolbar.tools.download = true;
      this.apexChartOptions.chart.toolbar.tools.selection = true;
      this.apexChartOptions.chart.toolbar.tools.pan = true;
      this.apexChartOptions.chart.toolbar.tools.zoom = true;
      this.apexChartOptions.chart.toolbar.tools.zoomin = true;
      this.apexChartOptions.chart.toolbar.tools.zoomout = true;
      this.apexChartOptions.chart.toolbar.show = true;
    }
  },

  async mounted() {
    // console.log("Mount Power")
    this.loadEvents();
  },

  // beforeUnmount() {
  //   console.log("unmount")
  //   clearInterval(this.eventInterval);
  // },

  destroyed(){
    // clearInterval(this.eventInterval);
    // console.log("destroyed")
  },

  methods: {
    
    ...mapActions("event", ["getDeviceEvents"]),

    cancel_picker() {
      this.showPicker = 0;
      this.test = 0;
      //console.log("Cancel Picker ", this.showPicker)
    },
    date_right() {
      if (Number(this.$moment(this.startDate, "YYYY-MM-DD").format("X")) <  Number(this.$moment().startOf("day").format("X"))  ) {
        this.startDate = this.$moment(this.startDate, "YYYY-MM-DD").add(1, this.toggle_calendar).format("YYYY-MM-DD");
        this.pickDate = this.$moment(this.startDate, "YYYY-MM-DD").toDate();
        this.loadEvents();
      }
    },
    date_left() {
      this.startDate = this.$moment(this.startDate, "YYYY-MM-DD").subtract(1, this.toggle_calendar).format("YYYY-MM-DD");
      this.pickDate = this.$moment(this.startDate, "YYYY-MM-DD").toDate();
      this.loadEvents();
     
    },
    date_now() {
      this.startDate = this.$moment().format("YYYY-MM-DD");
      this.pickDate = this.$moment(this.startDate, "YYYY-MM-DD").toDate();
      this.loadEvents();
    },
    date_selected() {
      this.startDate = this.$moment(this.pickDate).format("YYYY-MM-DD");
      this.showPicker = false;
      this.loadEvents();
    },
    cancel_picker() {
      this.pickDate = this.$moment(this.startDate, "YYYY-MM-DD").toDate();
      this.showPicker = false;
    },
    date_selected_year(value) {
      //console.log(value)
      this.startDate = this.$moment(value, "YYYY").format("YYYY-MM-DD");
      this.showPicker = false;
      this.loadEvents();
    },

    async loadEvents() {
      if (this.selectedDevice._id) {
        // clearInterval(this.eventInterval);

        // this.chartData.consumption = [];
        // this.chartData.production = [];
    
        let queryStartDate = this.startDate;
        let queryEndDate = this.startDate;

        if (this.toggle_calendar == "day") {
          queryStartDate = this.$moment(this.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
          // this.setAutoRefreshEvent();
        } else if (this.toggle_calendar == "month") {
          queryStartDate = this.$moment(this.startDate, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD");
          queryEndDate = this.$moment(this.startDate, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD");
        } else {
          queryStartDate = this.$moment(this.startDate, "YYYY-MM-DD").startOf("year").format("YYYY-MM-DD");
          queryEndDate = this.$moment(this.startDate, "YYYY-MM-DD").endOf("year").format("YYYY-MM-DD");
        }

        //console.log("selectedDevice ", this.selectedDevice._id)
        await this.getDeviceEvents({
          deviceId: this.selectedDevice._id,
          //days: this.selectedDays,
          startDate: queryStartDate,
          endDate: queryEndDate,
          apexChart: true,
          view: this.toggle_calendar
        });

        let newEvents = JSON.parse(JSON.stringify(this.events));      
        //console.table(newEvents);
        this.myEvents = newEvents;


        this.sum_total_positive = newEvents.reduce((total, item) => total + item.total_positive, 0);
        this.sum_total_negative = newEvents.reduce((total, item) => total + item.total_negative, 0);

        if (this.toggle_calendar == "day") {
          this.sum_total_positive = this.sum_total_positive / 60;
          this.sum_total_negative = this.sum_total_negative / 60;
        }
        this.sum_total_negative = -this.sum_total_negative;
        //console.log("SUMME: ", this.sum_total_positive, this.sum_total_negative);

        

        this.resetData();
        
        // if (this.isWatch) { 
        //   this.homeChart();
        //   this.isWatch = false;
        // }
      }
    },

    async resetData() {
      let data = {};
      data.consumption = [];
      data.production = [];

      let idx = 0;

      //if (this.chartData.consumption.length != this.myEvents.length) {  //Compute funkt nicht ohne löschen
        this.chartData = {consumption: [], production: []};
        for (let i = 0; i < this.myEvents.length; i++) {
          this.chartData.consumption.push([]);
          this.chartData.production.push([]);
        }
      //}

      for (let el of this.myEvents) {
        
        const _time = this.$moment(el._id);        
        const _time_x = parseInt(_time.format("x"));
        
        if (this.datasetToggle[0]) {

          this.chartData.consumption[idx] = [_time_x, (el.total_positive).toFixed(2)];
        } else {
          // data.consumption.push([_time_x, 0]);
          
          this.chartData.consumption[idx] = [_time_x, 0];
          // console.log(this.chartData.consumption[idx])
        }
        if (this.datasetToggle[1]) {

          this.chartData.production[idx] = [_time_x, (-el.total_negative).toFixed(2)];
        } else {

          this.chartData.production[idx] = [_time_x, 0];
        }

        idx++;
      }
      
      // this.chartData = data; 

      //const chart = this.$refs.chart.chart;
      // await chart.render();
      // chart.resetSeries()
      // chart.appendSeries([{
      //   consumption: data.consumption,
      //   production: data.production
      // }]);
      // console.log(chart)
      // console.table(this.chartData.consumption);
      // data = this.chartData;
      // this.chartData = {consumption: [], production: []};
      // this.chartData = data;
      // window.dispatchEvent(new Event('resize'));
      // this.$forceUpdate();
      // try {
      //ToDo: only with error correct refresh.
      // chart.render();
      // } catch (err) {

      // }
      // this.homeChart();
      

      // this.homeChart();
      // this.apexChartData2[0].data = this.chartData.consumption;
      // this.apexChartData2[1].data = this.chartData.production;
      // chart.updateSeries([{
      //   data: this.chartData.consumption
      // }])
      // console.log("HCMin: ", this.homeChartMin, this.homeChartMax, this.$moment(this.homeChartMin, "x").format("DD.MM.YY"));
      // console.log(this.chartData.consumption[0]);
      this.forceChartXUpdate();
    },

    forceChartXUpdate() {
      this.refreshKey++;
      //console.log(this.$refs)
      this.$refs.chart.updateOptions({
        xaxis: {
          type: 'datetime',
          labels: {
              datetimeUTC: false
          }
        },
      });
      // window.dispatchEvent(new Event('resize'));
      // this.$forceUpdate();
    },

    dataOnOff(dataset) {
      // console.log("OnOff " , dataset, this.datasetToggle[dataset]);
      this.datasetToggle[dataset] = !this.datasetToggle[dataset];
      // console.log("OnOff " , dataset, this.datasetToggle[dataset]);
      this.resetData();
      // this.loadEvents();
      // console.log(this.apexChartDataSet);
      // this.apexChartData();
      //this.$forceUpdate();
    },

    panChart(direction) {
      const chart = this.$refs.chart.chart;
      const min = chart.w.globals.minX;
      const max = chart.w.globals.maxX;
      const range = max - min;
      const panAmount = range * 0.1; // Pan um 10% des aktuellen Bereichs

      let newMin, newMax;
      // console.log(min, max);
      if (direction === 'left') {
        newMin = min - panAmount;
        newMax = max - panAmount;
      } else if (direction === 'right') {
        newMin = min + panAmount;
        newMax = max + panAmount;
      }

      if (newMin >= this.homeChartMin && newMax <= this.homeChartMax) {
        chart.updateOptions({
          xaxis: {
            min: newMin,
            max: newMax
          }
        });
      } 
      // else {
      //   this.homeChart();
      // }
    },

    zoomChart(direction, pinchX) {
      const chart = this.$refs.chart.chart;
      let min = chart.w.globals.minX;
      let max = chart.w.globals.maxX;

      const range = max - min;
      const zoomAmountIn = range * 0.1; // Zoom um 20% des aktuellen Bereichs
      const zoomAmountOut = range * 0.3;
      //const centerX = range / 2 + min;
      const _posPercentage = pinchX / window.innerWidth;
      //const abweichung = (window.innerWidth / 2) - pinchX;
      
      let newMin, newMax;

      if (direction === 'in') {

        if (_posPercentage > 0.5) {
          min += ( (_posPercentage - 0.5) * range) * 2;
        } else if (_posPercentage < 0.5) {
          max -= ( (0.5 - _posPercentage) * range) * 2;
        }

        newMin = min + zoomAmountIn;
        newMax = max - zoomAmountIn;

      } else if (direction === 'out') {
        newMin = min - zoomAmountOut;
        newMax = max + zoomAmountOut;
      }

      // console.log("pinch: ", _posPercentage, pinchX,  min, max, newMin, newMax, this.homeChartMin, this.homeChartMax)

      if (newMin >= this.homeChartMin && newMax <= this.homeChartMax) {
        chart.updateOptions({
          xaxis: {
            min: newMin,
            max: newMax
          }
        });
      } else {
        this.homeChart();
      }
    },

    homeChart() {
      //console.log(this.$refs)
      const chart = this.$refs.chart.chart;

      chart.updateOptions({
        xaxis: {
          min: this.homeChartMin,
          max: this.homeChartMax
        }
      });
    },

    handlePinch(event) {

      // console.log('Pinch detected:', event.scale, 
      //   event.center.x , event.center.y,
      // );
      if(!event.isFirst && !event.isFinal) {
        //console.log('Pinch detected:', event.scale, (this.lastScale).toFixed(1), (event.scale).toFixed(1));
        if ((this.lastScale).toFixed(1) != (event.scale).toFixed(1)) {
          this.lastScale = event.scale;
          if (event.scale > 1) {
            this.zoomChart('in', event.center.x);
          } 
          else if (event.scale < 1) {
            this.zoomChart('out', event.center.x);
          }  
        }
      }
    },
    handlePan(event) {
      //console.log('Pan detected:', event);
      if(!event.isFirst && !event.isFinal) {
        // console.log('Pan detected:', event.direction);
        if (event.direction == 2) {
          this.panChart('right');
        } 
        else if (event.direction == 4) {
          this.panChart('left');
        }
      }
      
    },

    // setAutoRefreshEvent() {
    //   this.eventInterval = setInterval(async () => {
    //     this.$store.commit('SET_NOLOADING',true);
    //     // console.log("Load Event with Interval");
    //     await this.loadEvents();
    //     this.$store.commit('SET_NOLOADING',false);
    //   },60000); //60000
    // },
    formatPower(value) {
      let new_value = "";
      if (value < 1000) {
        new_value = value + "W";
      } else if (value < 100 * 1000) {
        new_value = (value / 1000).toFixed(2) + "kW";
      } else {
        new_value = (value / 1000).toFixed(1) + "kW";
      }
      return new_value;
    },
    getYears() {
      // Beispiel: 1920 bis 2030
      const years = [];
      for (let y = 2023; y <= Number(this.$moment().format("YYYY")); y++) {
        years.push(String(y)); // strings oder objects
      }
      //console.log(years);
      return years;
    },
   
  },
  computed: {
    ...mapState({
      events: ({ event }) => event.events,
      eventStatus: ({ event }) => event.status,
    }),

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
      // smAndDown => true, wenn Gerät <= small
    },

    // getColumns() {
    //   let _result = ['day', 'month', 'year'];
    //   if (this.toggle_calendar == "month") {
    //     _result = ['month', 'year'];
    //   } else if (this.toggle_calendar == "year") {
    //     _result = ['year'];
    //   }
    //   return _result;
    // },

    pickerType() {
      let _result = 'date';
      if (this.toggle_calendar == "month") {
        _result = "year-month";
      } else if (this.toggle_calendar == "year") {
        _result = "year-month";
      }
      return _result;
    },

    apexChartData() {
      this.refreshKey;
      //console.log("refresh apexChartData")
      return [
        {
          name: this.$t('Consumption'),
          type: 'area',
          data: this.chartData.consumption
        },
        {
          name: this.$t("Production"),
          type: 'area',
          data: this.chartData.production
        }
      ]
    },

    chartHeight() {
      //let h = (window.innerHeight / 4);
      let h = (window.innerHeight / 3 );
      //console.log(h)
      return h;
    },

    pickerPos() {
      let h = (window.innerHeight - 475 );
      const res = h.toString();
      return res
    },

    homeChartMin() {
      return this.chartData.consumption[0][0];
    },
    homeChartMax() {
      return this.chartData.consumption[this.chartData.consumption.length - 1][0];
    },

    cssVars() {
      return {
        '--pickerPos': this.pickerPos + 'px',
      }
    }

  },
  watch: {
    toggle_calendar (value) {  
      // this.isWatch = true;
      if (value == "year") {
        const _year = this.$moment(this.startDate,"YYYY-MM-DD").format("YYYY");
        const _idx = this.yearColumns.findIndex(obj => {
          return obj == _year;
        })
        //console.log(_idx, _year)
        this.pickerIndex = _idx;
      }
      this.loadEvents();
    },
    async selectedDevice() {
      // this.isWatch = true;
      this.loadEvents();

    },
  },
};
</script>

<style  scoped>

.caption2 {
  font-size: 10px;
}

.pickerStyle {
  position: absolute;
  top: var(--pickerPos);
  left: 24%;
  width: 55%;
  
}



</style>