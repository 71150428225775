const REQUEST_PRICE_INDEX = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_PRICE_INDEX = (state, { body }) => {
  state.status = "success";
  state.PriceIndex = body;
};

const FAILURE_REQUEST_PRICE_INDEX = state => {
  state.status = "failure";
};

const REQUEST_UPDATE_PRICEINDEX = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_PRICEINDEX = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_PRICEINDEX = state => {
  state.statusUpdate = "failure";
};

const REQUEST_CREATE_PRICEINDEX = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_PRICEINDEX = state => {
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_PRICEINDEX = state => {
  state.statusCreate = "failure";
};

export default {
  REQUEST_PRICE_INDEX,
  SUCCESS_REQUEST_PRICE_INDEX,
  FAILURE_REQUEST_PRICE_INDEX,
  REQUEST_CREATE_PRICEINDEX,
  SUCCESS_REQUEST_CREATE_PRICEINDEX,
  FAILURE_REQUEST_CREATE_PRICEINDEX,
  REQUEST_UPDATE_PRICEINDEX,
  SUCCESS_REQUEST_UPDATE_PRICEINDEX,
  FAILURE_REQUEST_UPDATE_PRICEINDEX
};
