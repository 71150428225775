<template>
  <v-container fluid v-if="status === 'success'">
    <v-row class="align-center justify-center">
      <!-- Contract Section -->
      <v-col md="12">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Contract</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="editedIndex = -1"
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog" max-width="750px" persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6" v-if="editedIndex === -1">
                          <v-select 
                            :items="providerNames" 
                            label="Provider" 
                            v-model="provider_index"
                            @change="set_provider"
                            item-text="name"
                            item-value="index"
                            v-validate="'required'"
                            data-vv-name="form_provider"
                            :error-messages="errors.collect('form_provider')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" v-else>
                          <v-select 
                            :items="providerNames" 
                            label="Provider" 
                            v-model="provider_index"
                            item-text="name"
                            item-value="index"
                            disabled
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            :items="customerNames" 
                            label="Customer" 
                            v-model="customer_index"
                            @change="set_customer"
                            item-text="name"
                            item-value="index"
                            v-validate="'required'"
                            data-vv-name="form_customer"
                            :error-messages="errors.collect('form_customer')"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select 
                            :items="energyUnitNames" 
                            label="EnergyUnit" 
                            v-model="energyUnit_array"
                            item-text="name"
                            item-value="index"
                            v-validate="'required'"
                            data-vv-name="form_energyUnit"
                            :error-messages="errors.collect('form_energyUnit')"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="comp_signing_date"
                            label="signing_date"
                            autofocus 
                            type="date"
                            v-validate="'required'"
                            data-vv-name="form_signing_date"
                            :error-messages="errors.collect('form_signing_date')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="comp_valid_from"
                            label="valid_from"
                            type="date"
                            v-validate="'required'"
                            data-vv-name="form_valid_from"
                            :error-messages="errors.collect('form_valid_from')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="comp_valid_to"
                            label="valid_to"
                            type="date"
                            v-validate="'required'"
                            data-vv-name="form_valid_to"
                            :error-messages="errors.collect('form_valid_to')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="editedItem.discount_monthly_fix"
                            label="Discount/Month €"
                            type="number"
                            :prefix="currency"
                            v-validate="'required'"
                            data-vv-name="form_discount_monthly_fix"
                            :error-messages="errors.collect('form_discount_monthly_fix')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="editedItem.discount_monthly_var"
                            label="Discount/Month %"
                            type="number"
                            suffix="%"
                            v-validate="'required'"
                            data-vv-name="form_discount_monthly_var"
                            :error-messages="errors.collect('form_discount_monthly_var')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="editedItem.discount_vat"
                            label="Discount Vat"
                            type="number"
                            suffix="%"
                            v-validate="'required'"
                            data-vv-name="form_discount_vat"
                            :error-messages="errors.collect('form_discount_vat')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="editedItem.accounting_number"
                            label="Accounting Number"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <!-- Contract DataTable -->
          <v-data-table
            :headers="headers"
            :items="contract"
            class="elevation-0"
            :search="search"
            :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
            sort-by="contract_number"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr  @click="on_click(item)" :class="{ 'grey--text': !item.active }">
                <td>{{ item.contract_number }}</td>
                <td>{{ comp_provider(item.provider) }}</td>
                <td>{{ comp_customer(item.customer) }}</td>
                <td>
                  <span v-for="energyUId in item.energyunit" :key="energyUId" class="caption">
                    {{ comp_energyunit(energyUId) }}
                  </span>
                </td>
                <td>{{ item.signing_date | moment("DD.MM.YYYY") }}</td>
                <td>{{ item.valid_from | moment("DD.MM.YYYY") }}</td>
                <td>{{ item.valid_to | moment("DD.MM.YYYY") }}</td>
                <td>
                  <template v-if="item.active">
                    {{ currency }} {{ item.discount_monthly_fix }}
                  </template>
                  <template v-else>
                    {{ currency }} {{ item.discount_energy_costs_var }}
                  </template>
                </td>
                <td>{{ item.discount_monthly_var }} %</td>
                <td>{{ item.discount_vat }} %</td>
                <td>{{ item.accounting_number }}</td>
                <td class="justify-center layout px-0">
                  <v-icon small class="mr-2" @click.stop="editItem(item)">mdi-pencil</v-icon>
                  <v-icon
                    v-if="item.active && mySelf.permissions.includes('deactivate')"
                    small
                    @click.stop="deactivateItem(item)"
                  >mdi-delete</v-icon>
                  <v-icon
                    v-else-if="mySelf.permissions.includes('deactivate')"
                    small
                    @click.stop="activateItem(item)"
                  >mdi-eye</v-icon>
                  <v-icon
                    v-if="!item.active && mySelf.permissions.includes('delete')"
                    small
                    @click.stop="deleteItem(item)"
                  >mdi-cancel</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>

      <!-- Metering Points Section -->
      <v-col md="12" v-if="editedIndex > -1">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title v-if="Contract[editedIndex]">
              Metering Points of # {{ Contract[editedIndex].contract_number }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search_metering_point"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createMeteringPoint">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog_metering_point" max-width="750px" persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitleMeteringPoint }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6" v-if="editedMeteringPointIndex === -1">
                          <v-select 
                            :items="locationNames" 
                            label="location" 
                            v-model="location_index"
                            @change="set_location"
                            item-text="name"
                            item-value="index"
                            v-validate="'required'"
                            data-vv-name="form_location"
                            :error-messages="errors.collect('form_location')"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" v-else-if="location_index > -1">
                          <v-text-field 
                            v-model="Locations[location_index].name" 
                            label="Location"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field 
                            v-model="editedMeteringPoint.start_value" 
                            label="Start Value" 
                            type="number"
                            v-validate="'required'"
                            data-vv-name="form_metering_point_start"
                            :error-messages="errors.collect('form_metering_point_start')"
                          ></v-text-field>
                        </v-col>
                        <v-col md="3">
                          <v-checkbox
                            v-model="show_submeter" 
                            label="SubMeter"
                            @change="unset_submeter"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="6" v-if="show_submeter">
                          <v-select 
                            :items="meterNames" 
                            label="Submeter Of" 
                            v-model="meter_index"
                            @change="set_sublocation"
                            item-text="name"
                            item-value="index"
                          ></v-select>
                          <v-col md="3" v-if="show_submeter">
                            <v-checkbox
                              v-model="editedMeteringPoint.submeter_subtract"
                              label="Subtract"
                            ></v-checkbox>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close_metering_point">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save_metering_point">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers_metering_point"
            :items="editedItem.metering_point"
            class="elevation-1"
            :search="search_metering_point"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            sort-by="id"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr >
                <td>{{ comp_location(item.location) }}</td>
                <td>{{ item.start_value }}</td>
                <td>{{ comp_location(item.submeter_of) }}</td>
                <td>{{ item.submeter_subtract }}</td>
                <td class="justify-center layout px-0">
                  <v-icon small class="mr-2" @click.stop="editMeteringPoint(item)">mdi-pencil</v-icon>
                  <v-icon
                    v-if="mySelf.permissions.includes('delete')"
                    small
                    class="mr-2"
                    @click.stop="deleteMeteringPoint(item)"
                  >mdi-delete</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>

      <!-- Billing Types Section -->
      <v-col md="12" v-if="editedIndex > -1">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title v-if="Contract[editedIndex]">
              Billing Types of # {{ Contract[editedIndex].contract_number }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search_billing_type"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-btn color="green" fab small dark class="mb-2" @click="createBillingType">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog_billing_type" max-width="750px" persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitleBillingType }}</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <v-row>
                        <v-col md="5" v-if="Global.billingTypes">
                          <v-select 
                            :items="sortGlobalBillingTypes" 
                            item-text="value"
                            item-value="_id"  
                            label="Billing Type" 
                            v-model="editedBillingType.typeOf"
                            v-validate="'required'"
                            data-vv-name="form_typeOf"
                            :error-messages="errors.collect('form_typeOf')"
                          ></v-select>
                        </v-col>
                        <v-col md="3">
                          <v-checkbox
                            v-model="editedBillingType.calc_from"
                            label="from EnergyUnit"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="3" v-if="!editedBillingType.calc_from">
                          <v-checkbox
                            v-model="editedBillingType.use_index"
                            label="from Index"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="3">
                          <v-checkbox
                            v-model="editedBillingType.calc_var"
                            label="var (per kWh)"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="3">
                          <v-checkbox
                            v-model="editedBillingType.is_additional_fee"
                            label="is additional fee"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="3" v-if="Global.indexTypes && !editedBillingType.calc_from && editedBillingType.use_index">
                          <v-select 
                            :items="Global.indexTypes" 
                            item-text="value"
                            item-value="_id"  
                            label="Index Type" 
                            v-model="editedBillingType.price_index"
                          ></v-select>
                        </v-col>
                        <v-col md="2" v-if="!editedBillingType.calc_from">
                          <v-text-field
                            v-model="editedBillingType.price_fix"
                            label="Price"
                            :prefix="currency"
                          ></v-text-field>
                        </v-col>
                        <v-col md="2" v-if="!editedBillingType.calc_from">
                          <v-text-field
                            v-model="editedBillingType.vat"
                            label="vat"
                            type="number"
                            suffix="%"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="close_billing_type">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save_billing_type">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers_billing_type"
            :items="editedItem.billing_type"
            class="elevation-1"
            :search="search_billing_type"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            sort-by="id"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ comp_billingType(item.typeOf) }}</td>
                <td>{{ item.calc_from }}</td>
                <td>{{ item.use_index }}</td>
                <td>{{ item.calc_var }}</td>
                <td>{{ comp_priceIndex(item.price_index) }}</td>
                <td v-if="item.use_index">{{ comp_priceIndexBase(item.price_index) }}</td>
                <td v-else></td>
                <td>{{ item.price_fix }}</td>
                <td>{{ item.vat }}</td>
                <td>{{ item.is_additional_fee }}</td>
                <td class="justify-center layout px-0">
                  <v-icon small class="mr-2" @click.stop="editBillingType(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="mySelf.permissions.includes('delete')"
                    small
                    class="mr-2"
                    @click.stop="deleteBillingType(item)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>

      <!-- Invoices Section -->
      <v-col md="12" v-if="editedIndex > -1">
        <div>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              Invoices of # {{ Contract[editedIndex].contract_number }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search_invoice"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
          <v-data-table
            :headers="headers_invoice"
            :items="Invoice"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
            :items-per-page="50"
            :search="search_invoice"
            sort-by="id"
            must-sort
          >
            <template v-slot:item="{ item }">
              <tr >
                <td>{{ item.invoice_prefix + item.invoice_nr }}</td>
                <td>{{ item.invoice_date | moment("DD.MM.YYYY") }}</td>
                <td>{{ item.pdf }}</td>
                <td class="justify-center layout px-0" v-if="item.pdf">
                  <v-icon small class="mr-2" @click.stop="showInvoicePdf(item)">
                    mdi-file-pdf
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";
//import api from "../api";
import _ from "lodash";

export default {
  name: "contract",
  data() {
    return {
      //Required: [v => !!v || "The input is required"],
      // pagination: {
      //   sortBy: 'timestamp',
      //   descending: true
      // },
      // pagination2: {
      //   sortBy: 'timestamp',
      //   descending: true
      // },
      // pagination3: {
      //   sortBy: 'timestamp',
      //   descending: true
      // },

      contract: [],
      //person: {},
      //history: [],
      location_index: 0,
      provider_index: 0,
      customer_index: 0,
      meter_index: 0,
      energyUnit_array: [],

      //history: [],
      dialog: false,
      dialog_metering_point: false,
      dialog_billing_type: false,
      //dialog_billing: false,
      //dialog_invest: false,
      search: '',
      search_metering_point: '',
      search_billing_type: '',
      search_invoice: '',
      headers: [
        { text: '#', align: 'left', sortable: true, value: 'contract_number'},
        { text: 'provider', align: 'left', sortable: true, value: 'provider'},
        { text: 'customer', align: 'left', sortable: true, value: 'customer'},
        { text: 'energyunit', value: 'energyunit' },
        { text: 'signing_date', align: 'left', sortable: true, value: 'signing_date'},
        { text: 'valid_from', value: 'valid_from' },
        { text: 'valid_to', value: 'valid_to' },
        { text: 'Discount/M €', value: 'discount_monthly_fix' },
        { text: 'Discount/M %', value: 'discount_monthly_var' },
        { text: 'Discount Vat', value: 'discount_vat' },
        { text: 'Accounting nr', value: 'accounting_number' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      headers_metering_point: [
        { text: 'location', sortable: true, value: 'location' },
        { text: 'start_value', sortable: true, value: 'start_value' },
        { text: 'submeter of', sortable: true, value: 'submeter_of' },
        { text: 'submeter_subtract', sortable: true, value: 'submeter_subtract' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      headers_invoice: [
        { text: 'invoice_number', sortable: true, value: 'invoice_number' },
        { text: 'invoice_date', sortable: true,value: 'invoice_date' },
        { text: 'pdf', align: 'left', sortable: true, value: 'pdf'},
        { text: 'Actions', value: 'action', sortable: false }
      ],
      headers_billing_type: [
        { text: 'typeOf', sortable: true, value: 'typeOf' },
        { text: 'from EnergyUnit', sortable: false,value: 'calc_from' },
        { text: 'use_index', align: 'left', sortable: false, value: 'use_index'},
        { text: 'var (per kWh)', sortable: false,value: 'calc_var' },
        { text: 'Index', align: 'left', sortable: true, value: 'price_index'},
        { text: 'Index_Base', align: 'left', sortable: true, value: 'price_index_base'},
        { text: 'price_fix', value: 'price_fix', sortable: false },
        { text: 'vat', value: 'vat', sortable: false },
        { text: 'is additional fee', value: 'is_additional_fee', sortable: false },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      editedIndex: -1,
      editedMeteringPointIndex: -1,
      editedBillingTypeIndex: -1,
      //editedHistoryIndex: -1,
      editedItem: {},
      defaultItem: {
        active: true,
        provider: null,
        customer: null,
        location: null,
        energyunit: [],
        owning_location: null,
        signing_date: this.$moment().toDate(),
        valid_from: this.$moment().startOf('month').toDate(),
        valid_to: this.$moment().endOf('month').toDate(),
        discount_monthly_fix: 0,
        discount_monthly_var: 0,
        discount_vat: 20,
        accounting_number: ''
      },
      editedMeteringPoint: {},
      default_editedMeteringPoint: {
        location: null,
        start_value: 0,
        last_invoiced_value: 0,
        submeter_of: null,
        submeter_subtract: false
      },
      show_submeter: false,
      editedBillingType: {},
      default_editedBillingType: {
        typeOf: null,
        calc_from: false,
        use_index: true,
        calc_var: true,
        price_fix: 0,
        price_index: null,
        vat: 20
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions("person", [
      "getPersons"
      ]),
    ...mapActions(
      "location", ["getLocation"]
      ),

    ...mapActions(
      "energyunit", [
        "getEnergyUnit"
        ]
      ),
    ...mapActions(
      "contract", [
        "getContract",
        "getContractHistory",
        "deleteContract",
        "updateContract",
        "deactivateContract",
        "createContract"
        ]
    ),
    ...mapActions(
      "invoice", [
        "getInvoice"
        ]
      ),
    ...mapActions(
      "global", ["getGlobal"]
      ),
    ...mapActions(
      "price_index", [
        "getPriceIndex",
        "createPriceIndex",
        "updatePriceIndex"
        ]
      ),
    async initData() {
      await this.getGlobal({})
      await this.getPriceIndex({})
      await this.getLocation({})
      await this.getPersons({})
      await this.getEnergyUnit({})    
      await this.getContract({})
    },

    createItem () {
      this.editedItem = _.cloneDeep(this.defaultItem)
      this.editedIndex = -1
      //this.editedHistoryIndex = -1
      //this.history = []
      this.location_index = -1
      this.customer_index = -1
      this.provider_index = -1
      this.dialog = true
    },

    createMeteringPoint () {
      this.editedMeteringPointIndex = -1
      this.editedMeteringPoint = _.cloneDeep(this.default_editedMeteringPoint)
      this.meter_index = 0
      this.dialog_metering_point = true
    },

    createBillingType () {
      this.editedBillingTypeIndex = -1
      this.editedBillingType = _.cloneDeep(this.default_editedBillingType)
      this.dialog_billing_type = true
    },

    editItem (item) {
      //this.editedHistoryIndex = this.history.indexOf(item)
 
      this.editedItem = item
      this.location_index = this.Locations.findIndex((loc) => {return loc._id == this.editedItem.location})
      this.customer_index = this.Persons.findIndex((per) => {return per._id == this.editedItem.customer})
      this.provider_index = this.Persons.findIndex((per) => {return per._id == this.editedItem.provider})

      this.energyUnit_array = []
      //console.log(this.editedItem.energyunit)
      this.editedItem.energyunit.forEach((eU) => {
        this.energyUnit_array.push(_.find(this.energyUnitNames, {_id: eU}).index)
      })

      this.dialog = true
    },

    editMeteringPoint (item) {
      this.editedMeteringPoint = Object.assign({}, item)
      this.editedMeteringPointIndex = this.contract[this.editedIndex].metering_point.indexOf(item)
      //console.log(this.editedMeteringPointIndex)
      this.meter_index = _.findIndex(this.editedItem.metering_point, {location: item.submeter_of})
      this.location_index = _.findIndex(this.Locations, {_id: item.location})
      console.log("EDIT")
      if (this.editedMeteringPoint.submeter_of != null) {
        this.show_submeter = true
      }
      this.dialog_metering_point = true
    },

    editBillingType (item) {
      this.editedBillingType = Object.assign({}, item)
      this.editedBillingTypeIndex = this.contract[this.editedIndex].billing_type.indexOf(item)
      //console.log(this.editedBillingTypeIndex)
      this.dialog_billing_type = true
    },

    async deleteItem (item) {
      confirm('Are you sure you want to delete this item?') && await this.deleteContract(item._id)
      this.history = []
      this.getContract({})
    },

    async deleteMeteringPoint (item) {
      this.editedMeteringPoint = Object.assign({}, item)
      this.editedMeteringPointIndex = this.contract[this.editedIndex].metering_point.indexOf(item)
      console.log(this.editedItem.metering_point)
       console.log(this.editedMeteringPoint)
      _.remove(this.editedItem.metering_point, (mp => {return this.editedMeteringPoint._id == mp._id}))
      console.log(this.editedItem.metering_point)
      await this.updateContract(this.editedItem)
      this.getContract({})
    },

    async deleteBillingType (item) {
      this.editedBillingType = Object.assign({}, item)
      this.editedBillingTypeIndex = this.contract[this.editedIndex].billing_type.indexOf(item)
      console.log(this.editedItem.billing_type)
       console.log(this.editedBillingType)
      _.remove(this.editedItem.billing_type, (mp => {return this.editedBillingType._id == mp._id}))
      console.log(this.editedItem.billing_type)
      await this.updateContract(this.editedItem)
      this.getContract({})
    },

    async deactivateItem (item) {
      await this.deactivateContract(item._id)
      this.getContract({})
    },

    async on_click (item) {
      this.editedIndex = this.contract.indexOf(item)
      this.editedItem = Object.assign({}, item)
      console.log("ON CLICK")
      await this.getInvoice(item._id)
    },
 

    async activateItem (item) {
      await this.deactivateContract(item._id)
      this.getContract({})
    },

    close () {
      this.dialog = false
    },

    close_metering_point () {
      this.dialog_metering_point = false
    },

    close_billing_type () {
      this.dialog_billing_type = false
    },

    async save () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.editedItem.valid_to = this.$moment(this.editedItem.valid_to).endOf("day").format()
          this.editedItem.energyunit = []
          this.energyUnit_array.forEach(eU => {
            this.editedItem.energyunit.push(this.EnergyUnit[eU]._id)
          })
          console.log(this.editedItem.energyunit)
          if (this.editedIndex > -1) {
            await this.updateContract(this.editedItem)
          } else {  //new item
            await this.createContract(this.editedItem)
          }
        
          this.getContract({})
          this.close()
        }
        else {

        }
      })
    },

    async save_metering_point () {
    //  this.$validator.validateAll().then(async result => {
    //    if (result) {
          console.log("SAVE Metering_Point")
          console.log(this.editedMeteringPoint)
          if (this.editedMeteringPointIndex > -1) {
            console.log("SAVE UPDATE")
            this.editedItem.metering_point[this.editedMeteringPointIndex] = this.editedMeteringPoint        
          } else {  //new item
            console.log("SAVE NEW")
            this.editedItem.metering_point.push(this.editedMeteringPoint)
          }
          await this.updateContract(this.editedItem)
          this.getContract({})
          this.close_metering_point()
          
    /*    }
        else {
        }
      })*/
      //await this.getEnergyUnit({})
    },

    async save_billing_type () {
    //  this.$validator.validateAll().then(async result => {
    //    if (result) {
          console.log("SAVE Metering_Point")
          console.log(this.editedBillingType)
          if (this.editedBillingType.calc_from) {
            this.editedBillingType.use_index = false
            this.editedBillingType.price_index = null
            this.editedBillingType.price_fix = 0
            this.editedBillingType.vat = 0
          }
          if (!this.editedBillingType.use_index) {
            this.editedBillingType.price_index = null
          }
          if (this.editedBillingTypeIndex > -1) {
            console.log("SAVE UPDATE")
            this.editedItem.billing_type[this.editedBillingTypeIndex] = this.editedBillingType        
          } else {  //new item
            console.log("SAVE NEW")
            this.editedItem.billing_type.push(this.editedBillingType)
          }
          await this.updateContract(this.editedItem)
          this.getContract({})
          this.close_billing_type()
          
    /*    }
        else {
        }
      })*/
      //await this.getEnergyUnit({})
    },

    comp_provider (id) {
      try{
        let findPerson = this.Persons.filter(obj => {return obj._id == id})[0]
        return findPerson.companyname + ' - ' + findPerson.lastname
      }
      catch {}
    },
    comp_customer (id) {
      try{
        let findPerson = this.Persons.filter(obj => {return obj._id == id})[0]
        return findPerson.companyname + ' - ' + findPerson.lastname
      }
      catch {}
    },
    comp_location (id) {
      try{
        const loc = _.find(this.Locations, {_id: id})
        //_.find(this.Locations, {_id: loc.parent}).name  + ' - ' +
        return _.find(this.Locations, {_id: loc.parent}).name  + ' - ' + loc.name //this.Locations.filter(obj => {return obj._id == id})[0].name
      }
      catch {}
    },
    comp_energyunit (id) {
      try{
        return this.EnergyUnit.filter(obj => {return obj._id == id})[0].name
      }
      catch {}
    },
    comp_priceIndex (id) {
      try {
        return this.Global.indexTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },
    comp_priceIndexBase (id) {
      try {
        const base_index = _.find(this.PriceIndex, o => o.typeOf == id && new Date(o.valid_from) <= new Date(this.editedItem.signing_date) && new Date(o.valid_to) >= new Date(this.editedItem.signing_date))
        return base_index.value
      }
      catch {
        return "ERROR - No Index at signing date found!"
      }
    },
    comp_billingType (id) {
      try {
        return this.Global.billingTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },
    set_location (index) {
      this.editedMeteringPoint.location = this.Locations[index]._id
    },
    set_sublocation (index) {
      this.editedMeteringPoint.submeter_of =  this.editedItem.metering_point[index].location
    },
    unset_submeter () {
      console.log("Unset")
      if (!this.show_submeter) {
        this.editedMeteringPoint.submeter_of = null
        this.editedMeteringPoint.submeter_subtract = false
      } 
      else {
        this.editedMeteringPoint.submeter_of = this.editedItem.metering_point[0].location
        this.meter_index = 0
      }
      this.$forceUpdate
    },
    set_provider (index) {
      this.editedItem.provider = this.Persons[index]._id
    },   
    set_customer (index) {
      this.editedItem.customer = this.Persons[index]._id
    },
    showInvoicePdf (item) {
      console.log(item)
      window.open("/api/image/" + item.contract + '/' + item.pdf + '.pdf', '_blank')
    }    
  },
  computed: {
    ...mapState({
      Locations: ({ location }) => location.Locations,
      Persons: ({ person }) => person.Persons,
      EnergyUnit: ({ energyunit }) => energyunit.EnergyUnit,
      Contract: ({ contract }) => contract.Contract,
      status: ({ contract }) => contract.status,
      statusUpdate: ({ contract }) => contract.statusUpdate,
      statusCreate: ({ contract }) => contract.statusCreate,
      statusDelete: ({ contract }) => contract.statusDelete,
      statusDeactivate: ({ contract }) => contract.statusDeactivate,
      Global: ({ global }) => global.Global,
      Invoice: ({ invoice }) => invoice.Invoice,
      mySelf: ({auth}) => auth.mySelf,
      PriceIndex: ({price_index}) => price_index.PriceIndex
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'New Contract' : 'Edit Contract'
    },
    formTitleMeteringPoint () {
      return this.editedMeteringPointIndex === -1 ? 'New Metering Point' : 'Edit Metering Point'
    },
    formTitleBillingType () {
      return this.editedBillingTypeIndex === -1 ? 'New Billing Type' : 'Edit Billing Type'
    },
    locationNames () {
      let nonactivelocnames = []
      let activecontracts = _.filter(this.Contract, {active: true})
      let activelocnames = []

      //ToDo: eleganter
      activecontracts.forEach(acon => {
        acon.metering_point.forEach(mp => {
          activelocnames.push(mp.location)
        })
      })

      let meterArray = [];
      let meters = this.Global.locationTypes.filter(obj=>{return obj.value == "heat meter" || obj.value == "electricity meter" || obj.value == "water meter"});
      for (let meter of meters) {
        meterArray.push(meter._id);
      }
      //console.log(meterArray);
      this.Locations.forEach((loc, index) => {
       // if (loc.type === _.find(this.Global.locationTypes, function(obj) {return obj.value == "electricity meter" || obj.value == "heat meter" || obj.value == "water meter"})._id ) {
        if (meterArray.includes(loc.type) ) {
          if (loc._id != activelocnames.find(aloc => {return loc._id == aloc})) {
            nonactivelocnames.push({name: _.find(this.Locations, {_id: loc.parent}).name  + ' - ' + loc.name , index: index, active: 0})
          }
          else {
            nonactivelocnames.push({name: _.find(this.Locations, {_id: loc.parent}).name  + ' - ' + loc.name , index: index, active: 1})
          }
        }
      })
      
      nonactivelocnames = _.sortBy(nonactivelocnames, "name");
      return nonactivelocnames
    },
    providerNames () {
      let providernames = []
      this.Persons.forEach((provider, index) => {
        if (provider.is_provider) {
          providernames.push({name: provider.companyname + ' - ' + provider.lastname + ' ' + provider.firstname, index: index})
        }
      })
      providernames.sort()
      return providernames
    },
    customerNames () {
      let customernames = []
      this.Persons.forEach((customer, index) => {
        customernames.push({name: customer.companyname + ' - ' + customer.lastname + ' ' + customer.firstname, index: index})
      })
      customernames = _.sortBy(customernames, "name");
      return customernames
    },
    energyUnitNames () {
      let energyUnitsNames = []
      this.EnergyUnit.forEach((energyUnits, index) => {
        energyUnitsNames.push({name: energyUnits.name, index: index, _id: energyUnits._id})
      })
      energyUnitsNames.sort()
      return energyUnitsNames
    },
    meterNames () {
      let meterNames = []
      this.editedItem.metering_point.forEach((meter, index) => {
        let location = _.find(this.Locations, {_id: meter.location})
        let parentLocation = _.find(this.Locations, {_id: location.parent})
        let name = parentLocation.name + ' - ' + location.name
        meterNames.push({name: name, index: index, _id: location._id})
      })
      return meterNames
    },
    currency () {
      //return this.store.global.currency
      return "€"
    },
    comp_signing_date: {    
			get(){
				return this.$moment(this.editedItem.signing_date ).format("YYYY-MM-DD")
			},
			set(newVal){
				if (this.$moment(newVal)._isValid) this.editedItem.signing_date = this.$moment(newVal).format()
      }
    },
    comp_valid_from: {    
			get(){
				return this.$moment(this.editedItem.valid_from ).format("YYYY-MM-DD")
			},
			set(newVal){
				if (this.$moment(newVal)._isValid) this.editedItem.valid_from = this.$moment(newVal).format()
			}
    },
    comp_valid_to: {    
			get(){
				return this.$moment(this.editedItem.valid_to ).format("YYYY-MM-DD")
			},
			set(newVal){
        if (this.$moment(newVal)._isValid) this.editedItem.valid_to = this.$moment(newVal).format()
			}
    },
    sortGlobalBillingTypes () {
      return _.sortBy(this.Global.billingTypes, "value");
    }
    
  },

  watch: {
    dialog () {
      this.$validator.reset()
    },
    status(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        console.log("status success")
        this.contract = _.cloneDeep(this.Contract)
        let findunit = _.find(this.contract, {_id: this.editedItem._id})
        this.editedIndex = _.cloneDeep(this.contract.indexOf(findunit))
        if (this.editedIndex > -1) this.editedItem = this.contract[this.editedIndex]
        console.log(this.editedIndex)
        //this.editedItem = this.contract[this.editedIndex]
      }
    },
    statusCreate(val) {
      this.loading = val === "fetching";
      if (val === "success") {
       // this.initData();
      }
    },
    statusDelete (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
       // this.initData()
      }
    },
    statusUpdate (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
       // this.initData()
      }
    },
    statusDeactivate (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
        //this.initData()
      }
    },
    loading(val) {
      this.$store.state.loading = val;
    }
  }
}

</script>