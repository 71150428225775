<template>
  <v-container pa-0 ma-0  fluid>
    <v-card>
      <v-row >
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Refresh</div>
          <v-btn small icon color="accent" @click="getDeviceDebug()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">AutoRefresh</div>
          <v-switch
            height="0"
            v-model="autoRefresh"
            color="blue"
            @change="setAutoRefresh()"
            :label="autoRefresh.toString()"
          ></v-switch>
        </v-col>
        <!-- <v-col   cols="12" py-0 ma-1 left>
          <div class="body-2">Manual Mode</div>
          <v-switch
            height="0"
            v-model="device.debug.f_manual"
            color="red"
            hide-details
            @change="flowManual()"
            :label="device.debug.f_manual.toString()"
          ></v-switch>
        </v-col> -->
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">DeviceID:</div>
          <div class="body-1">{{ device.device_id }}</div>
        </v-col>
        <v-col   cols="12"  sm="2" py-2 ma-1 left>
          <div class="body-2">Alive:</div>
          <div class="body-1">{{device.alive | moment('DD.MM HH:mm:ss')}}</div>
        </v-col>
      </v-row>
    </v-card>

    <br />

    <v-row
      style="min-height: 50px"
    >
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Name</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.name}}
            </div>
            <div class="subheader pt-3"></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Aktuelle Temp</div>
            <div v-if="device.raw.d_actTemp" class="headline font-weight-bold pt-3">
              {{ (device.raw.d_actTemp).toFixed(1)}} °C
            </div>
            <div v-else class="headline font-weight-bold pt-3">
              {{ (device.raw.T).toFixed(1)}} °C
            </div>
            <div class="subheader pt-3"></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Luftfeuchtigkeit</div>
            <div v-if="device.raw.d_hum" class="headline font-weight-bold pt-3">
              {{ (device.raw.d_hum).toFixed(2) }} %
            </div>
            <div v-else class="headline font-weight-bold pt-3">
              {{ (device.raw.rH).toFixed(2) }} %
            </div>
            <div class="subheader pt-3"></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Spannung</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.raw.bat).toFixed(2) }} V
            </div>
            <div class="subheader pt-3"></div>
          </v-card-text>
        </v-card>
      </v-col>

     
    </v-row>
    <!-- <v-row v-if='DeviceIFrame'>
      <v-col  cols="12">
        <iframe
          :src="DeviceIFrame"
          height="600"
          :width="window.width - 60"
        ></iframe>
      </v-col>
    </v-row> -->
   
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "homeSensor",
  props: {
    device: {},
  },
  data() {
    return {
      autoRefresh: false,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }
  },
  async mounted() {
    //console.log("SENSOR: ",this.device);
    // await this.getDeviceIFrame(this.device);
  },
  destroyed() {
    this.IntervalOff();
  },
  computed: {
    ...mapState({
      mqttResponse: ({ device }) => device.response,
      DeviceIFrame: ({ device }) => device.iframe
    }),
  },
  methods: {
    ...mapActions("device", [
      //        "getPowerDevices",
      "getPowerDevicesOnly",
      "getDevice",
      "getDeviceDebugValues",
      "setDevice", 
      "getDeviceIFrame"
    ]),
    ...mapActions("event", ["getDeviceEvents"]),
    ...mapActions("global", ["getGlobal", "getGlobalAll"]),
    ...mapActions("location", ["getLocation"]),

    async getDeviceDebug() {
      // await this.setDevice({
      //   device: this.device.device_id,
      //   topic: "getDetails",
      //   message: 1,
      //   qos: 0,
      // });
      // if (this.mqttResponse.status == false) {
        this.$emit("refreshDevice");
        // await this.getDevice(this.device);
        // this.device = this.powerDevice;
      // } else {
      //   try {
      //     this.device.debug = JSON.parse(this.mqttResponse.status);
      //   } catch (e) {
      //     //
      //   }
      // }
    },
    async setAutoRefresh() {
      if (this.autoRefresh) {
        this.$store.myInterval = setInterval(async () => {
          this.getDeviceDebug();
        }, 5000);
      } else {
        this.IntervalOff();
      }
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.myInterval)) {
          clearInterval(this.$store.myInterval);
          this.$store.myInterval = undefined;
          this.autoRefresh = false;
        }
      } catch (e) {
        //
      }
    },
  
  },
  watch: {
    async device() {
      await this.getDeviceIFrame(this.device);
    },
  }
};
</script>

<style>
</style>