<template>
  <v-container pa-0 ma-0  fluid>
    <v-card>
      <v-row >
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Refresh</div>
          <v-btn small icon color="accent" @click="getDeviceDebug()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="2" py-0 ma-1 left>
          <div class="body-2">AutoRefresh</div>
          <v-switch
            height="0"
            v-model="autoRefresh"
            color="blue"
            @change="setAutoRefresh()"
            :label="autoRefresh.toString()"
          ></v-switch>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Manual Mode</div>
          <v-switch
            height="0"
            v-model="device.debug.f_manual"
            color="red"
            hide-details
            @change="flowManual()"
            :label="device.debug.f_manual.toString()"
          ></v-switch>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">DeviceID:</div>
          <div class="body-1">{{ device.device_id }}</div>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">Alive:</div>
          <div class="body-1">{{device.alive | moment('DD.MM HH:mm:ss')}}</div>
        </v-col>
      </v-row>
    </v-card>

    <br />

    <v-row
      style="min-height: 50px"
      v-if="typeof device.debug.h_energy1 != 'undefined'"
    >
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Wärme</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_energy1 / 1000).toFixed(2) }} kWh
            </div>
            <div class="subheader pt-3">meter reading</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Kälte</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_energy3 / 1000).toFixed(2) }} kWh
            </div>
            <div class="subheader pt-3">meter reading</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Volumen</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_volume1 / 1000).toFixed(2) }} m³
            </div>
            <div class="subheader pt-3">meter readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Vorlauf</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_temp1 / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Rücklauf</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_temp2 / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Temp Diff</div>
            <div class="headline font-weight-bold pt-3">
              {{ ((device.debug.h_temp1 - device.debug.h_temp2) / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Durchfluss</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_flow1 / 1000).toFixed(2) }} l/h
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Leistung</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_power1 / 1000).toFixed(2) }} kW
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="min-height: 50px">
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>SOLL</div>
            <div class="headline font-weight-bold pt-3">
              {{ ((device.debug.f_sal61y / 255) * 100).toFixed(2) }} %
            </div>
            <br />
            <div class="title" pt-2>IST</div>
            <div class="headline font-weight-bold pt-3">
              {{ ((device.debug.f_sal61u / 1000) * 1.06).toFixed(2) }} %
            </div>
            <div class="subheader pt-3">Klappenstellung</div>
            <br />
            <v-slider
              v-model="device.debug.f_sal61y"
              class="align-center"
              max="255"
              min="0"
              color="green"
              thumb-label="always"
              :thumb-size="28"
              @change="flowYChange()"
            ></v-slider>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Pumpe HZ</div>
            <v-switch
              class="align-center"
              v-model="device.debug.f_relais_1"
              color="green"
              hide-details
              @change="flowRelais1Change()"
              :label="device.debug.f_relais_1.toString()"
            ></v-switch>
            <br />
            <div v-if="typeof device.debug.f_relais_2 != 'undefined'">
              <div class="title" pt-2>Pumpe Zirkulation</div>
              <v-switch
                class="align-center"
                v-model="device.debug.f_relais_2"
                color="green"
                hide-details
                @change="flowRelais2Change()"
                :label="device.debug.f_relais_2.toString()"
              ></v-switch>
            </div>
            <br />
            <div class="title" pt-2>Stellantrieb ByPass</div>
            <v-switch
              class="align-center"
              v-model="device.debug.f_relais_3"
              color="green"
              hide-details
              @change="flowRelais3Change()"
              :label="device.debug.f_relais_3.toString()"
            ></v-switch>
            <br />
            <div class="title" pt-2>Stellantrieb Vorlauf</div>
            <v-switch
              class="align-center"
              v-model="device.debug.f_relais_4"
              color="green"
              hide-details
              @change="flowRelais4Change()"
              :label="device.debug.f_relais_4.toString()"
            ></v-switch>
            <div class="subheader pt-3">manual control</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Flow</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.f_vfs_flow / 1000).toFixed(2) }} l/min
            </div>
            <br />
            <div class="title" pt-2>LW Vorlauf</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.f_vfs_temp / 1000 }} °C
            </div>
            <div class="subheader pt-3">flow sensor</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>HZ Vorlauf (T3)</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.f_1W_temp4 / 1000 }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>HZ Rücklauf (T1)</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.f_1W_temp6 / 1000 }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  v-if="device.debug.f_1W_temp5">
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>WW Vorlauf (T2)</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.f_1W_temp5 / 1000 }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  v-if="device.debug.f_1W_temp7">
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Rücklauf Zirkulation 1</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.f_1W_temp7 / 1000 }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  v-if="device.debug.f_1W_temp9">
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Rücklauf Zirkulation 2</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.f_1W_temp9 / 1000 }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="3" cols="12"  v-if="device.debug.f_1W_temp1">
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Wärmespeicher</div>
            <div class="headline font-weight-bold pt-3">
              100%... {{ device.debug.f_1W_temp8 / 1000 }} °C
            </div>
            <div class="headline font-weight-bold pt-3">
              66%... {{ device.debug.f_1W_temp3 / 1000 }} °C
            </div>
            <div class="headline font-weight-bold pt-3">
              33%... {{ device.debug.f_1W_temp2 / 1000 }} °C
            </div>
            <div class="headline font-weight-bold pt-3">
              0%... {{ device.debug.f_1W_temp1 / 1000 }} °C
            </div>
            <div class="subheader pt-3">heat storage</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row >
      <v-col  cols="12" >
        <CrossFilterComponent :parentDevice="device"/>
      </v-col>
    </v-row>
    
    <v-row v-if='DeviceIFrame'>
      <v-col  cols="12">
        <iframe
          :src="DeviceIFrame"
          height="600"
          :width="window.width - 60"
        ></iframe>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_mqtt" lazy width="390px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Fehler</v-card-title>
        <v-card-text>{{dialog_mqtt_description}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_mqtt = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import CrossFilterComponent from "../components/CrossFilterComponent";

export default {
  name: "homeFlowControl",
  components: {
    CrossFilterComponent
  },
  props: {
    device: {},
  },
  data() {
    return {
      autoRefresh: false,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      dialog_mqtt: false,
      dialog_mqtt_description: "",
    }
  },
  async mounted() {
    //console.log(this.device);
    await this.getDeviceIFrame(this.device);
  },
  destroyed() {
    this.IntervalOff();
  },
  computed: {
    ...mapState({
      mqttResponse: ({ device }) => device.response,
      DeviceIFrame: ({ device }) => device.iframe
    }), 
  },
  methods: {
    ...mapActions("device", [
      //        "getPowerDevices",
      //"getPowerDevicesOnly",
      "getDevice",
      //"getDeviceDebugValues",
      "setDevice",
      "getDeviceIFrame"
    ]),
    ...mapActions("event", ["getDeviceEvents"]),
    ...mapActions("global", ["getGlobal", "getGlobalAll"]),
    ...mapActions("location", ["getLocation"]),


    async getDeviceDebug() {
      //await this.setDevice({
      //  device: this.device.device_id,
      //  topic: "getDetails",
      //  message: 1,
      //  qos: 0,
      //});
      //if (this.mqttResponse.status == false) {
        this.$emit("refreshDevice");
        //await this.getDevice(this.device);
        //this.device = this.powerDevice;
      //} else {
       // try {
      //    this.device.debug = JSON.parse(this.mqttResponse.status);
      //  } catch (e) {
      //    //
      //  }
      //}
    },
    async setAutoRefresh() {
      if (this.autoRefresh) {
        this.$store.myInterval = setInterval(async () => {
          this.getDeviceDebug();
        }, 2000);
      } else {
        this.IntervalOff();
      }
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.myInterval)) {
          clearInterval(this.$store.myInterval);
          this.$store.myInterval = undefined;
          this.autoRefresh = false;
        }
      } catch (e) {
        //
      }
    },
    async flowYChange() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setFlowSAL",
        message: Number(this.device.debug.f_sal61y),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.dialog_mqtt = true;
      }
    },
    async flowRelais1Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "1," + Number(this.device.debug.f_relais_1),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_1 = !this.device.debug.f_relais_1;
        this.dialog_mqtt = true;
      }
    },
    async flowRelais2Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "2," + Number(this.device.debug.f_relais_2),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_2 = !this.device.debug.f_relais_2;
        this.dialog_mqtt = true;
      }
    },
    async flowRelais3Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "3," + Number(this.device.debug.f_relais_3),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_3 = !this.device.debug.f_relais_3;
        this.dialog_mqtt = true;
      }
    },
    async flowRelais4Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "4," + Number(this.device.debug.f_relais_4),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_4 = !this.device.debug.f_relais_4;
        this.dialog_mqtt = true;
      }
    },
    async flowManual() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setFlowManual",
        message: Number(this.device.debug.f_manual),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },
    async awakeDevice() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "awake",
        message: 30,
        qos: 2,
      });
    },
  },
  watch: {
    async device() {
      await this.getDeviceIFrame(this.device);
    },
  }
};
</script>

<style>
</style>
