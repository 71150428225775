const downloadFile = async (blob, fileName, expectedType) => {
  if (expectedType !== blob.type) {
    throw new TypeError("Incorrect blob type");
  }

  let blobURL = window.URL.createObjectURL(blob);
  console.log("blobURL", blobURL);

  let tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", fileName);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  await setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 100);

  console.log("blob", blob, blobURL);
};

export { downloadFile };
