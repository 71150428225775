const REQUEST_CONTRACT = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_CONTRACT = (state, { body }) => {
  state.Contract = body;
  state.status = "success";
};

const FAILURE_REQUEST_CONTRACT = state => {
  state.status = "failure";
};


const REQUEST_DELETE_CONTRACT = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_CONTRACT = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_CONTRACT = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_CONTRACT = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_CONTRACT = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_CONTRACT = state => {
  state.statusUpdate = "failure";
};

const REQUEST_CONTRACT_HISTORY = state => {
  state.statusHistory = "fetching";
};

const SUCCESS_REQUEST_CONTRACT_HISTORY = (state, { body }) => {
  //console.log("SUCCESS_HISTORY")
  //console.log(body.history)
  state.History = body;
  state.statusHistory = "success";
};

const FAILURE_REQUEST_CONTRACT_HISTORY = state => {
  state.statusHistory = "failure";
};

const REQUEST_CREATE_CONTRACT = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_CONTRACT = state => {
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_CONTRACT = state => {
  state.statusCreate = "failure";
};

const REQUEST_DEACTIVATE_CONTRACT = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_CONTRACT = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_CONTRACT = state => {
  state.statusDeactivate = "failure";
};


export default {
  REQUEST_CONTRACT,
  SUCCESS_REQUEST_CONTRACT,
  FAILURE_REQUEST_CONTRACT,
  REQUEST_CREATE_CONTRACT,
  SUCCESS_REQUEST_CREATE_CONTRACT,
  FAILURE_REQUEST_CREATE_CONTRACT,
  REQUEST_CONTRACT_HISTORY,
  SUCCESS_REQUEST_CONTRACT_HISTORY,
  FAILURE_REQUEST_CONTRACT_HISTORY,
  REQUEST_DELETE_CONTRACT,
  SUCCESS_REQUEST_DELETE_CONTRACT,
  FAILURE_REQUEST_DELETE_CONTRACT,
  REQUEST_UPDATE_CONTRACT,
  SUCCESS_REQUEST_UPDATE_CONTRACT,
  FAILURE_REQUEST_UPDATE_CONTRACT,
  REQUEST_DEACTIVATE_CONTRACT,
  SUCCESS_REQUEST_DEACTIVATE_CONTRACT,
  FAILURE_REQUEST_DEACTIVATE_CONTRACT
};
