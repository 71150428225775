import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "./views/Login.vue";
import SignUpComponent from "./views/SignUp.vue";
import AccountActivation from "./views/AccountActivation.vue";
import ResetPasswordComponent from "./views/ResetPassword.vue";
import Home from "./views/Home.vue";
import Admin from "./views/Admin.vue";
import Person from "./views/Person.vue";
import Location from "./views/Location.vue";
import Contract from "./views/Contract.vue";
import EnergyUnit from "./views/EnergyUnit.vue";
import Invoice from "./views/Invoice.vue";
import Administration from "./views/Administration.vue";
import Device from "./views/Device.vue";
import Dsgvo from "./views/Dsgvo.vue";
import User from "./views/User.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    
    {
      path: "/",
      redirect: {
        name: "login"
      }
    },
    {
      path: "/login",
      name: "login",
      component: LoginComponent
    },
    {
      path: "/sign-up",
      name: "signUp",
      component: SignUpComponent
    },
    {
      path: "/activate-account/:token?",
      component: AccountActivation,
      name: "accountActivation"
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPasswordComponent
    },
    {
      path: "/confirm-password-reset/:token?",
      name: "resetPasswordConfirm",
      component: ResetPasswordComponent
    },
    {
      path: "/home",
      icon: "insert_chart",
      color: "red darken-2",
      name: "home",
      component: Home
    },
    {
      path: "/admin",
      name: "admin",
      icon: "settings",
      color: "grey darken-2",
      component: Admin
    },
    {
      path: "/person",
      name: "person",
      icon: "account_box",
      color: "deep-orange",
      component: Person
    },
    {
      path: "/loc",
      name: "location",
      icon: "business",
      color: "blue darken-2",
      component: Location
    },
    {
      path: "/contract",
      name: "contract",
      icon: "assignment_turned_in",
      color: "purple",
      component: Contract
    },
    {
      path: "/unit",
      name: "energyUnit",
      icon: "battery_charging_full",
      color: "green darken-2",
      component: EnergyUnit
    },
    {
      path: "/invoice",
      name: "invoice",
      icon: "description",
      color: "yellow lighten-2",
      component: Invoice
    },
    {
      path: "/administration",
      name: "administration",
      icon: "settings",
      color: "lime lighten-2",
      component: Administration
    },
    {
      path: "/device",
      name: "device",
      icon: "settings",
      color: "red lighten-2",
      component: Device
    },
    {
      path: "/dsgvo",
      name: "Dsgvo",
      component: Dsgvo,
    },
    {
      path: "/user",
      name: "user",
      icon: "settings",
      color: "pink lighten-2",
      component: User
    },

  ]
});
