import api from "../../../api";

export default {
  async getPowerDevices({ commit }, _type) {
    commit("REQUEST_POWER_DEVICES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_POWER_DEVICES",
        await api.getDevices({ type: _type.type })
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_POWER_DEVICES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getPowerDevicesOnly({ commit }, _type) {
    commit("REQUEST_POWER_DEVICESONLY");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_POWER_DEVICESONLY",
        await api.getDevicesOnly({ type: _type.type })
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        //this.$router.push({ name: "login" });
        commit("TO_LOGIN", true, { root: true });
        //console.log(this);
      }
      commit("FAILURE_REQUEST_POWER_DEVICESONLY");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getDevicesAll({ commit }) {
    commit("REQUEST_DEVICESALL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEVICESALL",
        await api.getDevicesAll()
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DEVICESALL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateDeviceLocation({ commit }, item) {
    commit("REQUEST_UPDATE_DEVICELOCATION");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_DEVICELOCATION",
        await api.updateDeviceLocation(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_DEVICELOCATION");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getDevice({ commit }, item) {
    //console.log("getDevice action")
    commit("REQUEST_POWER_DEVICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_POWER_DEVICE",
        await api.getDevice({id: item._id, from: item.from})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_POWER_DEVICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateDeviceConfig({ commit }, item) {
    //console.log(item)
    commit("REQUEST_UPDATE_DEVICECONFIG");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_DEVICECONFIG",
        await api.updateDeviceConfig(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_DEVICECONFIG");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getDeviceDebugValues({ commit }, item) {
    commit("REQUEST_POWER_DEVICE_DEBUG_VALUES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_POWER_DEVICE_DEBUG_VALUES",
        await api.getDeviceDebugValues({id: item._id})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_POWER_DEVICE_DEBUG_VALUES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async setDevice({ commit }, item) {
    //console.log(item)
    commit("REQUEST_UPDATE_SETDEVICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_SETDEVICE",
        await api.setDevice(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_SETDEVICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getDeviceIFrame({ commit }, item) {
    commit("REQUEST_IFRAME_DEVICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_IFRAME_DEVICE",
        await api.getDeviceIFrame({id: item._id})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_IFRAME_DEVICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
