<template>
  <v-container pa-0 ma-0  fluid>
    <v-card>
      <v-row >
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Refresh</div>
          <v-btn small icon color="accent" @click="getDeviceDebug()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">AutoRefresh</div>
          <v-switch
            height="0"
            v-model="autoRefresh"
            color="blue"
            @change="setAutoRefresh()"
            :label="autoRefresh.toString()"
          ></v-switch>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Manual Mode</div>
          <v-switch
            height="0"
            v-model="device.debug.f_manual"
            color="red"
            hide-details
            @change="flowManual()"
            :label="device.debug.f_manual.toString()"
          ></v-switch>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Actuators</div>
          <v-btn :disabled="!device.debug.f_manual" small color="red" @click="HCsetAct(0)">Close All
          </v-btn>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Actuators</div>
          <v-btn :disabled="!device.debug.f_manual"  small color="green" @click="HCsetAct(1)">Full Open
          </v-btn>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">DeviceID:</div>
          <div class="body-1">{{ device.device_id }}</div>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">Alive:</div>
          <div class="body-1">{{device.alive | moment('DD.MM HH:mm:ss')}}</div>
        </v-col>
      </v-row>
    </v-card>

    <br />

    <v-row
      style="min-height: 50px"
      v-if="typeof device.debug.h_energy1 != 'undefined'"
    >
     <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Seriennummer</div>
            <div class="headline font-weight-bold pt-3">
              {{ device.debug.h_sernr }}
            </div>
            <div class="subheader pt-3">S/N</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Wärme</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_energy1 / 1000).toFixed(2) }} kWh
            </div>
            <div class="subheader pt-3">meter reading</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Kälte</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_energy3 / 1000).toFixed(2) }} kWh
            </div>
            <div class="subheader pt-3">meter reading</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Volumen</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_volume1 / 1000).toFixed(2) }} m³
            </div>
            <div class="subheader pt-3">meter readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Vorlauf</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_temp1 / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Rücklauf</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_temp2 / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Temp Diff</div>
            <div class="headline font-weight-bold pt-3">
              {{ ((device.debug.h_temp1 - device.debug.h_temp2) / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Durchfluss</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_flow1 / 1000).toFixed(2) }} l/h
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Leistung</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device.debug.h_power1 / 1000).toFixed(2) }} kW
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br/>

    <v-data-table
        :headers="HaHeaders"
        :items="heatAreas"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [15, 50, 100, -1] }"
        :items-per-page="50"
      >
      <template v-slot:items="props" class="text-xs-right">
          
        <td>{{ props.item.temp_i }}</td>
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.foreign_name }}</td>
        <td>{{ (props.item.a_temp / 1000).toFixed(1) }}</td>
        <td>{{ (props.item.t_temp / 1000).toFixed(1) }}</td>
        <td>{{ props.item.sqm }} m2</td>
        <td>{{ props.item.htc }}</td>
        <td>{{ props.item.inertia }} min</td>
        <td>{{ props.item.flowmax / 1000}} l/h</td>
        <td>{{ props.item.actcount }}</td>
        <td>{{ props.item.actflow / 1000}} l/h</td>
        <td>{{ props.item.power }} W</td>
  
      </template>
    </v-data-table> 

    <v-row >
      <v-col   >
        <CrossFilterComponent :parentDevice="device"/>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_mqtt" lazy width="390px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Fehler</v-card-title>
        <v-card-text>{{dialog_mqtt_description}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_mqtt = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import CrossFilterComponent from "../components/CrossFilterComponent";

export default {
  name: "homeHeatControl",
  components: {
    CrossFilterComponent
  },
  props: {
    device: {},
  },
  data() {
    return {
      autoRefresh: false,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      HaHeaders: [
        { text: 'Temp ID', value: 'temp_i' },
        { text: 'Raum', value: 'name' },
        { text: 'Sensor', value: 'foreign_name' },
        { text: 'Akt. Temp', value: 'a_temp' },
        { text: 'Soll Temp', value: 't_temp' },
        { text: 'sqm', value: 'sqm' },
        { text: 'htc', value: 'htc' },
        { text: 'inertia', value: 'inertia'},
        { text: 'flowmax', value: 'flowmax' },
        { text: 'actcount', value: 'actcount' },
        { text: 'actflow', value: 'actflow' },
        { text: 'power', value: 'power' }
      ],
      heatAreas: [],
      device_debug: {},
      dialog_mqtt: false,
      dialog_mqtt_description: "",
    }
  },
  async mounted() {
    //console.log(this.device);
    //this.getHa();
    await this.getPowerDevicesOnly({type: "sensor"});
    await this.getLocation({}); 
    this.getDeviceDebug();
    //this.getHa();
  },
  destroyed() {
    this.IntervalOff();
  },
  computed: {
    ...mapState({
      mqttResponse: ({ device }) => device.response,
      powerDevicesOnly: ({ device }) => device.powerDevicesOnly,
    }),
  
  },
  methods: {
    ...mapActions("device", [
      //        "getPowerDevices",
      "getPowerDevicesOnly",
      "getDevice",
      "getDeviceDebugValues",
      "setDevice",
    ]),
    ...mapActions("event", ["getDeviceEvents"]),
    ...mapActions("global", ["getGlobal", "getGlobalAll"]),
    ...mapActions("location", ["getLocation"]),

    getHa () {
    
      let result = new Array();
      for (let i = 0; i < this.device_debug.debug.ha_count; i++) {
        result[i] = new Object();
      }
      let debug = this.device_debug.debug;
      Object.keys(debug).map((key) => {       
        if (key.substring(0,5) == "hc_ha") {
          for (let i = 0; i < this.device_debug.debug.ha_count; i++) {
            //console.log(key, key.substring(key.length-1,key.length))
            if (key.substring(key.length-1,key.length) == i) {
              const key2 = key.substring(6,key.length - 2);
              result[i][key2] = debug[key];
            }
          }
        }
        //console.log("key Sub ", key.substring(0,6))

      });
      debug = this.device_debug.debug2;
      Object.keys(debug).map((key) => {  
        
        if (key.substring(0,5) == "hc_ha") {
          for (let i = 0; i < this.device_debug.debug.ha_count; i++) {
            //console.log(key, key.substring(key.length-1,key.length))
            if (key.substring(key.length-1,key.length) == i) {
              const key2 = key.substring(6,key.length - 2);
              result[i][key2] = debug[key];
            }
          }
        }
        if (key.substring(0,4) == "for_") {
          for (let i = 0; i < this.device.debug.for_devs; i++) {  
            if (key.substring(key.length-1,key.length) == i) {
              //const key2 = key.substring(6,key.length - 2);
              //result[i][key2] = debug[key];
              //console.log("Data: ", debug[key])

              //await this.getDevice(debug[key]);
              //console.log("getdevice ", this.powerDevice)
              const _device = _.find(this.powerDevicesOnly, {device_id: debug[key]});
              if (_device) {
                result[i]["foreign_name"] = _device.name;
              }
            }
          }
        }     
      });
      
      this.heatAreas = result;
      //console.table(result.sort((a,b) => a[0] - b[0]))
      //return result;
    },
    
   
    async getDeviceDebug() {

      //ToDo: Change to Server Read
      //this.$emit("refreshDevice");
      //console.log("getDeviceDebug");
      this.device_debug.debug = {};
      this.device_debug.debug2 = {};

      await this.setDevice({
        device: this.device.device_id,
        topic: "getDetails",
        message: 1,
        qos: 0,
      });
      //console.log("getDetails returned ", this.mqttResponse.status)
      if (this.mqttResponse.status == false) {  //REPEAT
        await this.setDevice({
          device: this.device.device_id,
          topic: "getDetails",
          message: 1,
          qos: 0,
        });
      }
        //await this.getDevice(this.device);
        //this.device = this.powerDevice;
      //  this.device_debug.debug = {};
      //} else {
      if (this.mqttResponse.status != false) {
        try {
          this.device_debug.debug = JSON.parse(this.mqttResponse.status);
          this.device.debug = JSON.parse(this.mqttResponse.status);
          this.device.alive = moment().toDate();
        } catch (e) {
          //
        }
      }
      
      await this.setDevice({
        device: this.device.device_id,
        topic: "getDetails2",
        message: 1,
        qos: 0,
      });
      //console.log("getDetails2 returned ", this.mqttResponse.status)
      if (this.mqttResponse.status == false) {
        // Repeat
        await this.setDevice({
          device: this.device.device_id,
          topic: "getDetails2",
          message: 1,
          qos: 0,
        });
        //await this.getDevice(this.device);
        //this.device = this.powerDevice;
        //this.device_debug.debug2 = {};
      } 
      if (this.mqttResponse.status != false) {
        try {
          this.device_debug.debug2 = JSON.parse(this.mqttResponse.status);
        } catch (e) {
          //
        }
      }
      
      this.getHa();
    },
    async setAutoRefresh() {
      if (this.autoRefresh) {
        this.$store.myInterval = setInterval(async () => {
          await this.getDeviceDebug();
        }, 5000);
      } else {
        this.IntervalOff();
      }
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.myInterval)) {
          clearInterval(this.$store.myInterval);
          this.$store.myInterval = undefined;
          this.autoRefresh = false;
        }
      } catch (e) {
        //
      }
    },
    async flowManual() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setFlowManual",
        message: Number(this.device.debug.f_manual),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },
    async awakeDevice() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "awake",
        message: 30,
        qos: 2,
      });
    },
    async HCsetAct(val) {
      let value = "1,0,0";
      if (val == 1) {
        value = "1,0,250";
        if (this.device.raw.DType >= 1200 && this.device.raw.DType < 1210) {
          value = "1,0,500";
        }
      } 
      await this.setDevice({
        device: this.device.device_id,
        topic: "HCsetAct",
        message: value,
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.dialog_mqtt = true;
      }
    },

  },

  watch: {
    async device() {
      this.heatAreas = [];
      this.getDeviceDebug();
      //this.getHa();
    }
  }
};
</script>

<style>
</style>

