import api from "../../../api";

export default {
  async getEnergyUnit({ commit }) {
    commit("REQUEST_ENERGYUNIT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_ENERGYUNIT",
        await api.getEnergyUnit()
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_ENERGYUNIT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getEnergyUnitHistory({ commit }, id) {
    commit("REQUEST_ENERGYUNIT_HISTORY");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_ENERGYUNIT_HISTORY",
        await api.getEnergyUnitHistory(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_ENERGYUNIT_HISTORY");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteEnergyUnit({ commit }, id) {
    commit("REQUEST_DELETE_ENERGYUNIT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_ENERGYUNIT",
        await api.deleteEnergyUnit(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DELETE_ENERGYUNIT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateEnergyUnit({ commit }, item ) {
    commit("REQUEST_UPDATE_ENERGYUNIT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_ENERGYUNIT",
        await api.updateEnergyUnit(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_ENERGYUNIT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createEnergyUnit({ commit }, item ) {
    commit("REQUEST_CREATE_ENERGYUNIT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_ENERGYUNIT",
        await api.createEnergyUnit( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_ENERGYUNIT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deactivateEnergyUnit({ commit }, id) {
    commit("REQUEST_DEACTIVATE_ENERGYUNIT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEACTIVATE_ENERGYUNIT",
        await api.deactivateEnergyUnit(id)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DEACTIVATE_ENERGYUNIT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
