<template>
  <v-container fluid >
    <v-row class="align-center justify-center" >
      <v-col  md="6" cols="12">
        <v-text-field label="Filename" v-model="filename"></v-text-field>
        <v-text-field label="Hash MD5" v-model="md5"></v-text-field>
        <v-btn block @click="exportXls">Export Debug to XLS</v-btn>
      </v-col>
      <v-col  md="10" cols="12" ma-0 pa-0>
        <v-card v-for="device in devices" :key="device.id">
          <div v-if="device.debug">
            <v-card-title class="title green--text" v-if="device.debug.Awake > 0">{{device.name}}</v-card-title>
            <v-card-title class="title blue--text" v-else>{{device.name}}</v-card-title>
          </div>
          <v-card-title class="title" v-else>{{device.name}}</v-card-title>
          <v-card-text>
            <v-row >
              <v-col  md="2" cols="12">
                ID: {{device.device_id}}
                <br />
                <span v-if="device.status">
                  Packet: {{device.status.time | moment('DD.MM HH:mm')}}
                  <br />
                  <span
                    v-if="device.status.status < 3"
                    class="green--text"
                  >Status: {{device.status.status}}</span>
                  <span
                    v-else-if="device.status.status < 5"
                    class="orange--text"
                  >Status: {{device.status.status}}</span>
                  <span v-else class="red--text">Status: {{device.status.status}}</span>
                  <br />
                </span>
                Alive: {{device.alive | moment('DD.MM HH:mm')}}
              </v-col>
              <v-col  v-if="device.debug" md="8" cols="12" class="caption">
                <div class="red--text" v-if="$moment().diff($moment(device.debug.time, 'X'),'minutes') > 15">
                  {{device.debug}}
                  -{{ $moment().diff($moment(device.debug.time, 'X'),'minutes')}} min
                </div>
                <div v-else>
                  {{device.debug}}
                </div>
              </v-col>
              <v-col  v-else md="8"  class="caption">{{device.status}}</v-col>
            </v-row>
            <v-row >
              <v-col  md="12"  >
                <v-divider></v-divider>Config:
              </v-col>
            </v-row>
            <v-row >
              <v-col  xl="1" lg="1" md="3" cols="12"  v-if="device.values">
                <v-text-field label="NT" :value="device.values.night" v-model="device.values.night"></v-text-field>
                <v-btn dark block small color="green" @click="setNT(device)">set NT</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  v-if="device.values">
                <v-text-field label="HT" :value="device.values.day" v-model="device.values.day"></v-text-field>
                <v-btn dark block small color="green" @click="setHT(device)">set HT</v-btn>
              </v-col>

              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="Heartbeat" v-model="device.heartbeat"></v-text-field>
                <v-btn dark block small color="green" @click="setHeartbeat(device)">HeartBeat</v-btn>
              </v-col>

              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="Bright" v-model="device.brightness"></v-text-field>
                <v-btn dark block small color="green" @click="setBrightness(device)">Bright</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="setMQTTIntervall" v-model="device.mint"></v-text-field>
                <v-btn dark block small color="green" @click="setMqttInt(device)">setMQTTInt</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="setPacketIntervall" v-model="device.pint"></v-text-field>
                <v-btn dark block small color="green" @click="setPacketInt(device)">setPacketInt</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="ukWh" v-model="device.ukwh"></v-text-field>
                <v-btn dark block small color="green" @click="setUkwh(device)">set ukWh</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="WebSrvOn" v-model="device.WebSrvOn"></v-text-field>
                <v-btn dark block small color="green" @click="setWebSrvOn(device)">set WebSrvOn</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="DeviceType" v-model="device.dtype"></v-text-field>
                <v-btn dark block small color="green" @click="setDType(device)">set DeviceType</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="SW1min" v-model="device.SW1min"></v-text-field>
                <v-text-field label="SW1" v-model="device.SW1"></v-text-field>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-text-field label="SW2min" v-model="device.SW2min"></v-text-field>
                <v-text-field label="SW2" v-model="device.SW2"></v-text-field>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-btn dark block small color="green" @click="updateConfig(device)">Save Config</v-btn>
                <v-btn dark block small color="green" @click="setConfig(device)">Load Config</v-btn>
                <v-btn dark block small color="green" @click="setSW(device)">setSW</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-btn block dark small color="blue" @click="awake(device)">Wake</v-btn>
                <v-btn
                  dark
                  block
                  small
                  color="orange"
                  @click="pub(device,'reboot','100',0)"
                >set Reboot</v-btn>
                <v-btn dark block small color="orange" @click="pub(device,'reboot','99',0)">Reboot</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-btn dark block small color="red" @click="update(device)">Flash</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-btn dark block small color="green" @click="pub(device,'setZero','1',0)">Zero</v-btn>
                <v-btn block dark small color="green" @click="pub(device,'kalman','1',0)">Kal ON</v-btn>
                <v-btn dark block small color="green" @click="pub(device,'kalman','0',0)">Kal OFF</v-btn>
              </v-col>
              <v-col  xl="1" lg="1" md="3" cols="12"  >
                <v-btn block dark small color="green" @click="pub(device,'setDebug','1',0)">Debug On</v-btn>
                <v-btn
                  dark
                  block
                  small
                  color="green"
                  @click="pub(device,'setDebug','0',0)"
                >Debug Off</v-btn>
                <v-btn dark block small color="green" @click="pub(device,'getDebug','1',0)">getDebug</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_mqtt" lazy width="390px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>Fehler</v-card-title>
        <v-card-text>{{dialog_mqtt_description}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_mqtt = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
//import api from "../api";
const ExcelJS = require("exceljs");
import { saveAs } from "file-saver";

export default {
  name: "admin",
  data() {
    return {
      device: {},
      devices: [],
      filename: "",
      md5: "",
      dialog_mqtt: false,
      dialog_mqtt_description: "",
 
      /*Brightness: 0,
      SW1: 0,
      SW1min: 0,
      SW2: 0,
      SW2min: 0,
      heartbeat: 0,
      pint: 0,
      mint: 0*/
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions("device", [
      "getPowerDevicesOnly",
      "getDevicesAll",
      "updateDeviceConfig",
      "setDevice"
    ]),
    initData() {
      this.getPowerDevicesOnly({});
      this.getDevicesAll({});
    },
    async setHT(_device) {
      //await this.setHT({ device: _device.device_id, HT: Number(_device.values.day)})
      await this.setDevice({
        device: _device.device_id,
        topic: "setHT",
        message: Number(_device.values.day),
        qos: 0
      });
    },
    async setNT(_device) {
      //await this.setNT({ device: _device.device_id, NT: Number(_device.values.night)})
      await this.setDevice({
        device: _device.device_id,
        topic: "setNT",
        message: Number(_device.values.night),
        qos: 0
      });
    },
    async awake(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "awake",
        message: 30,
        qos: 2
      });
    },
    async update(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "ota",
        message:
          "url:http://mqtt2.makerspace.at:8081/fw-V5AHQnsTVORmC3QX8602/" + this.filename + "," + this.md5,
        qos: 0
      });
    },
    async setHeartbeat(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setHeartBeat",
        message: _device.heartbeat,
        qos: 0
      });
    },
    async setBrightness(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setBrightness",
        message: _device.brightness,
        qos: 0
      });
    },
    async setMqttInt(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setMQTTInt",
        message: _device.mint,
        qos: 0
      });
    },
    async setPacketInt(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setPacketInt",
        message: _device.pint,
        qos: 0
      });
    },
    async setUkwh(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setUkWh",
        message: _device.ukwh,
        qos: 0
      });
    },
    async setWebSrvOn(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setWebServerOnTime",
        message: _device.WebSrvOn,
        qos: 0
      });
    },
    async setDType(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setDevType",
        message: _device.dtype,
        qos: 0
      });
    },
    async pub(_device, topic, message, qos) {
      await this.setDevice({
        device: _device.device_id,
        topic: topic,
        message: message,
        qos: qos
      });
    },
    async allAwake() {
      //await this.allAwake()
      await this.setDevice({
        device: null,
        topic: "awake",
        message: 30,
        qos: 2
      });
    },
    async updateConfig(_device) {
      if (confirm("Do you want to save current configuration?")) {
        console.log(_device.debug);
        await this.updateDeviceConfig({
          id: _device._id,
          config: _device.debug
        });
      }
    },
    async allResetLocation() {
      await this.allResetLocation();
    },
    async allUpdate() {
      await this.setDevice({
        device: null,
        topic: "ota",
        message:
          "url:http://mqtt2.makerspace.at:8081/fw-V5AHQnsTVORmC3QX8602/" + this.filename + "," + this.md5,
        qos: 0
      });
    },
    async setSW(_device) {
      await this.setDevice({
        device: _device.device_id,
        topic: "setSW1min",
        message: _device.SW1min,
        qos: 0
      });
      await this.setDevice({
        device: _device.device_id,
        topic: "setSW1",
        message: _device.SW1,
        qos: 0
      });
      await this.setDevice({
        device: _device.device_id,
        topic: "setSW2min",
        message: _device.SW2min,
        qos: 0
      });
      await this.setDevice({
        device: _device.device_id,
        topic: "setSW2",
        message: _device.SW2,
        qos: 0
      });
    },
    getSW(_device) {
      _device.SW1min = _device.debug.SW1m;
      _device.SW1 = _device.debug.SW1;
      _device.SW2min = _device.debug.SW2m;
      _device.SW2 = _device.debug.SW2;
      this.$forceUpdate();
    },
    async setConfig(_device) {
      await this.getPowerDevicesOnly({});
      _device.SW1min = _device.config.SW1m;
      _device.SW1 = _device.config.SW1;
      _device.SW2min = _device.config.SW2m;
      _device.SW2 = _device.config.SW2;
      _device.brightness = _device.config.Bright;
      _device.ukwh = _device.config.ukwh;
      _device.dtype = _device.config.DType;
      _device.WebSrvOn = _device.config.WebSrvOn;
      _device.heartbeat = _device.config.heart;
      _device.mint = _device.config.mint;
      _device.pint = _device.config.pint;
      this.$forceUpdate();
    },

    async exportXls() {
      const listDevices = [  'A8CAD4D108F0',  '68B4F0BF713C',  '805BF33A7D80',  '9CD3AABF713C',  '24F9F0BF713C',  'FC62D4D108F0',  '98ABEBA4AE30',  'B464D4D108F0',  'D8CB16286F24',  'AC25D4D108F0',  '9CD8AABF713C',  '24D3D4D108F0',  '6000ABBF713C',  '30F9AABF713C',  '089D9AC40A24',  '14A9F33A7D80',  '4C9F0ABF713C',  '387CA2C40A24',  'CCABEBA4AE30',  '90479CC40A24',  'EC13F3A4AE30',  '7C9B9AC40A24',  '8065A3C40A24',  'D8FEAABF713C',  'D8ADF33A7D80',  'F8EEAABF713C',  '30E5AABF713C',  '28EEAABF713C',  '981E9EC40A24',  '10B184A4AE30',  'B8469DBF713C',  'C8AD42A4AE30',  '9010D5AB6224'];
      let debugList = [];
      for (let deviceId of listDevices) {
        const device = this.devices.find(obj => { return obj.device_id == deviceId});
        if (device) {
          debugList.push({"device_id": deviceId, "debug": device.debug});
        }
      }
      let fileName = "EM_debug.xlsx";

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet", {
        properties: { tabColor: { argb: "FFC0000" } },
      });
      worksheet.columns = [
        { header: 'DeviceId', key: 'device_id', width: 20 },
        { header: 'Debug', key: 'debug', width: 100 },
      ];
      worksheet.addRows(debugList);
      
      const buffer = await workbook.xlsx.writeBuffer();
      var blob = new Blob([buffer], {
        type: "application/msexcel",
      });

      saveAs(blob, fileName);
    }
  },
  computed: {
    ...mapState({
      powerDevicesOnly: ({ device }) => device.powerDevicesOnly,
      devicesAll: ({ device }) => device.devicesAll,
      mqttResponse: ({ device }) => device.response
    })
  },
  watch: {
    devicesAll() {
      //this.devices = _.filter(this.devicesAll, function(o) { return o.location }) //.filter(device => { return device.values})
      this.devices = this.devicesAll.filter(device => {
        return device.location && device.location != null;
      });
    },
    mqttResponse(res) {
      if (res.status == false) {
        this.dialog_mqtt_description = "Das Gerät hat auf Ihren Befehl nicht reagiert. Versuchen Sie es erneut oder führen Sie vorher einen Awake aus und warten etwas (5sec - 2min)."
        this.dialog_mqtt = true;
      }
      else if (res.status == 0) {
        this.dialog_mqtt_description = "Das Gerät hat Ihren Befehl zurückgewiesen."
        this.dialog_mqtt = true;
      }
    }

  }
};
</script>