import Vue from "vue";
import VueResource from "vue-resource";
import { API_ROOT } from "../config";

Vue.use(VueResource);

// Vue.http.options.crossOrigin = true
// Vue.http.options.credentials = true

/*if (process.env.NODE_ENV === "production") {
  throw new Error("Missing API root");
  // Vue.http.options.root = ...
}*/

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  request.headers.set("Authorization", localStorage.getItem("Authorization"));
  next(response => {
    if (response.status === 401) {
      if (!window.location.pathname === "/login")
        window.location.pathname = "/login";
    }
  });
});

export const AuthResource = Vue.resource(API_ROOT + "auth");
export const SignUpResource = Vue.resource(API_ROOT + "signup");
export const ActivateAccountResource = Vue.resource(
  API_ROOT + "activate/{token}"
);
export const MySelfResource = Vue.resource(API_ROOT + "myself");
export const ForgotPasswordResource = Vue.resource(
  API_ROOT + "forgot_password"
);
export const ResetPasswordResource = Vue.resource(
  API_ROOT + "reset_password"
);
export const DeviceResource = Vue.resource(API_ROOT + "device");
export const DeviceOnlyResource = Vue.resource(API_ROOT + "device/only");
export const DeviceAllResource = Vue.resource(API_ROOT + "device/all");
export const DeviceLocationUpdateResource = Vue.resource(API_ROOT + "device/location/{id}");
export const DeviceConfigUpdateResource = Vue.resource(API_ROOT + "device/config/{id}");
export const DeviceGetResource = Vue.resource(API_ROOT + "device/{id}");
export const DeviceGetDebugValuesResource = Vue.resource(API_ROOT + "device/debug_values/{id}");
export const DeviceGetIFrameResource = Vue.resource(API_ROOT + "device/iframe/{id}");

export const DeviceEventResource = Vue.resource(
  API_ROOT + "device{/deviceId}/event"
);
export const LocationEventIndexResource = Vue.resource(
  API_ROOT + "event{/deviceId}/index"
);
//export const MQTTSetHTResource = Vue.resource(API_ROOT + "mqtt/setht");
//export const MQTTSetNTResource = Vue.resource(API_ROOT + "mqtt/setnt");
//export const MQTTallAwakeResource = Vue.resource(API_ROOT + "mqtt/allawake");
export const MQTTsetDeviceResource = Vue.resource(API_ROOT + "mqtt/setdevice");

export const CreatePersonResource = Vue.resource(API_ROOT + "person");
export const PersonResource = Vue.resource(API_ROOT + "person");
export const PersonDeleteResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonDeactivateResource = Vue.resource(API_ROOT + "person/deactivate/{id}");
export const PersonUpdateResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonHistoryResource = Vue.resource(API_ROOT + "person/{id}");

export const LocationResource = Vue.resource(API_ROOT + "location");
export const LocationCreateResource = Vue.resource(API_ROOT + "location");
export const LocationUpdateResource = Vue.resource(API_ROOT + "location/{id}");
export const LocationDeleteResource = Vue.resource(API_ROOT + "location/{id}");

export const UserReadResource = Vue.resource(API_ROOT + "user/{id}");
export const UserResource = Vue.resource(API_ROOT + "user");
export const UserCreateResource = Vue.resource(API_ROOT + "user");
export const UserUpdateResource = Vue.resource(API_ROOT + "user/{id}");

export const CreateEnergyUnitResource = Vue.resource(API_ROOT + "energyunit");
export const EnergyUnitResource = Vue.resource(API_ROOT + "energyunit");
export const EnergyUnitDeleteResource = Vue.resource(API_ROOT + "energyunit/{id}");
export const EnergyUnitDeactivateResource = Vue.resource(API_ROOT + "energyunit/deactivate/{id}");
export const EnergyUnitUpdateResource = Vue.resource(API_ROOT + "energyunit/{id}");
export const EnergyUnitHistoryResource = Vue.resource(API_ROOT + "energyunit/{id}");

export const GlobalResource = Vue.resource(API_ROOT + "global");
export const GlobalAllResource = Vue.resource(API_ROOT + "global/all");
export const GlobalCreateResource = Vue.resource(API_ROOT + "global");
export const GlobalUpdateResource = Vue.resource(API_ROOT + "global/{id}");

export const PriceIndexResource = Vue.resource(API_ROOT + "price_index");
export const CreatePriceIndexResource = Vue.resource(API_ROOT + "price_index");
export const PriceIndexUpdateResource = Vue.resource(API_ROOT + "price_index/{id}");

export const CreateContractResource = Vue.resource(API_ROOT + "contract");
export const ContractResource = Vue.resource(API_ROOT + "contract");
export const ContractDeleteResource = Vue.resource(API_ROOT + "contract/{id}");
export const ContractDeactivateResource = Vue.resource(API_ROOT + "contract/deactivate/{id}");
export const ContractUpdateResource = Vue.resource(API_ROOT + "contract/{id}");
export const ContractHistoryResource = Vue.resource(API_ROOT + "contract/{id}");

export const EventallResetLocationResource = Vue.resource(API_ROOT + "event/reset/location");

export const InvoiceResource = Vue.resource(API_ROOT + "invoice/contract/{id}");
export const InvoiceDeleteResource = Vue.resource(API_ROOT + "invoice/rollback/{id}");
export const InvoiceProviderResource = Vue.resource(API_ROOT + "invoice/provider/{id}");
export const InvoiceCreateProviderResource = Vue.resource(API_ROOT + "invoice/create/provider/{id}");
export const InvoiceSendResource = Vue.resource(API_ROOT + "invoice/send/{id}");
export const InvoiceSendProviderAllResource = Vue.resource(API_ROOT + "invoice/send/provider/{id}");

export const DeviceSettingResource = Vue.resource(API_ROOT + "device_setting");
export const DeviceSettingCreateResource = Vue.resource(API_ROOT + "device_setting");
export const DeviceSettingUpdateResource = Vue.resource(API_ROOT + "device_setting/{id}");

export const DeviceCommandResource = Vue.resource(API_ROOT + "device_command");
export const DeviceCommandCreateResource = Vue.resource(API_ROOT + "device_command");
export const DeviceCommandUpdateResource = Vue.resource(API_ROOT + "device_command/{id}");

export const LocationRawEventIndexResource = Vue.resource(
  API_ROOT + "raw_event{/deviceId}/index"
);