<template>
  <v-container fill-height>
    <v-row class="align-center justify-center" >
      <v-col  style="max-width:500px">
        <v-card textcolor="transparent" flat>
          <v-card-text>
            <v-card>
              <v-card-title class="title">Login</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'" >E-Mail or password incorrect.</v-alert>
              </v-card-text>
              <v-card-text>
                <v-form ref="form" v-model="valid" style="width:100%;height:100%;">
                  <v-text-field :rules="emailRule" required id="email" v-model="email" label="E-Mail"></v-text-field>
                  <v-text-field :append-icon="show ? 'visibility_off' : 'visibility'" :type="show ? 'text' : 'password'" @click:append="show = !show" @keydown.enter="startAuth({email, password})" id="password" required
                                v-model="password" label="Password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded :disabled="!valid" outlined color="primary" :loading="loading"
                       @click="startAuth({email, password})">Login
                </v-btn>
              </v-card-actions>
            </v-card> 
          </v-card-text>  
          <p align="center">
            <router-link :to="{name: 'resetPassword'}">{{$t("Forgot password?")}}</router-link><br/>
            <br/><router-link :to="{name: 'signUp'}">{{$t("You don't have an account yet and you want to sign up?")}}</router-link><br/>
            <br/><router-link :to="{name: 'Dsgvo'}">DSGVO</router-link>
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { emailRule, passwordRule } from "../utils/rules";

export default {
  name: "Login",
  data() {
    return {
      password: "",
      email: "",
      valid: false,
      emailValid: false,
      emailRule,
      passwordRule,
      show: false
    };
  },
  methods: {
    ...mapActions("auth", ["authenticate", "getMySelf"]),

    async startAuth(authData) {
      this.$refs.form.validate();
      console.log(this.valid);
      if (this.valid) {
        await this.authenticate(authData);
      }
    }
  },
  computed: {
    ...mapState({
      status: ({ auth }) => auth.status,
      loading: state => state.loading,
      tokenExpiresAt: ({ auth }) => auth.tokenExpiresAt,
    })
  },
  watch: {
    status(val) {
      if (val === "success") {
        this.$store.authInterval = setInterval(async () => {
          //console.log("Check Interval ", Number(this.$moment().format('X')), this.tokenExpiresAt);
          if (Number(this.$moment().format('X')) >= this.tokenExpiresAt) {
            this.getMySelf();
          }
        }, 2 * 1000);
        this.$router.push({ name: "home" });
      }
    }
  }
};
</script>
