const REQUEST_DEVICE_EVENTS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_DEVICE_EVENTS = (state, { body }) => {
  state.status = "success";
  state.events = body;
};

const FAILURE_REQUEST_DEVICE_EVENTS = state => {
  state.events = [];
  state.status = "failure";
};

const REQUEST_LOCATION_EVENTS_INDEX = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_LOCATION_EVENTS_INDEX = (state, { body }) => {
  state.status = "success";
  //console.log("RETURN: ", body)
  state.events = body;
};

const FAILURE_REQUEST_LOCATION_EVENTS_INDEX = state => {
  state.events = [];
  state.status = "failure";
};

export default {
  REQUEST_DEVICE_EVENTS,
  SUCCESS_REQUEST_DEVICE_EVENTS,
  FAILURE_REQUEST_DEVICE_EVENTS,
  REQUEST_LOCATION_EVENTS_INDEX,
  SUCCESS_REQUEST_LOCATION_EVENTS_INDEX,
  FAILURE_REQUEST_LOCATION_EVENTS_INDEX,
};
