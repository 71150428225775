import { timeInterval } from "d3-time";

const durationHour = 36e5;
const durationMinute = 6e4;

const time6hour = timeInterval(
  function(date) {
    let offset =
      (date.getTimezoneOffset() * durationMinute) % (durationHour * 6);
    if (offset < 0) offset += durationHour;
    date.setTime(
      Math.floor((+date - offset) / (durationHour * 6)) * (durationHour * 6) +
        offset
    );
  },
  function(date, step) {
    date.setTime(+date + step * 6 * durationHour);
  },
  function(start, end) {
    return (end - start) / (durationHour * 6);
  },
  function(date) {
    return date.getHours();
  }
);

const time15minutes = timeInterval(
  function(date) {
    date.setTime(
      Math.floor(date / (15 * durationMinute)) * (15 * durationMinute)
    );
  },
  function(date, step) {
    date.setTime(+date + step * (15 * durationMinute));
  },
  function(start, end) {
    return ((end - start) / durationMinute) % (15 * durationMinute);
  },
  function(date) {
    return date.getMinutes();
  }
);

export { time6hour, time15minutes };
