const emailRule = [
  v => {
    return !!v || "E-Mail is required";
  },
  v =>
    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,128})+$/.test(v) 
    /^[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+(\.[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]+)*@([A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z]{2,}$/.test(v)
    ||
    "E-Mail must be valid"
];


const requiredRule = [v => !!v || "required"];

const passwordRule = [
  v => {
    return !!v || "Password is required";
  },
  v =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/.test(v) ||
    "Min. 10 characters with at least one capital letter, a number and a special character."
];

const keyRule = [
  //v => this.checkKeyLen(v) || "Schlüssellänge nicht korrekt."
  v => !!v || "Bitte Schlüssel eingeben",
  v => /^[a-fA-F0-9]*$/.test(v) || "Darf nur Zahlen 0-9 und Buchstaben A-F enthalten",
  v => /^.{32}$/.test(v) || "Schlüssellänge nicht korrekt.",
];

export { passwordRule, emailRule, requiredRule, keyRule };
