import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/user";
import device from "./modules/device";
import event from "./modules/event";
import person from "./modules/person";
import location from "./modules/location";
import energyunit from "./modules/energyunit";
import global from "./modules/global";
import contract from "./modules/contract";
import invoice from "./modules/invoice";
import price_index from "./modules/price_index";
import device_setting from "./modules/device_setting";
import device_command from "./modules/device_command";
import raw_event from "./modules/raw_event";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    drawer: true,
    myInterval: undefined,
    authInterval: undefined
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_DRAWER(state, _drawer) {
      state.drawer = _drawer;
    },
    TO_LOGIN(state, value) {
      // console.log("TO LOGIN TRIGGERT")
      localStorage.removeItem("Authorization");
      state.auth.mySelf = null;
    },
  },
  modules: {
    auth,
    user,
    device,
    event,
    person,
    location,
    energyunit,
    global,
    contract,
    invoice,
    price_index,
    device_setting,
    device_command,
    raw_event
  }
});
