<template>
  <v-container fill-height>
    <v-row class="align-center justify-center" >
      <v-col  style="max-width:500px">
        <v-card textcolor="transparent" flat>
          <v-card-text>

            <template v-if="step === 'resetPasswordConfirm'">
              <v-card>
                <v-card-title class="title"> {{$t("Reset password")}} </v-card-title>

                <v-card-text>
                  <v-form ref="form" v-model="valid" style="width:100%;height:100%;">
                    <v-text-field :append-icon="show ? 'visibility_off' : 'visibility'" :type="show ? 'text' : 'password'" :rules="passwordRule" required :label='$t("Set New Password")' v-model="new_password"  @click:append="show = !show"></v-text-field>
                  </v-form>
                </v-card-text>

                <template v-if="statusResetPassword === 'failure'">
                  <v-card-text>
                    <v-alert type="error" value="true"> {{$t("Password reset error")}} </v-alert>
                  </v-card-text>
                  <v-card-text class="text-center">Link is invalid.</v-card-text>
                </template>

                <template v-if="statusResetPassword === 'success'">
                  <v-card-text>
                    <v-alert type="info" value="true"> {{$t("New Password successfully set")}} </v-alert>
                  </v-card-text>
                  <!-- <v-card-text class="text-center">New temporary password was sent to your e-mail address.
                  </v-card-text> -->

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn roundedcolor="primary" :to="{name: 'login'}">
                      Log In
                    </v-btn>
                   
                  </v-card-actions>

                </template>
                <v-card-actions v-else>
                    <v-spacer></v-spacer>
                    <v-btn roundedcolor="primary" @click="setNewPassword()">
                      {{ $t("Set New Password") }}
                    </v-btn>
                  </v-card-actions>

              </v-card>
            </template>

            <template v-else>
              <v-card>
                <v-card-title class="title"> {{$t("Reset password")}} </v-card-title>

                <template v-if="statusForgotPassword === 'success'">
                  <v-card-text>
                    <v-alert type="info" value="true"> {{$t("Your request for password change successfully submitted.")}} </v-alert>
                  </v-card-text>
                  <v-card-text class="text-center">
                      {{$t("Please check your e-mail.")}}
                  </v-card-text>
                </template>

                <template v-else>
                  <v-card-text>
                    <v-alert type="error" :value="statusForgotPassword === 'failure'">{{$t("Something went wrong")}}</v-alert>
                  </v-card-text>
                  <v-card-text>
                    <v-form v-model="valid" style="width:100%;height:100%;">
                      <v-text-field :rules="emailRule" required v-model="email" label="E-Mail"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                      <v-btn outlined rounded :disabled="!valid" color="primary"
                        @click="forgotPassword({email})">{{$t("Reset password")}}
                      </v-btn>
                  </v-card-actions>
                </template>

              </v-card>
              <p align="center">
                <br/><router-link :to="{name: 'login'}">{{$t("Back to Login")}}</router-link>
              </p>
            </template>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { emailRule, passwordRule } from "../utils/rules";

export default {
  data() {
    return {
      email: "",
      valid: false,
      emailRule,
      passwordRule,
      show: false,
      password: 'Password',
      token: null,
      new_password: ""
    };
  },

  methods: {
    ...mapActions("user", ["forgotPassword", "resetPassword"]),

    setNewPassword() {
      this.$refs.form.validate();
      if (this.valid) {
        this.resetPassword({ token: this.token, password: this.new_password });
      }
    }
  },
  computed: {
    ...mapState({
      statusForgotPassword: ({ user }) => user.statusForgotPassword,
      statusResetPassword: ({ user }) => user.statusResetPassword
    }),
    step: function() {
      switch (this.$route.name) {
        case "resetPassword":
        case "resetPasswordConfirm":
          return this.$route.name;
      }
      return null;
    }
  },
  mounted() {
    this.token = this.$route.params.token;
    // if (this.step === "resetPasswordConfirm") {
    //   this.resetPassword({ token });
    // }
  },
  destroyed(){

  },
  watch: {
    // "$route.params.token"(val) {
    //   this.resetPassword({ token: val });
    // }
  }
};
</script>

<style scoped>
</style>
