<template>
  <v-container fluid v-if="status === 'success'">
    <v-row class="align-center justify-center">
      <!-- Provider Select & Aktionen -->
      <v-col cols="12" md="5">
        <v-select 
          :items="providerNames" 
          label="Provider" 
          v-model="provider_index"
          @change="set_provider"
          item-text="name"
          item-value="index"
        ></v-select>
      </v-col>
      <v-col cols="12" md="7">
        <v-btn v-if="mySelf.permissions.includes('create')" color="green" small dark class="mb-2" @click="startInvoicing()">
          Generate Invoices
        </v-btn>
        <v-btn v-if="mySelf.permissions.includes('rollback')" color="green" small dark class="mb-2" @click="deleteInvoice({ id: editedProvider, param: 'all' })">
          Rollback all Invoices
        </v-btn>
        <v-btn v-if="mySelf.permissions.includes('rollback')" color="green" small dark class="mb-2" @click="deleteInvoice({ id: editedProvider, param: 'last' })">
          Rollback last Invoice
        </v-btn>
        <v-btn v-if="mySelf.permissions.includes('rollback')" color="green" small dark class="mb-2" @click="dialog_invoice_process = true; sendInvoiceProviderAll(editedProvider);">
          Send all
        </v-btn>
      </v-col>

      <!-- Not Sent Invoices -->
      <v-col cols="12" md="12">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Not Sent Invoices</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search_invoice_notsend"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-data-table
          :headers="headers_invoice"
          :items="InvoiceNotSent"
          class="elevation-1"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
          :items-per-page="50"
          :search="search_invoice_notsend"
          sort-by="invoice_nr"
          :sort-desc="true"
          must-sort
        >
          <template v-slot:item="{ item }">
            <tr >
              <td>{{ item.invoice_prefix }}{{ item.invoice_nr }}</td>
              <td>{{ item.invoice_date | moment("DD.MM.YYYY") }}</td>
              <td>
                {{ item.contract.customer.companyname }} {{ item.contract.customer.lastname }}
              </td>
              <td>{{ item.metering_point[0].delivery_date_from | moment("DD.MM.YYYY") }}</td>
              <td>{{ item.metering_point[0].delivery_date_to | moment("DD.MM.YYYY") }}</td>
              <td>{{ item.metering_point[0].value_sum }} kWh</td>
              <td>{{ item.total_sum_gross.toFixed(2) }} {{ Global.currencyTypes[0].value }}</td>
              <td>{{ item.send.customer | moment("DD.MM.YYYY HH:mm:ss") }}</td>
              <td>{{ item.send.api | moment("DD.MM.YYYY HH:mm:ss") }}</td>
              <td class="justify-center layout px-0" v-if="item.pdf">
                <v-icon small class="mr-2" @click.stop="dialog_invoice_process = true; sendInvoice(item._id)">
                  mdi-email
                </v-icon>
              </td>
              <td class="justify-center layout px-0" v-if="item.pdf">
                <v-icon small class="mr-2" @click.stop="showInvoicePdf(item)">
                  picture_as_pdf
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <!-- Sent Invoices -->
      <v-col cols="12" md="12">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Sent Invoices</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search_invoice_send"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-data-table
          :headers="headers_invoice"
          :items="InvoiceSent"
          class="elevation-1"
          :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
          :items-per-page="50"
          :search="search_invoice_send"
          sort-by="invoice_nr"
          :sort-desc="true"
          must-sort
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.invoice_prefix }}{{ item.invoice_nr }}</td>
              <td>{{ item.invoice_date | moment("DD.MM.YYYY") }}</td>
              <td>
                {{ item.contract.customer.companyname }} {{ item.contract.customer.lastname }}
              </td>
              <td>{{ item.metering_point[0].delivery_date_from | moment("DD.MM.YYYY") }}</td>
              <td>{{ item.metering_point[0].delivery_date_to | moment("DD.MM.YYYY") }}</td>
              <td>{{ item.metering_point[0].value_sum }} kWh</td>
              <td>{{ item.total_sum_gross.toFixed(2) }} {{ Global.currencyTypes[0].value }}</td>
              <td>{{ item.send.customer | moment("DD.MM.YYYY HH:mm:ss") }}</td>
              <td>{{ item.send.api | moment("DD.MM.YYYY HH:mm:ss") }}</td>
              <td class="justify-center layout px-0" v-if="item.pdf">
                <v-icon small class="mr-2" @click.stop="sendInvoice(item._id)">
                  mdi-email
                </v-icon>
              </td>
              <td class="justify-center layout px-0" v-if="item.pdf">
                <v-icon small class="mr-2" @click.stop="showInvoicePdf(item)">
                  picture_as_pdf
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Invoice Processing Dialog -->
    <v-dialog v-model="dialog_invoice_process" width="450px" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Invoice Processing
        </v-card-title>
        <v-card-text>
          <v-progress-linear color="blue" indeterminate rounded></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Invoice Error Dialog -->
    <v-dialog v-model="dialog_invoice_error" width="900px" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Invoice Error
        </v-card-title>
        <v-card-text v-for="text in dialog_invoice_error_text" :key="text">
          {{ text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_invoice_error = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import api from "../api";

export default {
  name: "invoice",
  data() {
    return {
      //Required: [v => !!v || "The input is required"],
      // pagination: {
      //   sortBy: 'metering_point[0].delivery_date_to',
      //   descending: true
      // },
      // pagination_not_sent: {
      //   sortBy: 'metering_point[0].delivery_date_to',
      //   descending: true
      // },
      //contract: [],
      //person: {},
      //history: [],
      //location_index: 0,
      provider_index: 0,
      //customer_index: 0,
      //energyUnit_array: [],

      //history: [],
      dialog: false,
      //dialog_metering_point: false,
      //dialog_billing: false,
      //dialog_invest: false,
      search_index: '',
      //search_metering_point: '',
      search_invoice_notsend: '',
      search_invoice_send: '',
      headers_index: [
        { text: 'typeOf', align: 'left', sortable: true, value: 'typeOf'},
        { text: 'valid', align: 'left', sortable: true, value: 'valid_from'},
        { text: 'value', align: 'left', sortable: true, value: 'value'}
      ],
      headers_invoice: [
        { text: 'Number', sortable: true, value: 'invoice_nr' },
        { text: 'Date', sortable: true,value: 'invoice_date' },
        { text: 'customer', align: 'left', sortable: true, value: 'contract.customer.companyname'},
        { text: 'from', align: 'left', sortable: true, value: 'metering_point[0].delivery_date_from'},
        { text: 'to', align: 'left', sortable: true, value: 'metering_point[0].delivery_date_to'},
        { text: 'Consumption', align: 'left', sortable: true, value: 'metering_point[0].value_sum'},
        { text: 'sum_gross', align: 'left', sortable: true, value: 'total_sum_gross'},
        { text: 'send customer', align: 'left', sortable: true, value: 'send.customer'},
        { text: 'send api', align: 'left', sortable: true, value: 'send.api'},
        { text: 'Actions', value: 'action', sortable: false }
      ],
      editedIndex: -1,
      //editedMeteringPointIndex: -1,
      //editedHistoryIndex: -1,
      editedItem: {},
      editedProvider: null,
      defaultItem: {
        typeOf: null,
        valid_from: this.$moment().format("YYYY-MM-DD"),
        valid_to: this.$moment().format("YYYY-MM-DD"),
        value: null
      },
      dialog_invoice_process : false,
      dialog_invoice_error : false,
      dialog_invoice_error_text: "",
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions("person", [
      "getPersons"
      ]),
    ...mapActions(
      "location", ["getLocation"]
      ),

    ...mapActions(
      "energyunit", [
        "getEnergyUnit"
        ]
      ),
    ...mapActions(
      "contract", [
        "getContract",
        "getContractHistory",
        "deleteContract",
        "updateContract",
        "deactivateContract",
        "createContract"
        ]
    ),
    ...mapActions(
      "invoice", [
        "getInvoiceProvider",
        "deleteInvoice",
        "createInvoiceProvider",
        "sendInvoiceProviderAll",
        "sendInvoice"
        ]
      ),
    ...mapActions(
      "global", ["getGlobal"]
      ),
    ...mapActions(
      "price_index", [
        "getPriceIndex",
        "createPriceIndex",
        "updatePriceIndex"
        ]
      ),

    tom_delay(t, val) {
      return new Promise(resolve => {
        setTimeout(function() {
            resolve(val);
        }, t);
      });
    },

    async startInvoicing() {
      this.dialog_invoice_process = true;
      await this.createInvoiceProvider(this.editedProvider);
    },
    
    async initData() {
      // await this.getGlobal({})
      if (this.Global.length == 0) {
        await this.getGlobal({});
      }
      await this.getPriceIndex({})
      await this.getLocation({})
      await this.getPersons({})
      await this.getEnergyUnit({})    
      await this.getContract({})
    },

    createItem () {
      this.editedItem = _.cloneDeep(this.defaultItem)
      this.editedIndex = -1
      this.dialog = true
    },

    editItem (item) {
      this.editedIndex = this.PriceIndex.indexOf(item)
      this.editedItem = item
      this.editedItem.valid_from = this.$moment(this.editedItem.valid_from).format("YYYY-MM-DD")
      this.dialog = true
    },

    close () {
      this.dialog = false
    },

    async save () {
      this.$validator.validateAll().then(async result => {
      if (result) {
        this.editedItem.valid_to = this.$moment(this.editedItem.valid_from).endOf('month').toDate()
        this.editedItem.valid_from = this.$moment(this.editedItem.valid_to).startOf('month').toDate()
        if (this.editedIndex > -1) {
            await this.updatePriceIndex(this.editedItem)
          } else {  //new item
            await this.createPriceIndex(this.editedItem)
          }
          this.getPriceIndex({})
          this.close()
        }
        else {

        }
      })
    },

    comp_provider (id) {
      try{
        let findPerson = this.Persons.filter(obj => {return obj._id == id})[0]
        return findPerson.companyname + ' - ' + findPerson.lastname
      }
      catch {}
    },

    comp_priceIndex (id) {
      try {
         return this.Global.indexTypes.filter(obj => {return obj._id == id})[0].value
      }
      catch {
        return ""
      }
    },
    set_provider (index) {
      try {
        this.editedProvider = this.Persons[index]._id
      }
      catch {
        return ""
      }      
    },   
   
    showInvoicePdf (item) {
      //ToDo: Chage to XMLRequest -> siehe Leas-e
      window.open("/api/image/" + item.contract._id + '/' + item.pdf + '.pdf', '_blank')
    },

    checkSumGrossByMeteringPoint(arr) {
      const sumByContract = {};
      const countContract = {};

      arr.forEach(obj => {
        const total_sum_gross = obj.total_sum_gross;
        const contract = obj.metering_point[0].location;
        //console.log(contract, total_sum_gross);
        sumByContract[contract] = (sumByContract[contract] || 0) + total_sum_gross;
        countContract[contract] = (countContract[contract] || 0) + 1;
        //console.log(this.$moment().diff(this.$moment(obj.invoice_date), 'days'));
      });

      const keys = Object.keys(sumByContract);
      const avgByContract = {};

      keys.forEach((key, index)=> {
        //console.log(key, sumByContract[key], countContract[key]);
        avgByContract[key] = sumByContract[key] / countContract[key];
      });

      const filtered_arr = arr.filter(obj => {
        return this.$moment().diff(this.$moment(obj.invoice_date), 'days') <= 1;
      });

      const error = [];

      filtered_arr.forEach(obj => {
        const total_sum_gross = obj.total_sum_gross;
        const contract = obj.metering_point[0].location;
        const nr = obj.invoice_prefix + obj.invoice_nr;
        const factor = 3;
        if (avgByContract[contract]*factor < total_sum_gross || avgByContract[contract]/factor > total_sum_gross) {
          error.push("Abweichung vom Durchschnitt über Faktor " + String(factor) + " bei Rechnungsnr " + String(nr) + " erkannt! Durchschnitt: " + avgByContract[contract].toFixed(2) + " Rechnungsbetrag: " + total_sum_gross.toFixed(2));
        }
      });

      return error;
    },

    checkInvoiceRoutine() {
      return new Promise(resolve => {
        console.log("Check Invoice started");
        let invoices = JSON.parse(JSON.stringify(this.Invoice));
        invoices = _.sortBy(invoices, "invoice_nr");

        let error = this.checkSumGrossByMeteringPoint(invoices);
        //console.log("contract_avg: ", JSON.stringify(contract_avg));
        
        for (let idx=0; idx < invoices.length - 1; idx++) {
          //console.log("Check Invoice Nr: ", invoices[idx].invoice_nr, invoices[idx+1].invoice_nr);
          if (invoices[idx].invoice_nr + 1 != invoices[idx+1].invoice_nr) {
            //Alte Fehler:
            if (invoices[idx].invoice_nr + 1 != 767 && invoices[idx].invoice_nr + 1 != 815) {
              error.push(invoices[idx].invoice_nr + 1);
              console.log("Fehlende Rechnungsnummer: ", invoices[idx].invoice_nr+1);
            }
          }
        }
        resolve(error);
      });
    }
  },
  computed: {
    ...mapState({
      Locations: ({ location }) => location.Locations,
      Persons: ({ person }) => person.Persons,
      statusPersons: ({ person }) => person.status,
      EnergyUnit: ({ energyunit }) => energyunit.EnergyUnit,
      Contract: ({ contract }) => contract.Contract,
      statusUpdate: ({ contract }) => contract.statusUpdate,
      statusDelete: ({ contract }) => contract.statusDelete,
      statusDeactivate: ({ contract }) => contract.statusDeactivate,
      Global: ({ global }) => global.Global,
      Invoice: ({ invoice }) => invoice.Invoice,
      mySelf: ({auth}) => auth.mySelf,
      PriceIndex: ({price_index}) => price_index.PriceIndex,
      statusInvoiceCreateProvider: ({ invoice }) => invoice.statusCreateProvider,
      statusInvoiceSend: ({ invoice }) => invoice.statusSend,
      statusInvoiceSendProviderAll: ({ invoice }) => invoice.statusSendProviderAll,
      statusInvoiceDelete: ({ invoice }) => invoice.statusDelete,
      status: ({ contract }) => contract.status,
    }),
    
    InvoiceSent() {
      return this.Invoice.filter(v => {return v.send.customer !== null})
    },
    InvoiceNotSent() {
      return this.Invoice.filter(v => {return v.send.customer === null})
    },

    formTitle () {
      return this.editedIndex === -1 ? 'New PriceIndex' : 'Edit PriceIndex'
    },

    providerNames () {
      let providernames = []
      this.Persons.forEach((provider, index) => {
        if (provider.is_provider) {
          providernames.push({name: provider.companyname + ' - ' + provider.lastname + ' ' + provider.firstname, index: index})
        }
      })
      providernames.sort()
      this.set_provider(0)
      return providernames
    },
  },

  watch: {
    dialog () {
      this.$validator.reset()
    },
    status(val) {
      this.loading = val === "fetching";
      // if (val === "success") {
       
      // }
    },
    
    async statusInvoiceCreateProvider(val) {
      this.loading = val === "fetching";
      if (val === "success") {   
        console.log("statusInvoiceCreateProvider")
        await this.getInvoiceProvider({id: this.editedProvider})
        //console.log(_.find(this.Invoice, {pdf: null}))
        let waitTime = new Date().getTime()
        while (_.find(this.Invoice, {pdf: null}) && new Date().getTime() - waitTime < 8000) {
          this.$store.state.loading = true
          let start = new Date().getTime()
          let end = start
          while(end < start + 500) {
            end = new Date().getTime()
          }
          await this.getInvoiceProvider({id: this.editedProvider}) 
        }
        let error = await this.checkInvoiceRoutine();
        this.dialog_invoice_process = false;
        if (error.length > 0) {
          this.dialog_invoice_error = true;
          // for (let err of error) {
          //   this.dialog_invoice_error_text += err + "<br>"; 
          // }
          this.dialog_invoice_error_text = error; 
        }
      }
      
    },
    async statusInvoiceSend(val) {
      this.loading = val === "fetching";
      console.log("statusInvoiceSend ", val);
      if (val === "success") {
        //this.getInvoiceProvider({id: this.editedProvider});
        await this.tom_delay(2000);
        await this.getInvoiceProvider({id: this.editedProvider});
        this.dialog_invoice_process = false;
      }
    },
    async statusInvoiceSendProviderAll(val) {
      console.log("statusInvoiceSendProviderAll ", val);
      if (val === "success") {
        this.dialog_invoice_process = true;
        console.log("statusInvoiceSendProviderAll get Invoice ", val);
         await this.tom_delay(10000);
        //this.getInvoiceProvider({id: this.editedProvider})
        await this.getInvoiceProvider({id: this.editedProvider});
        this.dialog_invoice_process = false;
      }
    },
    async statusInvoiceDelete (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
        //this.getInvoiceProvider({id: this.editedProvider})
        await this.tom_delay(1000);
        await this.getInvoiceProvider({id: this.editedProvider})
      }
    },
    statusUpdate (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
       // this.initData()
      }
    },
    statusDeactivate (val) {
      this.loading = val === 'fetching'
      if(val === 'success') {
        //this.initData()
      }
    },
    loading(val) {
      this.$store.state.loading = val
    },

    async editedProvider (val) {
      console.log("editedProvider")
      await this.getInvoiceProvider({id: this.editedProvider})
      
    }   
  }
}

</script>


