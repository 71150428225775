import {
  AuthResource,
  SignUpResource,
  ActivateAccountResource,
  MySelfResource,
  ForgotPasswordResource,
  ResetPasswordResource,
  DeviceResource,
  DeviceOnlyResource,
  DeviceAllResource,
  DeviceLocationUpdateResource,
  DeviceConfigUpdateResource,
  DeviceEventResource,
  DeviceGetDebugValuesResource,
  //MQTTSetHTResource,
  //MQTTSetNTResource,
  //MQTTallAwakeResource,
  MQTTsetDeviceResource,
  CreatePersonResource,
  PersonResource,
  PersonDeleteResource,
  PersonUpdateResource,
  PersonDeactivateResource,
  PersonHistoryResource,
  LocationResource,
  LocationCreateResource,
  LocationUpdateResource,
  LocationDeleteResource,
  UserReadResource,
  UserResource,
  CreateEnergyUnitResource,
  EnergyUnitResource,
  EnergyUnitDeleteResource,
  EnergyUnitUpdateResource,
  EnergyUnitDeactivateResource,
  EnergyUnitHistoryResource,
  GlobalResource,
  GlobalAllResource,
  GlobalCreateResource,
  GlobalUpdateResource,
  PriceIndexResource,
  CreatePriceIndexResource,
  PriceIndexUpdateResource,
  CreateContractResource,
  ContractResource,
  ContractDeleteResource,
  ContractUpdateResource,
  ContractDeactivateResource,
  ContractHistoryResource,
  EventallResetLocationResource,
  InvoiceResource,
  InvoiceDeleteResource,
  InvoiceProviderResource,
  InvoiceCreateProviderResource,
  InvoiceSendResource,
  InvoiceSendProviderAllResource,
  DeviceSettingResource,
  DeviceSettingCreateResource,
  DeviceSettingUpdateResource,
  DeviceGetResource,
  DeviceGetIFrameResource,
  LocationEventIndexResource,
  DeviceCommandResource,
  DeviceCommandCreateResource,
  DeviceCommandUpdateResource,
  LocationRawEventIndexResource,
  UserCreateResource,
  UserUpdateResource
} from "./resources";

export default {
  authenticate({ email, password }) {
    return AuthResource.save({}, { email, password });
  },
  mySelf() {
    return MySelfResource.get();
  },
  signUp({ email, password, displayname, dsgvo }) {
    return SignUpResource.save({}, { email, password, displayname, dsgvo });
  },
  activateAccount({ token }) {
    return ActivateAccountResource.get({ token }, {});
  },
  forgotPassword({ email }) {
    return ForgotPasswordResource.save({ email });
  },
  resetPassword(obj) {
    return ResetPasswordResource.save({}, obj);
  },
  getDevices({ type } = {}) {
    return DeviceResource.get({ type });
  },
  getDevicesOnly({ type } = {}) {
    return DeviceOnlyResource.get({ type });
  },
  getDevice(device) {
    return DeviceGetResource.get(device);
  },
  getDeviceDebugValues(device) {
    return DeviceGetDebugValuesResource.get(device);
  },
  getDevicesAll() {
    return DeviceAllResource.get();
  },
  updateDeviceLocation(deviceLocation) {
    return DeviceLocationUpdateResource.update({id: deviceLocation.deviceId} , deviceLocation);
  },
  updateDeviceConfig(item) {
    const id = item.id
    return DeviceConfigUpdateResource.update({id}, item.config);
  },
  getDeviceEvents(obj) {  //{ deviceId, days, startDate, endDate }
    // console.log("getDeviceEvents", obj)
    return DeviceEventResource.get(obj);
  },
  getLocationEventsIndex(obj) {
    return LocationEventIndexResource.get(obj);
  },
  /* setHT({ device, HT }) {
    return MQTTSetHTResource.save({}, { device, HT });
  },
  setNT({ device, NT }) {
    return MQTTSetNTResource.save({}, { device, NT });
  },
  allAwake() {
    return MQTTallAwakeResource.save({}, {});
  },*/
  setDevice(obj) {
    return MQTTsetDeviceResource.save({}, obj);
  },
  allResetLocation() {
    return EventallResetLocationResource.save({}, {});
  },
  createPerson(person) {
    return CreatePersonResource.save({},person);
  },
  getPersons() {
    return PersonResource.get();
  },
  getPersonHistory(id) {
    return PersonHistoryResource.get({ id }, {});
  },
  deletePerson(id) {
    return PersonDeleteResource.delete({ id }, {});
  },
  deactivatePerson(id) {
    return PersonDeactivateResource.update({ id }, {});
  },
  updatePerson(person) {
    return PersonUpdateResource.update({ id: person._id }, person);
  },
  getLocation() {
    return LocationResource.get();
  },
  createLocation(location) {
    return LocationCreateResource.save({}, location);
  },
  updateLocation(location) {
    return LocationUpdateResource.update({ id: location._id }, location);
  },
  deleteLocation(id) {
    return LocationDeleteResource.delete({ id }, {});
  },
  readUser({id}){
    return UserReadResource.get({id}, {});
  },
  getUser(){
    return UserResource.get();
  },
  createUser(user) {
    return UserCreateResource.save({}, user);
  },
  updateUser(user) {
    return UserUpdateResource.update({ id: user._id }, user);
  },
  createEnergyUnit(energyunit) {
    return CreateEnergyUnitResource.save({},energyunit);
  },
  getEnergyUnit() {
    return EnergyUnitResource.get();
  },
  getEnergyUnitHistory(id) {
    return EnergyUnitHistoryResource.get({ id }, {});
  },
  deleteEnergyUnit(id) {
    return EnergyUnitDeleteResource.delete({ id }, {});
  },
  deactivateEnergyUnit(id) {
    return EnergyUnitDeactivateResource.update({ id }, {});
  },
  updateEnergyUnit(energyunit) {
    return EnergyUnitUpdateResource.update({ id: energyunit._id }, energyunit);
  },
  getGlobal() {
    return GlobalResource.get();
  },
  getGlobalAll() {
    return GlobalAllResource.get();
  },
  createGlobal(item) {
    return GlobalCreateResource.save({}, item )
  },
  updateGlobal(item) {
    return GlobalUpdateResource.update({ id: item._id }, item);
  },
  getPriceIndex() {
    return PriceIndexResource.get();
  },
  createPriceIndex(price_index) {
    return CreatePriceIndexResource.save({},price_index)
  },
  updatePriceIndex(price_index) {
    return PriceIndexUpdateResource.update({ id: price_index._id }, price_index);
  },
  createContract(contract) {
    return CreateContractResource.save({},contract);
  },
  getContract() {
    return ContractResource.get();
  },
  getContractHistory(id) {
    return ContractHistoryResource.get({ id }, {});
  },
  deleteContract(id) {
    return ContractDeleteResource.delete({ id }, {});
  },
  deactivateContract(id) {
    return ContractDeactivateResource.update({ id }, {});
  },
  updateContract(contract) {
    return ContractUpdateResource.update({ id: contract._id }, contract);
  },
  getInvoice(id) {
    return InvoiceResource.get({ id }, {});
  },
  createInvoiceProvider(id) {
    return InvoiceCreateProviderResource.save({id}, {});
  },
  deleteInvoice(id, param) {
    return InvoiceDeleteResource.delete({id}, {param});
  },
  getInvoiceProvider(id) {
    return InvoiceProviderResource.get(id, {});
  },
  sendInvoiceProviderAll(id) {
    return InvoiceSendProviderAllResource.save({id}, {});
  },
  sendInvoice(id) {
    return InvoiceSendResource.save({id}, {});
  },
  getDeviceSetting() {
    return DeviceSettingResource.get();
  },
  createDeviceSetting(item) {
    return DeviceSettingCreateResource.save({}, item )
  },
  updateDeviceSetting(item) {
    return DeviceSettingUpdateResource.update({ id: item._id }, item);
  },
  getDeviceIFrame(id) {
    return DeviceGetIFrameResource.get(id, {});
  },
  getDeviceCommand() {
    return DeviceCommandResource.get();
  },
  createDeviceCommand(item) {
    return DeviceCommandCreateResource.save({}, item )
  },
  updateDeviceCommand(item) {
    return DeviceCommandUpdateResource.update({ id: item._id }, item);
  },
  getLocationRawEventsIndex({ deviceId, days, startDate, endDate }) {
    return LocationRawEventIndexResource.get({ deviceId, days, startDate, endDate });
  },
};
