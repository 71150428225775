import "@babel/polyfill";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
//import "./plugins/vuetify";
//import Vuetify from 'vuetify'
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import Auth from "./plugins/auth";
import "./registerServiceWorker";
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VeeValidate from 'vee-validate'
import VueI18n from 'vue-i18n'
import en from 'vee-validate/dist/locale/en.js'
import de from 'vee-validate/dist/locale/de.js'
import messages from './language'
import VueApexCharts from 'vue-apexcharts'
import { DatetimePicker, Picker } from 'vant';
import 'vant/lib/index.css';



Vue.config.productionTip = false;

Vue.use(Auth, { router, store });
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(VueMoment, {
  moment
})
Vue.use(VeeValidate)
//Vue.use(Vuetify)
//Vue.use(draggable)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(Auth, { router })
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'de',
  messages: messages.messages
})

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    de, en
  }
})

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
