<template>
  <v-container fluid>
    <v-row class="align-center justify-center">
      <v-col md="12" cols="12">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ $t('Persons') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            @input="editedIndex = -1; history = []"
          ></v-text-field>
          <v-btn color="green" fab small dark class="mb-2" @click="createItem">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="1000px" persistent>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-form>
                  <v-container pa-0 ma-1>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editedItem.firstname"
                          label="Firstname"
                          autofocus
                          v-validate
                          data-vv-name="form_firstname"
                          :error-messages="errors.collect('form_firstname')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editedItem.lastname"
                          label="Lastname"
                          autofocus
                          v-validate
                          data-vv-name="form_lastname"
                          :error-messages="errors.collect('form_lastname')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="editedItem.companyname"
                          label="Companyname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="Global.companyTypes">
                        <v-select
                          :items="Global.companyTypes"
                          item-text="value"
                          item-value="_id"
                          label="Company Type"
                          v-model="editedItem.company_type"
                          v-validate
                          data-vv-name="form_company_type"
                          :error-messages="errors.collect('form_company_type')"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="editedItem.companies_register_number"
                          label="companies_register_number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="editedItem.vatnr"
                          label="vatnr"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editedItem.street"
                          label="street"
                          v-validate="'required'"
                          data-vv-name="form_street"
                          :error-messages="errors.collect('form_street')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="editedItem.street_number"
                          label="street_number"
                          v-validate="'required'"
                          data-vv-name="form_number"
                          :error-messages="errors.collect('form_number')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="editedItem.code"
                          label="code"
                          type="number"
                          v-validate="'required'"
                          data-vv-name="form_code"
                          :error-messages="errors.collect('form_code')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editedItem.town"
                          label="town"
                          v-validate="'required'"
                          data-vv-name="form_town"
                          :error-messages="errors.collect('form_town')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          v-model="editedItem.country"
                          label="country"
                          v-validate="'required'"
                          data-vv-name="form_country"
                          :error-messages="errors.collect('form_country')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editedItem.comm[0].text"
                          label="email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-checkbox
                          v-model="editedItem.securedata"
                          label="Secure Data"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3" v-if="editedItem.bank_account">
                        <v-text-field
                          v-model="editedItem.bank_account.name"
                          label="Account Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="editedItem.bank_account">
                        <v-text-field
                          v-model="editedItem.bank_account.iban"
                          label="IBAN"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" v-if="editedItem.bank_account">
                        <v-text-field
                          v-model="editedItem.bank_account.bic"
                          label="BIC"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-checkbox
                          v-model="editedItem.is_provider"
                          label="Provider"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="6" v-if="editedItem.is_provider">
                        <v-text-field
                          v-model="editedItem.min_invoice_sum"
                          label="Minimum Net Invoice Sum in Euro"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" v-if="editedIndex === -1">
                        <v-select
                          :items="locationNames"
                          label="owning_location"
                          v-model="owning_location_index"
                          @change="set_owning_location"
                          item-text="name"
                          item-value="index"
                          v-validate="'required'"
                          data-vv-name="form_owning_location"
                          :error-messages="errors.collect('form_owning_location')"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" v-else>
                        <v-select
                          :items="locationNames"
                          label="owning_location"
                          v-model="owning_location_index"
                          item-text="name"
                          item-value="index"
                          disabled
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="allLocationNames"
                          label="location"
                          v-model="location_index"
                          @change="set_location"
                          item-text="name"
                          item-value="index"
                          v-validate="'required'"
                          data-vv-name="form_location"
                          :error-messages="errors.collect('form_location')"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" v-if="editedItem.is_provider">
                        <v-textarea
                          label="server email settings"
                          v-model="editedItem.provider_email"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-textarea
                          label="locationInfo"
                          v-model="editedItem.locationInfo"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-textarea
                          label="description"
                          v-model="editedItem.description"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <!-- Haupt-Daten-Tabelle -->
        <v-data-table
          :headers="headers"
          :items="filteredPersons"
          class="elevation-0"
          :search="search"
          :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
          :items-per-page="50"
          sort-by="number"
          must-sort
        >
          <template v-slot:item="{ item }">
            <tr @click="on_click(item)" :class="{ 'grey--text': !item.active }">
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click.stop="editItem(item)">mdi-pencil</v-icon>
                <v-icon
                  v-if="item.active && mySelf.permissions.includes('deactivate')"
                  small
                  @click.stop="deactivateItem(item)"
                >mdi-delete</v-icon>
                <v-icon
                  v-else
                  small
                  @click.stop="activateItem(item)"
                >mdi-eye</v-icon>
              </td>
              <td>{{ item.number }}</td>
              <td>{{ item.firstname }}</td>
              <td>{{ item.lastname }}</td>
              <td>{{ item.companyname }}</td>
              <td>{{ comp_company_type(item.company_type) }}</td>
              <td>{{ item.companies_register_number }}</td>
              <td>{{ item.street }}</td>
              <td>{{ item.street_number }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.town }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.vatnr }}</td>
              <td v-if="item.comm.length > 0">{{ item.comm[0].text }}</td>
              <td v-else>nothing</td>
              <td v-if="item.bank_account">{{ item.bank_account.name }}</td>
              <td v-else>nothing</td>
              <td v-if="item.bank_account">{{ item.bank_account.iban }}</td>
              <td v-else>nothing</td>
              <td v-if="item.bank_account">{{ item.bank_account.bic }}</td>
              <td v-else>nothing</td>
              <td>{{ item.securedata }}</td>
              <td>{{ comp_location(item.owning_location) }}</td>
              <td>{{ comp_location(item.location) }}</td>
              <td>{{ item.is_provider }}</td>
              <td>{{ item.min_invoice_sum }}</td>
              <td class="justify-center layout px-0">
                <v-icon small class="mr-2" @click.stop="editItem(item)">mdi-pencil</v-icon>
                <v-icon
                  v-if="item.active && mySelf.permissions.includes('deactivate')"
                  small
                  @click.stop="deactivateItem(item)"
                >mdi-delete</v-icon>
                <v-icon
                  v-else
                  small
                  @click.stop="activateItem(item)"
                >mdi-eye</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <!-- History-Daten-Tabelle -->
      <v-col md="12" cols="12" v-if="editedIndex > -1">
        <v-toolbar color="primary" dark>
          <v-toolbar-title v-if="Persons[editedIndex]">
            History of {{ Persons[editedIndex].companyname }} {{ Persons[editedIndex].lastname }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table
          :headers="headers_history"
          :items="history"
          class="elevation-1"
          :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
          :items-per-page="50"
          sort-by="timestamp"
          :sort-desc="true"
          must-sort
        >
          <template v-slot:item="{ item }">
            <tr>
              <td class="justify-center layout px-0">
                <v-icon
                  v-if="item.active"
                  small
                  class="mr-2"
                  @click.stop="editItem(item)"
                >mdi-refresh</v-icon>
              </td>
              <td>{{ $moment(item.timestamp).format('DD-MM-YYYY HH:mm') }}</td>
              <td>{{ item.active }}</td>
              <td>{{ item.firstname }}</td>
              <td>{{ item.lastname }}</td>
              <td>{{ item.companyname }}</td>
              <td>{{ comp_company_type(item.company_type) }}</td>
              <td>{{ item.companies_register_number }}</td>
              <td>{{ item.street }}</td>
              <td>{{ item.street_number }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.town }}</td>
              <td>{{ item.country }}</td>
              <td>{{ item.vatnr }}</td>
              <td v-if="item.comm.length > 0">{{ item.comm[0].text }}</td>
              <td v-else>nothing</td>
              <td v-if="item.bank_account">{{ item.bank_account.name }}</td>
              <td v-else>nothing</td>
              <td v-if="item.bank_account">{{ item.bank_account.iban }}</td>
              <td v-else>nothing</td>
              <td v-if="item.bank_account">{{ item.bank_account.bic }}</td>
              <td v-else>nothing</td>
              <td>{{ item.securedata }}</td>
              <td>{{ comp_location(item.owning_location) }}</td>
              <td>{{ comp_location(item.location) }}</td>
              <td>{{ item.is_provider }}</td>
              <td>{{ item.user }}</td>
              <td class="justify-center layout px-0">
                <v-icon
                  v-if="item.active"
                  small
                  class="mr-2"
                  @click.stop="editItem(item)"
                >mdi-refresh</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :color="snackbar_color">
      {{ snackbar_text }}
      <v-btn color="black" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";
import api from "../api";

export default {
  name: "person",
  data() {
    return {
      //Required: [v => !!v || "The input is required"],
      // pagination: {
      //   sortBy: "timestamp",
      //   descending: true,
      // },
      person: {},
      history: [],
      owning_location_index: 0,
      location_index: 0,
      dialog: false,
      search: "",
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      headers: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Nr", value: "number" },
        {
          text: "Firstname",
          align: "left",
          sortable: true,
          value: "firstname",
        },
        {
          text: "Lastname",
          sortable: true,
          value: "lastname",
        },
        { text: "Company", value: "companyname" },
        { text: "Company Type", value: "company_type" },
        { text: "Company RegNr", value: "companies_register_number" },
        { text: "Street", value: "street" },
        { text: "Number", value: "street_number" },
        { text: "code", value: "code" },
        { text: "town", value: "town" },
        { text: "country", value: "country" },
        { text: "vatnr", value: "vatnr" },
        { text: "email", value: "comm[0].text", sortable: false },
        { text: "securedata", value: "securedata", sortable: false },
        {
          text: "Bank Account Name",
          value: "bank_account.name",
          sortable: false,
        },
        { text: "IBAN", value: "bank_account.iban", sortable: false },
        { text: "BIC", value: "bank_account.bic", sortable: false },
        { text: "owning_location", value: "owning_location" },
        { text: "location", value: "location" },
        { text: "is_provider", value: "is_provider", sortable: false },
        { text: "Min Net Invoice Sum", value: "min_invoice_sum", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers_history: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Changed at", value: "timestamp" },
        { text: "active", value: "active" },
        {
          text: "Firstname",
          align: "left",
          sortable: true,
          value: "firstname",
        },
        {
          text: "Lastname",
          align: "left",
          sortable: false,
          value: "lastname",
        },
        { text: "Company", value: "companyname", sortable: false },
        { text: "Company Type", value: "company_type" },
        { text: "Company RegNr", value: "companies_register_number" },
        { text: "Street", value: "street", sortable: false },
        { text: "Number", value: "street_number", sortable: false },
        { text: "code", value: "code", sortable: false },
        { text: "town", value: "town", sortable: false },
        { text: "country", value: "country", sortable: false },
        { text: "vatnr", value: "vatnr", sortable: false },
        { text: "email", value: "comm[0].text", sortable: false },
        { text: "securedata", value: "securedata", sortable: false },
        {
          text: "Bank Account Name",
          value: "bank_account.name",
          sortable: false,
        },
        { text: "IBAN", value: "bank_account.iban", sortable: false },
        { text: "BIC", value: "bank_account.bic", sortable: false },
        { text: "owning_location", value: "owning_location", sortable: false },
        { text: "location", value: "location", sortable: false },
        { text: "is_provider", value: "is_provider", sortable: false },
        { text: "Min Net Invoice Sum", value: "min_invoice_sum", sortable: false },
        { text: "user", value: "user" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedHistoryIndex: -1,
      editedItem: {
        firstname: "",
        lastname: "",
        companyname: "",
        company_type: null,
        companies_register_number: "",
        street: "",
        street_number: "",
        code: 0,
        town: "",
        country: "",
        vatnr: "",
        accounting: {},
        is_provider: false,
        comm: [
          {
            commtype: "5d319cddc0905f1818e9456f", //email
            text: "",
          },
        ],
        bank_account: {
          name: "",
          iban: "",
          bic: "",
        },
        owning_location: null,
        location: null,
        provider_email: {},
        securedata: true
      },
      defaultItem: {
        firstname: "",
        lastname: "",
        companyname: "",
        company_type: null,
        companies_register_number: "",
        street: "",
        street_number: "",
        code: 0,
        town: "",
        country: "",
        vatnr: "",
        accounting: {},
        is_provider: false,
        comm: [
          {
            commtype: "5d319cddc0905f1818e9456f", //email
            text: "",
          },
        ],
        owning_location: null,
        location: null,
        bank_account: {
          name: "",
          iban: "",
          bic: "",
        },
        provider_email: {},
        securedata: true
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions("person", [
      "getPersons",
      "getPersonHistory",
      "deletePerson",
      "updatePerson",
      "deactivatePerson",
      "createPerson",
    ]),
    ...mapActions("location", ["getLocation"]),
    ...mapActions("global", ["getGlobal"]),
    async initData() {
      // await this.getGlobal({});
      if (this.Global.length == 0) {
        await this.getGlobal({});
      }
      await this.getLocation({});
      await this.getPersons({});
    },

    createItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.editedHistoryIndex = -1;
      this.history = [];
      this.owning_location_index = 0;
      this.set_owning_location(this.owning_location_index);
      this.location_index = 0;
      this.set_owning_location(this.location_index);
      this.dialog = true;
    },

    editItem(item) {
      this.editedHistoryIndex = this.history.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.is_provider) {
        this.editedItem.provider_email = JSON.stringify(
          this.editedItem.provider_email
        );
      }
      //console.log("DEFINE");
      this.editedItem.comm[0].commtype = "5d319cddc0905f1818e9456f";
      if (_.isUndefined(this.editedItem.bank_account)) {
        //console.log("DEFINE2");
        this.editedItem.bank_account = {
          name: "",
          iban: "",
          bic: "",
        };
      }
      this.owning_location_index = this.Locations.findIndex((loc) => {
        return loc._id == this.editedItem.owning_location;
      });
      this.location_index = this.Locations.findIndex((loc) => {
        return loc._id == this.editedItem.location;
      });
      this.dialog = true;
    },

    async deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
        (await this.deletePerson(item._id));
      this.history = [];
      this.getPersons({});
    },

    async deactivateItem(item) {
      await this.deactivatePerson(item._id);
      this.getPersons({});
      this.getPersonHistories(item);
    },

    on_click(item) {
      this.editedIndex = this.Persons.indexOf(item);
      this.getPersonHistories(item);
    },

    async getPersonHistories(item) {
      //console.log("getHistory " + this.editedIndex)
      if (this.editedIndex > -1) {
        await this.getPersonHistory(item._id);
        this.history = this.History;
        //console.log(this.History);
      }
    },

    async activateItem(item) {
      await this.deactivatePerson(item._id);
      this.getPersons({});
      this.getPersonHistories(item);
    },

    close() {
      this.dialog = false;
    },

    async save() {
      this.$validator.validateAll().then(async (result) => {
        let parse_error = false;
        if (result) {
          if (this.editedItem.is_provider) {
            try {
              this.editedItem.provider_email = JSON.parse(
                this.editedItem.provider_email
              ); }
            catch(err) {
              this.snackbar_text = "JSON not parseable";
              this.snackbar = true;
              parse_error = true;
            }
          } else {
            this.editedItem.provider_email = {};
          }
          if (!parse_error) {
            if (this.editedIndex > -1 || this.editedHistoryIndex > -1) {
              await this.updatePerson(this.editedItem);
            } else {
              //new item
              await this.createPerson(this.editedItem);
            }

            this.getPersons({});
            this.getPersonHistories(this.editedItem);
            this.close();
          }
        } else {
        }
      });
    },

    comp_location(id) {
      try {
        return this.Locations.filter((obj) => {
          return obj._id == id;
        })[0].name;
      } catch {}
    },

    comp_company_type(id) {
      //console.log(id)
      try {
        return this.Global.companyTypes.filter((obj) => {
          return obj._id == id;
        })[0].value;
      } catch {}
    },

    set_owning_location(index) {
      this.editedItem.owning_location = this.Locations[index]._id;
    },
    set_location(index) {
      this.editedItem.location = this.Locations[index]._id;
    },
  },
  computed: {
    ...mapState({
      Persons: ({ person }) => person.Persons,
      History: ({ person }) => person.History,
      status: ({ person }) => person.status,
      statusUpdate: ({ person }) => person.statusUpdate,
      statusCreate: ({ person }) => person.statusCreate,
      statusDelete: ({ person }) => person.statusDelete,
      statusDeactivate: ({ person }) => person.statusDeactivate,
      statusHistory: ({ person }) => person.statusHistory,
      Locations: ({ location }) => location.Locations,
      Global: ({ global }) => global.Global,
      mySelf: ({ auth }) => auth.mySelf,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Person" : "Edit Person";
    },
    filteredPersons() {
      /*return this.Persons.filter((p) => {
        return p.active === true
      })
      */
      return this.Persons;
    },

    locationNames() {
      let locnames = [];
      let _type = _.find(this.Global.locationTypes, { value: "root" });
      let _root = _.find(this.Locations, { type: _type._id });

      if (_.isUndefined(_root)) {
        _type = _.find(this.Global.locationTypes, { value: "project" });
        _root = _.find(this.Locations, { type: _type._id });
      }
      if (_.isUndefined(_root)) {
        _type = _.find(this.Global.locationTypes, { value: "site" });
        _root = _.find(this.Locations, { type: _type._id });
      }
      //console.log(_root);
      if (!_.isUndefined(_root)) {
        this.Locations.forEach((loc, index) => {
          if (loc.parent == _root._id) {
            locnames.push({ name: loc.name, index: index });
          }
        });
      }
      return locnames;
    },
    allLocationNames() {
      let locnames = [];
      this.Locations.sort((a, b) => {
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      this.Locations.forEach((loc, index) => {
        locnames.push({ name: loc.name, index: index });
      });
      return locnames;
    },

    /*provider_email: {
      get: function () {
        return JSON.stringify(this.editedItem.provider_email)
      },
      set: function (newValue) {
        this.editedItem.provider_email = JSON.parse(newValue)
      }
      
    }*/
  },

  watch: {
    dialog() {
      this.$validator.reset();
    },
    status(val) {
      this.loading = val === "fetching";
    },
    statusCreate(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        this.initData();
      }
    },
    statusDelete(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        this.initData();
      }
    },
    statusUpdate(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        this.initData();
      }
    },
    statusDeactivate(val) {
      this.loading = val === "fetching";
      if (val === "success") {
        this.initData();
      }
    },
    loading(val) {
      this.$store.state.loading = val;
    },
  },
};
</script>