<template >
  <v-container pa-0 ma-0  fluid>
    <!--<div ref="kwhd"></div>-->
    <v-row style="min-height: 50px">
      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.startDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="Start Date"
              :value="startDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              () => {
                modals.startDate = false;
              }
            "
            min="2020-01-01"
            v-model="startDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.startDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.endDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="End Date"
              :value="endDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template> 
          <v-date-picker
            @input="
              () => {
                modals.endDate = false;
              }
            "
            :min="startDate"
            :max="maxDate"
            v-model="endDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.endDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card>
      <v-row >
        <v-col cols="12" sm="2"  sm2 py-0 ma-1 left>
          <div class="body-2">Refresh Main Data</div>
          <v-btn small icon color="accent" @click="getDeviceDebug()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="2"  sm2 py-0 ma-1 left>
          <div class="body-2">AutoRefresh</div>
          <v-switch
            height="0"
            v-model="autoRefresh"
            color="blue"
            @change="setAutoRefresh()"
            :label="autoRefresh.toString()"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="2" py-2 ma-1 left>
          <div class="body-2">DeviceID:</div>
          <div class="body-1">{{ device.device_id }}</div>
        </v-col>
        <v-col  cols="12" sm="2" py-2 ma-1 left>
          <div class="body-2">Alive:</div>
          <div v-if="device.alive" class="body-1">{{ device.alive | moment("DD.MM HH:mm:ss") }}</div>
          <div v-else-if="device.status" class="body-1">{{ device.status.lastevent | moment("DD.MM HH:mm") }}</div>
        </v-col>
        <v-col  cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">Records:</div>
          <div v-show="events.length > 0" id="data-count"></div>
        </v-col>
      </v-row>
    </v-card>

    <br />
    <v-row
      style="min-height: 50px"
      v-if="device.values"
    >
      <v-col  md="2" cols="12"  v-if="meterReading > 0">
        <v-card style="min-height: 170px" class="elevation-4 text-center">
          <v-card-text>
            <div
              v-if="meterReadingNight == 0"
              class="headline font-weight-bold pt-3"
            >
              {{ meterReading }}
              <div class="title">kWh</div>
            </div>
            <div v-else class="headline font-weight-bold pt-1">
              <div class="title">NT:</div>
              {{ meterReadingNight }}
              <div class="title">kWh</div>
              <br />
              <div class="title">HT:</div>
              {{ meterReadingDay }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">Meter reading Consumption</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  md="2" cols="12"  v-if="meterReading2 > 0">
        <v-card style="min-height: 170px" class="elevation-4 text-center">
          <v-card-text>
            <div
              v-if="meterReadingNight2 == 0"
              class="headline font-weight-bold pt-3"
            >
              {{ meterReading2 }}
              <div class="title">kWh</div>
            </div>
            <div v-else class="headline font-weight-bold pt-1">
              <div class="title">NT:</div>
              {{ meterReadingNight2 }}
              <div class="title">kWh</div>
              <br />
              <div class="title">HT:</div>
              {{ meterReadingDay2 }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">Meter reading production</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 170" class="elevation-4 text-center pt-3">
          <v-card-text v-if="currentConsumption < 0">
            <div class="headline font-weight-bold">
              {{ -currentConsumption }}
              <div class="title">kW</div>
            </div>
            <div class="subheader pt-4">Current production</div>          
          </v-card-text>
          <v-card-text  v-else>
            <div class="headline font-weight-bold">
              {{ currentConsumption }}
              <div class="title">kW</div>
            </div>        
            <div class="subheader pt-4">Current consumption</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 170" class="elevation-4 text-center pt-3">
          <v-card-text v-if="consumptionMonth < 0">
            <div class="headline font-weight-bold">
              {{ -consumptionMonth }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">Current Month Production</div>
          </v-card-text>
          <v-card-text v-else>
            <div class="headline font-weight-bold">
              {{ consumptionMonth }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">Current Month Consumption</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-card
          style="min-height: 170px"
          class="elevation-4 text-center pt-3"
        >
          <v-card-text v-if="consumptionMonthEstimate < 0">
            <div class="headline font-weight-bold">
              {{ -consumptionMonthEstimate }}
              <div class="title">kWh</div>
            </div>       
            <div class="subheader pt-4">Estimate Current Month Production</div>
          </v-card-text>
          <v-card-text v-else>
            <div class="headline font-weight-bold">
              {{ consumptionMonthEstimate }}
              <div class="title">kWh</div>
            </div>
             <!--<br />               
              <div class="headline font-weight-bold">
                {{consumptionMonthEstimateCosts}}
                <div class="title">Euro Net</div>
            </div>-->
            <div class="subheader pt-4">Estimate Current Month Consumption</div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-card
          style="min-height: 170px"
          class="elevation-4 text-center pt-3"
        >
          <v-card-text v-if="consumptionLastMonth < 0">
            <div class="headline font-weight-bold">
              {{ -consumptionLastMonth }}
              <div class="title">kWh</div>
            </div>
            <!--<br />
              <div class="headline font-weight-bold">
                {{consumptionLastMonthEstimateCosts}}
              <div class="title">Euro Net</div>
            </div>-->
            <div class="subheader pt-4">Last Month Production</div>
          </v-card-text>
          <v-card-text v-else>
            <div class="headline font-weight-bold">
              {{ consumptionLastMonth }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">Last Month Consumption</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  v-if="event_first2 > 0">
        <v-card
          style="min-height: 170px"
          class="elevation-4 text-center pt-3"
        >
          <v-card-text>
            <div class="headline font-weight-bold">
              {{ event_first2 }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">
              Meter Reading Production on
              <br />
              {{ startDate | moment("DD.MM.YYYY") }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  v-if="event_first > 0">
        <v-card
          style="min-height: 170px"
          class="elevation-4 text-center pt-3"
        >
          <v-card-text>
            <div class="headline font-weight-bold">
              {{ event_first }}
              <div class="title">kWh</div>
            </div>
            <div class="subheader pt-4">
              Meter Reading Consumption on
              <br />
              {{ startDate | moment("DD.MM.YYYY") }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card
          style="min-height: 170px"
          class="elevation-4 text-center pt-3"
        >
          <v-card-text style="word-break: break-word">
            <div class="headline font-weight-bold">
              {{ device_location }}
            </div>

            <div class="subheader pt-4">Meter number</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row
      style="min-height: 50px"
      v-if="device.values"
    >
      <v-col  md="4" cols="12"  >
        <v-card style="min-height: 200px">
          <v-toolbar card prominent class="lightgrey">
            <v-spacer></v-spacer>

            <v-btn
              text
              color="accent"
              @click="
                dayOfWeekChart.filterAll();
                dc.redrawAll();
              "
              >reset</v-btn
            >
            <v-menu bottom left>
              <v-btn v-on="on" icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
              <v-list>
                <v-list-item @click="downloadCSV(groups.weekOfYearGroup.all())">
                  <v-list-item-title>Export to CSV...</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-card-text>
            <v-row class="align-center" style="min-height: 100px">
              <div id="day-of-week-chart"></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  md="4" cols="12"  >
        <v-card style="min-height: 200px">
          <v-toolbar card prominent class="lightgrey">
            <v-spacer></v-spacer>
            <v-btn
              text
              color="accent"
              @click="
                weekOfYearChart.filterAll();
                dc.redrawAll();
              "
              >reset</v-btn
            >
            <v-btn icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="align-center" style="min-height: 100px">
              <div id="week-of-year-chart"></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  cols="12" >
        <v-card style="min-height: 200px">
          <v-toolbar card prominent class="lightgrey">
            <v-select
              hide-details
              label="Select Resolution"
              :items="resolutionOptions"
              v-model="resolution"
              return-object
              @change="changeResolution"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="accent"
              @click="
                volumeChart.filterAll();
                dc.redrawAll();
              "
              >reset</v-btn
            >
            <v-menu bottom left>
              <v-btn v-on="on" icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
              <v-list>
                <v-list-item
                  @click="downloadCSV(monthlyMoveCategoryFilteredData())"
                >
                  <v-list-item-title>Export to CSV...</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <v-card-text>
            <v-row
              class="align-center justify-center"
              style="min-height: 150px"
            >
              <div id="monthly-move-chart"></div>
            </v-row>
            <v-row
              class="align-center justify-center"
              style="min-height: 30px"
            >
              <div id="monthly-volume-chart"></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-alert type="error" dismissible :value="statusDownload === 'failure'"
      >Cannot download csv file</v-alert
    >

    <!--
            <v-col  cols="12" lg="6">
                <v-card>
                    <v-toolbar card prominent class="white">
                        <v-toolbar-title class="title">Per Year</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn textcolor="accent" @click="fluctuationChart.filterAll();dc.redrawAll();">reset</v-btn>
                        <v-btn icon>
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <div ref="kwhy"></div>
                    <v-row class="align-center justify-center" style="min-height: 250px">
                        <div id="fluctuation-chart"></div>
                    </v-row>
                </v-card>
            </v-col>

            <v-col  cols="12" >
                <v-card>
                    <v-toolbar card prominent class="white">
                        <v-spacer></v-spacer>
                        <v-btn textcolor="accent" @click="moveChart.filterAll();moveChart2.filterAll();volumeChart.filterAll();dc.redrawAll();">reset</v-btn>
                        <v-btn icon>
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-row class="align-center justify-center" style="min-height: 250px">
                        <div id="monthly-move-chart"></div>
                    </v-row>
                   <v-row class="align-center justify-center" style="min-height: 250px">
                        <div id="monthly-move-chart2"></div>
                    </v-row>
                    <v-row class="align-center justify-center" style="min-height: 50px">
                        <div id="monthly-volume-chart"></div>
                    </v-row>
                </v-card>
            </v-col>
      -->
    <v-row>
      <v-col  v-if="device" >
        <PowerChart :selectedDevice="device" />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_mqtt" lazy width="390px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Fehler</v-card-title
        >
        <v-card-text>{{ dialog_mqtt_description }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_mqtt = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- {{ device.type_full }}   {{ device.location_full }} -->
  </v-container>
</template>

<script>
//import Plotly from 'plotly.js'
import * as dc from "dc";
import * as d3 from "d3";
import "dc/dc.min.css";
import "../assets/dc.custom.css";
import crossfilter from "crossfilter2";
import { mapActions, mapState } from "vuex";
import { downloadFile } from "../utils/files";
import { time6hour, time15minutes } from "../components/chart/utils";
//import chartDebugValues from "../components/chart/debugValues";
import get from "lodash/get";
import _ from "lodash";
//import api from "../api";
import PowerChart from "../components/PowerChart";

export default {
  name: "homeElectricityMeter",
  components: {
    PowerChart,
  },
  props: {
    parentDevice: {},
  },
  data() {
    return {
      ndx: null,
      autoRefresh: false,
      //deviceType: "",
      //deviceTypeSub: "",
      //devices: [],
      device: {},
      statusDownload: "",
      resolutionAllOptions: ["1d", "6h", "1h", "15m"],
      resolution: "1d",
      modals: {
        startDate: false,
        endDate: false,
      },
      startDate: void 0,
      endDate: void 0,
      maxDate: void 0,
      dc: dc,
      window: {
        width: 0,
        height: 0,
      },
      kWhPrice: 0.052 + 0.09,
      dialog_mqtt: false,
      dialog_mqtt_description:
        "Das Gerät hat auf Ihren Befehl nicht reagiert. Versuchen Sie es erneut oder führen Sie vorher einen Awake aus und warten etwas (5sec - 2min).",
      loadingEvent: false,
    };
  },

  mounted() {
    //this.initData();
    //console.log(this.parentDevice);
  },
  computed: {
    ...mapState({
      statusMySelf: ({ auth }) => auth.statusMySelf,
      Global: ({ global }) => global.Global,
      GlobalAll: ({ global }) => global.GlobalAll,
      //powerDevices: ({ device }) => device.powerDevices,
      powerDevicesOnly: ({ device }) => device.powerDevicesOnly,
      powerDevice: ({ device }) => device.powerDevice,
      powerDeviceDebugValues: ({ device }) => device.debugValues,
      mqttResponse: ({ device }) => device.response,
      allEvents: ({ event }) => event.events,      
      Locations: ({ location }) => location.Locations,
    }),
    events: function () {
      // return this.allEvents.slice(16);
      return this.allEvents;
    },
    resolutionOptions: function () {
      return this.resolutionAllOptions;
    },
    meterReading() {
      //console.log("Typeof: ", typeof this.device.values, this.device.values);
      if (typeof this.device.values !== "undefined") {
        return this.device.values.total.toFixed(1);
      } else {
        return "N/A";
      }
    },
    meterReadingDay() {
      if (typeof this.device.values !== "undefined") {
        return (this.device.values.day / 1000).toFixed(1);
      } else {
        return "N/A";
      }
    },
    meterReadingNight() {
      if (typeof this.device.values !== "undefined") {
        return (this.device.values.night / 1000).toFixed(0);
      } else {
        return "N/A";
      }
    },
    meterReading2() {
      //console.log("Typeof: ", typeof this.device.values, this.device.values);
      if (typeof this.device.values !== "undefined") {
        return this.device.values.total2.toFixed(1);
      } else {
        return "N/A";
      }
    },
    meterReadingDay2() {
      if (typeof this.device.values !== "undefined") {
        return (this.device.values.day2 / 1000).toFixed(1);
      } else {
        return "N/A";
      }
    },
    meterReadingNight2() {
      if (typeof this.device.values !== "undefined") {
        return (this.device.values.night2 / 1000).toFixed(0);
      } else {
        return "N/A";
      }
    },
    consumptionMonth() {
      if (typeof this.device.values !== "undefined") {
        return this.device.values.currentMonth.toFixed(0);
      } else {
        return "N/A";
      }
    },
    consumptionMonthEstimate() {
      if (typeof this.device.values !== "undefined") {
        return this.device.values.estimateMonth.toFixed(0);
      } else {
        return "N/A";
      }
    },
    consumptionMonthEstimateCosts() {
      if (typeof this.device.values !== "undefined") {
        return (this.device.values.estimateMonth * this.kWhPrice).toFixed(0);
      } else {
        return "N/A";
      }
    },
    consumptionLastMonth() {
      if (typeof this.device.values !== "undefined") {
        return this.device.values.totallastMonth.toFixed(0);
      } else {
        return "N/A";
      }
    },
    consumptionLastMonthEstimateCosts() {
      if (typeof this.device.values !== "undefined") {
        return (this.device.values.totallastMonth * this.kWhPrice).toFixed(0);
      } else {
        return "N/A";
      }
    },
    // productionMonth() {
    //   if (typeof this.device.values !== "undefined") {
    //     return this.device.values.currentMonth2.toFixed(0);
    //   } else {
    //     return "N/A";
    //   }
    // },
    // productionMonthEstimate() {
    //   if (typeof this.device.values !== "undefined") {
    //     return this.device.values.estimateMonth2.toFixed(0);
    //   } else {
    //     return "N/A";
    //   }
    // },
    // productionMonthEstimateCosts() {
    //   if (typeof this.device.values !== "undefined") {
    //     return (this.device.values.estimateMonth2 * this.kWhPrice).toFixed(0);
    //   } else {
    //     return "N/A";
    //   }
    // },
    // productionLastMonth() {
    //   if (typeof this.device.values !== "undefined") {
    //     return this.device.values.totallastMonth2.toFixed(0);
    //   } else {
    //     return "N/A";
    //   }
    // },
    // productionLastMonthEstimateCosts() {
    //   if (typeof this.device.values !== "undefined") {
    //     return (this.device.values.totallastMonth2 * this.kWhPrice).toFixed(0);
    //   } else {
    //     return "N/A";
    //   }
    // },
    currentConsumption() {
      if (typeof this.device.values !== "undefined") {
        const kW = this.device.values.current_consumption / 1000;
        return kW.toFixed(2);
      } else {
        return "N/A";
      }
    },
    event_first() {
      try {
        return (this.events[0].event_first.value_total / 1000).toFixed(0);
      } catch {
        return "N/A";
      }
    },
    event_first2() {
      try {
        return (this.events[0].event_first.value_total2 / 1000).toFixed(0);
      } catch {
        return "N/A";
      }
    },
    device_location() {
      try {
        return _.find(this.Locations, { _id: this.device.location }).name;
      } catch {
        return "N/A";
      }
    },
    /*globalDeviceType() {
      const query = _.filter(this.GlobalAll, {
        typeOf: "deviceTypes",
        parent: null,
      });
      return query;
    },
    globalDeviceTypeSub() {
      //ToDo: Change erverything to ID Selection
      let query = _.find(this.GlobalAll, {
        typeOf: "deviceTypes",
        parent: null,
        value: this.deviceType,
      });
      query = _.filter(this.GlobalAll, {
        typeOf: "deviceTypes",
        parent: query._id,
      });
      //Watch does not work
      this.deviceTypeSub = query[0].value;
      return query;
    },*/
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.IntervalOff();
  },
  methods: {
    ...mapActions("device", [
      //        "getPowerDevices",
      "getPowerDevicesOnly",
      "getDevice",
      "getDeviceDebugValues",
      "setDevice",
    ]),
    ...mapActions("event", ["getDeviceEvents"]),
    ...mapActions("global", ["getGlobal", "getGlobalAll"]),
    ...mapActions("location", ["getLocation"]),

    /* async initData() {
      await this.getGlobal({});
      await this.getGlobalAll({});
      await this.getLocation({});
      this.deviceType = "heat";
      //this.deviceType = "power"
    },*/
    // async loadDebugValues(device) {
    //   //console.log("LoadDebugValues " + device);
    //   await this.getDeviceDebugValues(device);
    // },
    async loadEvent() {
      // console.log("LOAD EVENT 0 ", this.loadingEvent);
      try {
        if (!this.loadingEvent) {
          this.loadingEvent = true;
          // console.log("LOAD EVENT 1 ", this.parentDevice, this.Global.locationTypes, this.Locations);
          
          //this.device = {};
          this.parentDevice.from = this.startDate;
          await this.getDevice(this.parentDevice);

          const loc = this.Locations.find((obj) => {
            return obj._id == this.powerDevice.location;
          })    
          const loc_type = this.Global.locationTypes.find((obj) => {
            return obj._id == loc.type;
          })

          this.powerDevice.location_full = loc;
          this.powerDevice.location_full.type_full = loc_type;
      
          
          this.device = this.powerDevice;
          // console.log("LOAD EVENTS 2-2 ", this.device);
          await this.getDeviceEvents({
            deviceId: this.device._id,
            // days: 1
            startDate: this.startDate,
            endDate: this.endDate,
            apexChart: true,
            view: "day"
          });
          // //console.log("LOAD EVENT 3 ", this.parentDevice);
          // this.changeResolution(this.resolution);
          //console.log("LOAD EVENT 4 ", this.parentDevice);
          this.loadingEvent = false;
        }
      } catch (err) {
        console.log(err);
        this.loadingEvent = false;
      }
    },
    downloadCSV: async function (data) {
      //https://github.com/d3/d3-dsv#dsv_format

      let blob = new Blob([d3.csvFormat(data)], {
        type: "text/csv;charset=utf-8",
      });

      const fileName = `${this.device.name || "device"}.csv`;

      try {
        await downloadFile(blob, fileName, "text/csv;charset=utf-8");
      } catch (e) {
        this.statusDownload = "failure";
        await setTimeout(() => {
          this.statusDownload = "";
        }, 5000);
      }
    },
    monthlyMoveCategoryFilteredData: function () {
      const columns = {
        _id: "date",
        total: this.device.type + " sum",
      };
      const data = [];
      this.ndx.allFiltered().map((d) => {
        const row = {};
        for (let key in columns) {
          row[columns[key]] = d[key];
        }
        data.push(row);
      });
      return data;
    },
    clearCharts: function () {
      this.volumeChart.filterAll();
      this.compositeChart.filterAll();
      this.dayOfWeekChart.filterAll();
      this.weekOfYearChart.filterAll();
      this.ndx.remove();
    },
    rebuildCrossFilterWithEvents: function (data, device) {
    
      if (data[0]) {
        //console.log(`rebuild ${JSON.stringify(data[0])} and ${data.length} more...`);
        data.forEach(function (d) {
          d.dd = new Date(d._id);
          d.month = d3.timeMonth(d.dd);
          d.week = d3.timeWeek(d.dd);
          d.day = d3.timeDay(d.dd);
          d.hour = d3.timeHour(d.dd);
          d.minute = d3.timeMinute(d.dd);
          d.category = device.type;
          // d.total_negative = d.total_positive;
          // d.total = d.total * 0.5;
        });

        //filter out invalid dates
        const maxDate = new Date().getTime();
        const filtered = data.filter((d) => d.dd.getTime() < maxDate);

        this.ndx.add(filtered);
        

      }
    },
    changeResolution: function (resolution) {
      const cb = [
        // callback for when data is added to the current filter results
        (p, v) => {
          p.sum += v.total;
          p.sum_positive += v.total_positive;
          p.sum_negative += v.total_negative;
          return p;
        },
        // callback for when data is removed from the current filter results
        (p, v) => {
          p.sum -= v.total;
          p.sum_positive -= v.total_positive;
          p.sum_negative -= v.total_negative;
          return p;
        },
        // callback for initialize p
        () => ({
          sum: 0,
          sum_positive: 0,
          sum_negative: 0,
        }),
      ];

      const children = this.compositeChart.children();

      let timeInterval = d3.timeDay;
      let keyAccessor = (d) => d.key.toLocaleDateString();

      switch (resolution) {
        case "15m":
          timeInterval = time15minutes;
          keyAccessor = (d) => d.key.toLocaleTimeString();
          break;
        case "1h":
          timeInterval = d3.timeHour;
          keyAccessor = (d) => d.key.toLocaleTimeString();
          break;
        case "6h":
          timeInterval = time6hour;
          keyAccessor = (d) => d.key.toLocaleTimeString();
          break;
        case "1d":
          timeInterval = d3.timeDay;
          break;
        default:
          timeInterval = d3.timeDay;
          break;
      }

      this.groupFunc = (d) => timeInterval(d);
      this.groups.monthlyMoveCategoryGroup.dispose();
      this.groups.monthlyMoveCategoryGroup = this.dimensions.moveMonths
        .group(this.groupFunc)
        .reduce(cb[0], cb[1], cb[2]);

      // const xUnits = (start, end) => d3.timeMinute.range(start, end, 15);

      this.compositeChart.round(timeInterval.round).xUnits(timeInterval.range);
      this.compositeChart.keyAccessor((d) => d.key.toLocaleTimeString());

      this.volumeChart.group(this.groups.monthlyMoveCategoryGroup, "Power");
      this.volumeChart.round(timeInterval.round).xUnits(timeInterval.range);

      children[0].group(this.groups.monthlyMoveCategoryGroup, "Consumption");
      children[1].group(this.groups.monthlyMoveCategoryGroup, "Production");
      children[2].group(this.groups.monthlyMoveCategoryGroup, "Total");

      this.volumeChart.render();
      this.compositeChart.render();
    },
    applyDateRange() {
      const firstDate = new Date(this.startDate);
      const lastDate = new Date(this.endDate);
      this.compositeChart.x(d3.scaleTime().domain([firstDate, lastDate]));
      this.volumeChart.x(d3.scaleTime().domain([firstDate, lastDate]));

      dc.redrawAll();
    },
    handleResize() {
      //this.clearCharts();
      let resize_factor = 0;
      let resize_factor2 = 0;
      if (this.window.width < 600) {
        resize_factor = 0.5;
        resize_factor2 = 0.6;
      } else if (this.window.width >= 600 && this.window.width < 960) {
        resize_factor = 0.3;
        resize_factor2 = 0.8;
      } else {
        resize_factor = 0.25;
        resize_factor2 = 0.8;
      }
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.compositeChart.width(this.window.width * resize_factor2);
      this.compositeChart.legend(
        dc
          .legend()
          .x(this.window.width - this.window.width * (1 - resize_factor2) - 100)
          .y(10)
          .itemHeight(13)
          .gap(5)
      );
      this.volumeChart.width(this.window.width * resize_factor2);
      this.dayOfWeekChart.width(this.window.width * resize_factor);
      this.weekOfYearChart.width(this.window.width * resize_factor);
      dc.renderAll();
    },
    async flowYChange() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setFlowSAL",
        message: Number(this.device.debug.f_sal61y),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.dialog_mqtt = true;
      }
    },
    async flowRelais1Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "1," + Number(this.device.debug.f_relais_1),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_1 = !this.device.debug.f_relais_1;
        this.dialog_mqtt = true;
      }
    },
    async flowRelais2Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "2," + Number(this.device.debug.f_relais_2),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_2 = !this.device.debug.f_relais_2;
        this.dialog_mqtt = true;
      }
    },
    async flowRelais3Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "3," + Number(this.device.debug.f_relais_3),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_3 = !this.device.debug.f_relais_3;
        this.dialog_mqtt = true;
      }
    },
    async flowRelais4Change() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setRelais",
        message: "4," + Number(this.device.debug.f_relais_4),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_relais_4 = !this.device.debug.f_relais_4;
        this.dialog_mqtt = true;
      }
    },
    async flowManual() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "setFlowManual",
        message: Number(this.device.debug.f_manual),
        qos: 0,
      });
      if (this.mqttResponse.status == false) {
        this.device.debug.f_manual = !this.device.debug.f_manual;
        this.dialog_mqtt = true;
      }
    },
    async awakeDevice() {
      await this.setDevice({
        device: this.device.device_id,
        topic: "awake",
        message: 30,
        qos: 2,
      });
    },
    /*async reloadDevice() {
      await this.getDeviceDebug();
    },*/
    async setAutoRefresh() {
      /*await this.reloadDevice()
      //ToDo: Change to single load
      await this.getPowerDevicesOnly({type: this.deviceTypeSub})
      this.devices = this.powerDevicesOnly
      for (let device of this.devices) {
        if (device.device_id === this.device.device_id) {
          this.device = device
        }
      }*/
      //console.log(JSON.stringify(this.device))
      //await this.loadEvent(this.device);

      //this.$forceUpdate();

      if (this.autoRefresh) {
        //this.setAutoRefreshInt = setInterval(async () => {
        this.$store.myInterval = setInterval(async () => {
          //console.log("electricity Meter Interval");
          this.getDeviceDebug();
        }, 5000);
      } else {
        this.IntervalOff();
      }
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.myInterval)) {
          clearInterval(this.$store.myInterval);
          this.$store.myInterval = undefined;
          this.autoRefresh = false;
        }
      } catch (e) {
        //
      }
    },

    async getDeviceDebug() {
      // await this.setDevice({
      //   device: this.device.device_id,
      //   topic: "getDetails",
      //   message: 1,
      //   qos: 0,
      // });
      // if (this.mqttResponse.status == false) {
        this.$emit("refreshDevice");
        // await this.getDevice(this.device);
        // this.device = this.powerDevice;
      // } else {
      //   try {
      //     this.device.debug = JSON.parse(this.mqttResponse.status);
      //   } catch (e) {
      //     //
      //   }
      //}
    },

    async initCharts() {
      // ### Create Chart Objects

      // Create chart objects associated with the container elements identified by the css selector.
      // Note: It is often a good idea to have these objects accessible at the global scope so that they can be modified or
      // filtered by other page controls.
      this.ndx = null;
      this.ndx = crossfilter();

      const dataCount = dc.dataCount("#data-count");
      this.dataCount = dataCount;

      const dayOfWeekChart = dc.rowChart("#day-of-week-chart");
      this.dayOfWeekChart = dayOfWeekChart;

      const weekOfYearChart = dc.rowChart("#week-of-year-chart");
      this.weekOfYearChart = weekOfYearChart;

      const compositeChart = dc.compositeChart("#monthly-move-chart");
      this.compositeChart = compositeChart;

      const volumeChart = dc.barChart("#monthly-volume-chart");
      this.volumeChart = volumeChart;

      //const debugValuesChart = dc.lineChart("#debug-values-chart");
      //this.debugValuesChart = debugValuesChart;

      const ndx = this.ndx;

      const all = ndx.groupAll();
      // const allusage = ndx.groupAll().reduceSum(function(d) {
      //   return d.usage;
      // });

      this.dataCount.dimension(ndx).group(all).html({
        some: "%filter-count out of %total-count records selected",
        all: "All records selected. Click on charts to apply filters",
      });

      // Dimension by full date
      // const dateDimension = ndx.dimension(d => d.dd); // Dimension by month

      /*                // Dimension by month
                  var moveMonths = ndx.dimension(function (d) {
                      return d.month;
                  });
  */ const moveMonths = ndx.dimension((d) => d.minute); // Group by total movement within month
      /* var monthlyMoveGroup = moveMonths.group().reduceSum(function (d) {
                      return d.usage;
                  });
  */
      this.groupFunc = (d) => d3.timeDay(d);
      const monthlyMoveCategoryGroup = moveMonths.group(this.groupFunc).reduce(
        // callback for when data is added to the current filter results
        (p, v) => {
          p.sum += v.total;
          p.sum_positive += v.total_positive;
          p.sum_negative += v.total_negative;
          return p;
        },
        // callback for when data is removed from the current filter results
        (p, v) => {
          p.sum -= v.total;
          p.sum_positive -= v.total_positive;
          p.sum_negative -= v.total_negative;

          return p;
        },
        // callback for initialize p
        () => ({
          sum: 0,
          sum_positive: 0,
          sum_negative: 0,
        })
      );

      // Create categorical dimension
      // const category = ndx.dimension(d => {
      //   return d.category;
      // });

      // Produce counts records in the dimension
      // const categoryGroup = category.group().reduceSum(function(d) {
      //   return d.usage;
      // });

      // Counts per weekday
      const dayOfWeek = ndx.dimension((d) => {
        const day = d.dd.getDay();
        const name = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return day + "." + name[day];
      });
      const dayOfWeekGroup = dayOfWeek.group();

      const weekOfYear = ndx.dimension((d) => {
        const week = d3.timeMonday(d.week);
        const yearStart = new Date(Date.UTC(week.getUTCFullYear(), 0, 1));
        return Math.ceil(((week - yearStart) / 86400000 + 1) / 7);
      });
      const weekOfYearGroup = weekOfYear.group();

      this.dimensions = {
        dayOfWeek,
        weekOfYear,
        moveMonths,
      };

      this.groups = {
        dayOfWeekGroup,
        weekOfYearGroup,
        monthlyMoveCategoryGroup,
      };

      //### Define Chart Attributes
      // Define chart attributes using fluent methods. See the
      // [dc.js API Reference](https://github.com/dc-js/dc.js/blob/master/web/docs/api-latest.md) for more information
      //

      //#### Row Chart

      // Create a row chart and use the given css selector as anchor. You can also specify
      // an optional chart group for this chart to be scoped within. When a chart belongs
      // to a specific group then any interaction with such chart will only trigger redraw
      // on other charts within the same chart group.
      // <br>API: [Row Chart](https://github.com/dc-js/dc.js/blob/master/web/docs/api-latest.md#row-chart)
      dayOfWeekChart /* dc.rowChart('#day-of-week-chart', 'chartGroup') */
        .width(0)
        .height(180)
        .margins({ top: 20, left: 10, right: 10, bottom: 20 })
        .group(dayOfWeekGroup)
        .dimension(dayOfWeek)
        //.group(weekOfYearGroup)
        //.dimension(weekOfYear)
        // Assign colors to each value in the x scale domain
        .ordinalColors(["#3182bd", "#6baed6", "#9ecae1", "#c6dbef", "#dadaeb"])
        .label(function (d) {
          return d.key.split(".")[1];
        })
        // Title sets the row text
        .title(function (d) {
          return d.value;
        })
        .elasticX(true)
        .xAxis()
        .ticks(4);

      weekOfYearChart
        .width(0)
        .height(180)
        .margins({ top: 20, left: 10, right: 10, bottom: 20 })
        .group(weekOfYearGroup)
        .dimension(weekOfYear)
        // Assign colors to each value in the x scale domain
        .ordinalColors(["#3182bd", "#6baed6", "#9ecae1", "#c6dbef", "#dadaeb"])
        .label(function (d) {
          return d.key + ". Week ";
        })
        // Title sets the row text
        .title(function (d) {
          return d.key;
        })
        .ordering(function (d) {
          return d.key;
        })
        .elasticX(true)
        .xAxis()
        .ticks(4);

      //#### Stacked Area Chart

      const dateFormatSpecifier = "%Y-%m-%dT%H:%M";
      const dateFormat = d3.timeFormat(dateFormatSpecifier);
      // const dateFormatParser = d3.timeParse(dateFormatSpecifier);
      const numberFormat = d3.format(".2f");

      const nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      const pastDate = new Date(
        nowDate.getFullYear() - 1,
        nowDate.getMonth(),
        nowDate.getDate()
      );

      /*let resize_factor = 0
      if (this.window.width < 600) {
        resize_factor = 80
      }
      else if (this.window.width >= 600 && this.window.width < 960) {
        resize_factor = 65
      }
      else {
        resize_factor = 50
      }*/

      compositeChart
        .width(0)
        .height(200)
        .margins({ top: 30, right: 50, bottom: 25, left: 40 })
        //.mouseZoomable(true)
        // Specify a "range chart" to link its brush extent with the zoom of the current "focus chart".
        .rangeChart(volumeChart)
        .x(d3.scaleTime().domain([pastDate, nowDate]))
        .round(d3.timeHour.round) //selection
        .xUnits(d3.timeHours)
        //.xUnits(d3.timeDays)
        .elasticY(true)
        .renderHorizontalGridLines(true)
        .renderVerticalGridLines(true)
        //##### Legend
        // Position the legend relative to the chart origin and specify items' height and separation.
        .legend(dc.legend().x(960).y(10).itemHeight(13).gap(5))
        .brushOn(false)
        .compose([
          dc
            .barChart(compositeChart)
            .dimension(moveMonths)
            // .centerBar(true)
            .group(monthlyMoveCategoryGroup, "positive")
            .ordinalColors(["#ffdf14"])
            .valueAccessor(function (d) {
              return d.value.sum_positive;
            }),
          dc
            .barChart(compositeChart)
            .dimension(moveMonths)
            .ordinalColors(["#339933"])
            // .centerBar(true)
            .group(monthlyMoveCategoryGroup, "Production")
            .valueAccessor(function (d) {
              return d.value.sum_negative;
            }),
          dc
            .lineChart(compositeChart)
            .dimension(moveMonths)
            .group(monthlyMoveCategoryGroup, "total")
            .ordinalColors(["#14a8ff"])
            .valueAccessor(function (d) {
              return d.value.sum;
            }),
        ])
        .renderTitle(true)
        .title(function (d) {
          return (
            dateFormat(d.key) + "\n" + "Power:" + numberFormat(d.value.sum)
          );
        })
        .keyAccessor((d) => {
          return d.key.toLocaleDateString();
        })
        .valueAccessor((d) => {
          return [
            "",
            "Total: " + numberFormat(d.value.sum),
            "Consumption: " + numberFormat(d.value.sum_positive),
            "Production: " + numberFormat(-d.value.sum_negative),
          ].join("\n");
        })
        .xAxis()
        .ticks(this.window.width / 70 - 1);

      //#### Range Chart

      // Since this bar chart is specified as "range chart" for the area chart, its brush extent
      // will always match the zoom of the area chart.
      volumeChart
        .width(0)
        .height(50)
        .elasticY(true)
        .margins({ top: 0, right: 50, bottom: 20, left: 40 })
        .dimension(moveMonths)
        .group(monthlyMoveCategoryGroup, "power")
        .valueAccessor(function (d) {
          return d.value.sum;
        })
        // .centerBar(true)
        .gap(1)
        .x(d3.scaleTime().domain([pastDate, nowDate]))
        .round(d3.timeHour.round) //selection
        .xUnits(d3.timeHours)
        // .round(d3.timeDay.round)
        //.xUnits(d3.timeDays)
        .alwaysUseRounding(true)
        .transitionDuration(0)
        .xAxis()
        .ticks(this.window.width / 70 - 1);
      //.yAxis().ticks(0);

      /* lineChart
        .width(768)
        .height(480)
        .x(d3.scaleLinear().domain([0,20]))
        .curve(d3.curveStepBefore)
        .renderArea(true)
        .brushOn(false)
        .renderDataPoints(true)
        .clipPadding(10)
        .yAxisLabel("This is the Y Axis!")
        .dimension()
        .group();
*/
      //#### Rendering

      //render all charts on the page
      dc.renderAll();

      this.changeResolution(this.resolution);

      /*
        // render charts belonging to a specific chart group
        dc.renderAll('group');
        // update charts incrementally when the data changes, without re-rendering everything
        dc.redrawAll();
        // redraw only those charts associated with a specific chart group
        dc.redrawAll('group');
      */

      //#### Versions

      //Determine the current version of dc with `dc.version`
      d3.selectAll("#version").text(dc.version);

      // Determine latest stable version in the repo via Github API

      d3.json("https://api.github.com/repos/dc-js/dc.js/releases/latest").then(
        function (latestRelease) {
          //jshint camelcase: false
          //jscs:disable
          d3.selectAll("#latest").text(latestRelease.tag_name);
        }
      );

      /*
              Plotly.plot(
                  this.$refs.kwhy,
                  [{
                      x: ['01.01.2018', '01.02.2018', '01.03.2018', '01.04.2018', '01.05.2018', '01.06.2018', '01.07.2018', '01.08.2018', '01.09.2018', '01.10.2018'],
                      y: [0, 0, 0, 100, 24, 21, 13, 5, 0],
                      type: 'scatter',
                      line: {
                          color: '#2196F3'
                      }
                  }], {
                      font: {
                          family: 'Roboto',
                          size: 14,
                          color: 'black'
                      },
                      xaxis: {
                          title: 'Year'
                      },
                      yaxis: {
                          title: 'kWh'
                      }
                  }
              ),
              Plotly.plot(
                  this.$refs.kwhd,
                  [{
                      x: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                      y: [23, 24, 20, 40, 30, 23, 22, 8, 11],
                      type: 'scatter',
                      line: {
                          color: '#2196F3'
                      }
                  }], {
                      color: '#000',
                      font: {
                          family: 'Roboto',
                          size: 12,
                          color: 'grey'
                      },
                      xaxis: {
                          title: 'Day'
                      },
                      yaxis: {
                          title: 'kWh'
                      }
                  }
              ),
              Plotly.plot(
                  this.$refs.kwhh,
                  [{
                      x: ['11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'],
                      y: [27, 34, 35, 38, 33, 5, 4, 15, 15],
                      type: 'scatter',
                      line: {
                          color: '#2196F3'
                      }
                  }], {
                      color: '#000',
                      font: {
                          family: 'Roboto',
                          size: 12,
                          color: 'grey'
                      },
                      xaxis: {
                          title: 'Hour'
                      },
                      yaxis: {
                          title: 'kWh'
                      }
                  }
              )
              */
      this.handleResize();
    },
  },

  watch: {
    /*statusMySelf() {
      this.IntervalOff();
    },*/

    parentDevice() {
      //console.log("parent Device");
      this.loadEvent();
    },

    startDate() {
      // this.applyDateRange();
      //console.log("Start Date");
      this.loadEvent();
    },
    endDate() {
      // this.applyDateRange();
      //console.log("end Date");
      this.loadEvent();
    },
    events(val) {
      // console.log("EVENTS CHARTS");
      // //this.$store.commit('SET_LOADING', true)
      // this.initCharts();
      // this.clearCharts();

      // // // this.maxDate = void 0;
      // this.rebuildCrossFilterWithEvents(val, this.device);
      // const items = this.ndx.all();

      // if (items.length > 0) {
      //   const firstDate = new Date(items[0]._id);
      //   const lastDate = new Date(items[items.length - 1]._id);

      //   this.compositeChart.x(d3.scaleTime().domain([firstDate, lastDate]));
      //   this.volumeChart.x(d3.scaleTime().domain([firstDate, lastDate]));

      //   // this.startDate = firstDate.toISOString().slice(0, 10);
      //   // this.endDate = lastDate.toISOString().slice(0, 10);
      //   this.maxDate = new Date().toISOString().slice(0, 10); //this.endDate;

      //   const days =
      //     (new Date(this.endDate) - new Date(this.startDate)) / 864e5;

      //   if (days <= 2) {
      //     this.resolutionAllOptions = ["15m", "1h", "1d"];
      //   } else if (days <= 7) {
      //     this.resolutionAllOptions = ["1h", "6h", "1d"];
      //   } else if (days <= 92) {
      //     this.resolutionAllOptions = ["6h", "1d"];
      //   } else {
      //     this.resolutionAllOptions = ["1d"];
      //   }

      //   this.resolution = this.resolutionAllOptions[0];
      // }
      // dc.renderAll();
      // dc.redrawAll();
      //this.$store.commit('SET_LOADING', false)

    },
  },

  created: function () {
    window.addEventListener("resize", this.handleResize);
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;

    // this.ndx = crossfilter();

    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 7 * 1000 * 86400);

    this.startDate = startDate.toISOString().slice(0, 10);
    this.endDate = endDate.toISOString().slice(0, 10);
    this.maxDate = this.endDate;
  },
};
</script>

<!--
<style>
    .js-plotly-plot {
        max-width: 100%;
    }
</style>
-->
