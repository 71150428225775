import api from "../../../api";

export default {
  // async getDeviceEvents({ commit }, { deviceId, days, startDate, endDate }) {
  //   commit("REQUEST_DEVICE_EVENTS");
  //   commit("SET_LOADING", true, { root: true });
  //   try {
  //     commit(
  //       "SUCCESS_REQUEST_DEVICE_EVENTS",
  //       await api.getDeviceEvents({ deviceId, days, startDate, endDate })
  //     );
  //   } catch (err) {
  //     if (!err.ok && err.status === 401) {
  //       commit("TO_LOGIN", true, { root: true });
  //     }
  //     commit("FAILURE_REQUEST_DEVICE_EVENTS");
  //   } finally {
  //     commit("SET_LOADING", false, { root: true });
  //   }
  // },
  async getLocationRawEventsIndex({ commit }, { deviceId, days, startDate, endDate }) {
    commit("REQUEST_LOCATION_RAW_EVENTS_INDEX");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_LOCATION_RAW_EVENTS_INDEX",
        await api.getLocationRawEventsIndex({ deviceId, days, startDate, endDate })
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_LOCATION_RAW_EVENTS_INDEX");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }

};
