<template>
  <v-container pa-0 ma-0  fluid>
    <v-card>
      <v-row >
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">Refresh</div>
          <v-btn small icon color="accent" @click="getDeviceDebug()">
            <v-icon>refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col   cols="12" sm="2"  py-0 ma-1 left>
          <div class="body-2">AutoRefresh</div>
          <v-switch
            height="0"
            v-model="autoRefresh"
            color="blue"
            @change="setAutoRefresh()"
            :label="autoRefresh.toString()"
          ></v-switch>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">DeviceID:</div>
          <div class="body-1">{{ device_local.device_id }}</div>
        </v-col>
        <v-col   cols="12" sm="2"  py-2 ma-1 left>
          <div class="body-2">Alive:</div>
          <div class="body-1">{{ device_local.alive | moment("DD.MM HH:mm:ss") }}</div>
        </v-col>
      </v-row>
    </v-card>

    <br />
    <v-row style="min-height: 50px" >
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Seriennummer</div>
            <div class="headline font-weight-bold pt-3">
              {{ getSerNr }}
            </div>
            <!-- <div class="subheader pt-3">S/N</div> -->
            <!-- <div class="subheader" pt-2>Seriennummer von Modul</div> -->
            <div  class="subheader pt-3">
              Nr. von Modul <br/>
              {{ device_local.raw.h_sernr }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Wärme</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_energy1 / 1000).toFixed(2) }} kWh
            </div>
            <div class="subheader pt-3">meter reading</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Kälte</div>
            <div  class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_energy3 / 1000).toFixed(2) }} kWh
            </div>
            <div class="subheader pt-3">meter reading</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Volumen</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_volume1 / 1000).toFixed(2) }} m³
            </div>
            <div class="subheader pt-3">meter readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Vorlauf</div>
            <div  class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_temp1 / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Rücklauf</div>
            <div  class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_temp2 / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Temp Diff</div>
            <div  class="headline font-weight-bold pt-3">
              {{ ((device_local.raw.h_temp1 - device_local.raw.h_temp2) / 1000).toFixed(2) }} °C
            </div>
            <div class="subheader pt-3">actual temperatures</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Durchfluss</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_flow1 / 1000).toFixed(3) }} m³/h
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  >
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>Leistung</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.h_power1 / 1000).toFixed(2) }} kW
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Only for ESTMK Teststellung -> ToDo: Virtual Devices -->
      <v-col  md="2" cols="12"  v-if="typeof device_local.raw.cop != 'undefined'"> 
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>COP WP</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.cop).toFixed(2) }}
            </div>
            <div class="subheader pt-3">actual calculation</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  md="2" cols="12"  v-if="device_local.raw.em_Pp"> 
        <v-card style="min-height: 130px" class="elevation-4 text-center">
          <v-card-text>
            <div class="title" pt-2>EM Power</div>
            <div class="headline font-weight-bold pt-3">
              {{ (device_local.raw.em_Pp / 1000).toFixed(3) }} kW
            </div>
            <div class="subheader pt-3">actual readings</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row style="min-height: 50px">
      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.startDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="Start Date"
              :value="startDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              () => {
                modals.startDate = false;
              }
            "
            min="2020-01-01"
            v-model="startDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.startDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.endDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="End Date"
              :value="endDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              () => {
                modals.endDate = false;
              }
            "
            :min="startDate"
            :max="maxDate"
            v-model="endDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.endDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!-- <v-col  md="2" cols="12"  >
        <v-btn color="#88AA88" block @click="exportXls">Export Data to XLS</v-btn>
      </v-col> -->
    </v-row>

    <apexchart v-if="showChart" ref="myChart" type="line" height="350"  :options="apexChartOptions" :series="apexChartData"></apexchart>
    <!-- <v-row >
      <v-col   >
        <CrossFilterComponent :parentDevice="device"/>
      </v-col>
    </v-row> -->

    <v-row v-if='DeviceIFrame'>
      <v-col  cols="12">
        <iframe
          :src="DeviceIFrame"
          height="600"
          :width="window.width - 60"
        ></iframe>
      </v-col>
    </v-row>
   
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
const ExcelJS = require("exceljs");

//import CrossFilterComponent from "../components/CrossFilterComponent";

export default {
  name: "homeHeatMeter",
  components: {
    //CrossFilterComponent
  },
  props: {
    device: {},
    Locations: null
  },
  data() {
    return {
      device_local: undefined,
      autoRefresh: false,
      loadingEvent: false,
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      modals: {
        startDate: false,
        endDate: false,
      },
      startDate: void 0,
      endDate: void 0,
      maxDate: void 0,

      apexChartOptions: {
        
        theme: {
          mode: this.$vuetify.theme.isDark ? "dark" : "light",
        },
        legend: {
          position: "top"
        },
        colors: [this.$vuetify.theme.energy_primary, this.$vuetify.theme.energy_secondary],
        forecastDataPoints: {
          count: 0,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        },
        chart: {
          // events: {
          //   mounted: function(chart) {
          //     chart.toggleSeries('Power');
          //     chart.toggleSeries('Flow');
          //     chart.toggleSeries('Battery');
          //   }
          // },
          toolbar: {
            show: true,
            export: {
              csv: {
                headerCategory: 'Date',
                columnDelimiter: ',',
                dateFormatter: function(timestamp) {
                  let date = new Date(timestamp);
                  return date.toISOString();
                },
              }
            }
          },
          zoom: {
            enabled: true,
          },
          background: this.$vuetify.theme.background_secondary,
          height: 350,
          type: 'line',
        },
        stroke: {
          width: "1",
         curve: "smooth"
        },
        fill: {
          type:'solid',
          opacity: [0.2, 0.5],
        },
        labels: [],
        markers: {
          size: 0
        },
        yaxis: [
          {
            title: {
              text: 'Value',
            },
          },
          // {
          //   opposite: true,
          //   title: {
          //     text: 'Series B',
          //   },
          // },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
              datetimeUTC: false
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            format: 'dd MMM HH:mm',
            formatter: undefined,
          },
          y: {
            formatter: function (y) {
              // console.log("y: ",y)
              if(typeof y !== "undefined") {
                return  y.toFixed(1);
              }
              return y;
            }
          }
        }
      },
      showChart: true,
      data: {
        h_power1: [],
        bat: [],
        h_flow1: [],
        h_temp1: [],
        h_temp2: [],
        h_volume1: [],
        value_positive: [],
        value_total: [],
        value_negative: [],
        value_total2: [],
        cop: []
      },
    };
  },

  created() {
    // console.log("Created ", this.device);
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 7 * 1000 * 86400);

    this.startDate = startDate.toISOString().slice(0, 10);
    this.endDate = endDate.toISOString().slice(0, 10);
    this.maxDate = this.endDate;


    this.device_local = JSON.parse(JSON.stringify(this.device));
    // for (const [key, value] of Object.entries(this.device_local.raw)) {
    //   this.device_local[key] = value;
    // }

  },

  async mounted() {
    // console.log("Mounted");
    //window.myChart = this.$refs.myChart;
    this.$emit("refreshDevice");
    
    await this.getDeviceIFrame(this.device);
    
    
  },
  destroyed() {
    this.IntervalOff();
  },
  computed: {
    ...mapState({
      DeviceIFrame: ({ device }) => device.iframe,
      allEvents: ({ event }) => event.events,
    }),

    getSerNr() {
      const _sernr = _.find(this.Locations, {_id: this.device.location});
      if (_sernr) {
        return _sernr.name;
      } else {
        return "Not defined";
      }
    },
    apexChartData() {
      return [
        {
          name: 'Power',
          type: 'area',
          data: this.data.h_power1
        }, 
        {
          name: 'Flow',
          type: 'area',
          data: this.data.h_flow1
        },
        {
          name: 'Temp 1',
          type: 'area',
          data: this.data.h_temp1
        },
        {
          name: 'Temp 2',
          type: 'area',
          data: this.data.h_temp2
        },
        {
          name: 'Volume',
          type: 'area',
          data: this.data.h_volume1
        },
        // {
        //   name: '',
        //   type: 'area',
        //   data: this.data.h_volume1
        // },
        {
          name: 'Heat Sum',
          type: 'area',
          data: this.data.value_total
        },
        {
          name: 'Battery',
          type: 'area',
          data: this.data.bat
        },
        {
          name: 'COP',
          type: 'area',
          data: this.data.cop
        },
        // {
        //   name: 'Volume',
        //   type: 'area',
        //   data: this.data.h_volume1
        // },
        // {
        //   name: 'Volume',
        //   type: 'area',
        //   data: this.data.h_volume1
        // },
   
        ]
    },

  },
  methods: {
    ...mapActions("device", ["getDevice", "setDevice", "getDeviceIFrame"]),
    ...mapActions("event", ["getLocationEventsIndex"]),

    async getDeviceDebug() {
      // await this.setDevice({
      //   device: this.device.device_id,
      //   topic: "getDetails",
      //   message: 1,
      //   qos: 0,
      // });
      // if (this.mqttResponse.status == false) {

        this.$emit("refreshDevice");
        // await this.getDevice(this.device);

        //console.log("this.powerDevice: " , this.device);
        // this.device = this.powerDevice;
        // this.device.debug = this.device.status;

      // } else {
      //   try {
      //    this.device.debug = JSON.parse(this.mqttResponse.status);
      //   } catch (e) {
      //     //
      //   }
      // }
    },
    async setAutoRefresh() {
      if (this.autoRefresh) {
        this.$store.myInterval = setInterval(async () => {
          this.getDeviceDebug();
          //console.log("Interval heatmeter")
        }, 5000);
      } else {
        this.IntervalOff();
      }
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.myInterval)) {
          clearInterval(this.$store.myInterval);
          this.$store.myInterval = undefined;
          this.autoRefresh = false;
        }
      } catch (e) {
        //
      }
    },
    async exportXls() {
      const listDevices = [  'A8CAD4D108F0',  '68B4F0BF713C',  '805BF33A7D80',  '9CD3AABF713C',  '24F9F0BF713C',  'FC62D4D108F0',  '98ABEBA4AE30',  'B464D4D108F0',  'D8CB16286F24',  'AC25D4D108F0',  '9CD8AABF713C',  '24D3D4D108F0',  '6000ABBF713C',  '30F9AABF713C',  '089D9AC40A24',  '14A9F33A7D80',  '4C9F0ABF713C',  '387CA2C40A24',  'CCABEBA4AE30',  '90479CC40A24',  'EC13F3A4AE30',  '7C9B9AC40A24',  '8065A3C40A24',  'D8FEAABF713C',  'D8ADF33A7D80',  'F8EEAABF713C',  '30E5AABF713C',  '28EEAABF713C',  '981E9EC40A24',  '10B184A4AE30',  'B8469DBF713C',  'C8AD42A4AE30',  '9010D5AB6224'];
      let debugList = [];
      for (let deviceId of listDevices) {
        const device = this.devices.find(obj => { return obj.device_id == deviceId});
        if (device) {
          debugList.push({"device_id": deviceId, "debug": device.debug});
        }
      }
      let fileName = "EM_debug.xlsx";

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet", {
        properties: { tabColor: { argb: "FFC0000" } },
      });
      worksheet.columns = [
        { header: 'DeviceId', key: 'device_id', width: 20 },
        { header: 'Debug', key: 'debug', width: 100 },
      ];
      worksheet.addRows(debugList);
      
      const buffer = await workbook.xlsx.writeBuffer();
      var blob = new Blob([buffer], {
        type: "application/msexcel",
      });

      saveAs(blob, fileName);
    },

    loadEvent: async function () {

      // if (!this.loadingEvent) {
      //   this.loadingEvent = true;
        //console.log("LOAD EVENT 1 ", this.parentDevice);
        // this.device = {};
        // this.parentDevice.from = this.startDate;
        // await this.getDevice(this.parentDevice);
        // this.device = this.powerDevice;
        // console.log("LOAD EVENTS 2 ", this.device);
      await this.getLocationEventsIndex({
        deviceId: this.device._id,
        // days: 1
        startDate: this.startDate,
        endDate: this.endDate,
      });
        // this.changeResolution(this.resolution);
        // this.loadingEvent = false;
      // }
      let data = {};
      data.h_power1 = [];
      data.bat = [];
      data.h_flow1 = [];
      data.h_temp1 = [];
      data.h_temp2 = [];
      data.h_volume1 = [];
      data.value_positive = [];
      data.value_total = [];
      data.value_negative = [];
      data.value_total2 = [];
      data.cop = [];

      for (let el of this.allEvents) {
        const _time = this.$moment(el.raw.time);        
        const _time_x = parseInt(_time.format("x"));
        
        data.h_power1.push([_time_x, (el.raw.h_power1 / 1000).toFixed(1)]);
        data.bat.push([_time_x, (el.raw.bat).toFixed(2)]);
        data.h_flow1.push([_time_x, (el.raw.h_flow1 / 1000).toFixed(1)]);
        data.h_temp1.push([_time_x, (el.raw.h_temp1 / 1000).toFixed(1)]);
        data.h_temp2.push([_time_x, (el.raw.h_temp2 / 1000).toFixed(1)]);
        data.h_volume1.push([_time_x, (el.raw.h_volume1 / 1000).toFixed(1)]);
        data.value_positive.push([_time_x, (el.value_positive / 1000).toFixed(1)]);
        data.value_total.push([_time_x, (el.value_total / 1000).toFixed(1)]);
        data.value_negative.push([_time_x, (el.value_negative / 1000).toFixed(1)]);
        data.value_total2.push([_time_x, (el.value_total2 / 1000).toFixed(1)]);

        //console.log(typeof el.raw.cop);
        if (typeof el.raw.cop == "number") {
          if (el.raw.cop < 8) {
            //console.log("COP Object: ", el.raw.cop);
            data.cop.push([_time_x, (el.raw.cop).toFixed(2)]);
          } else {
            data.cop.push([_time_x, 0]);
          }
        } else {
          data.cop.push([_time_x, 0]);
        }
      }
      this.data = data;
      // console.log("event loaded ");
    },
  },
  watch: {
    async device(item) {
      //console.log("device changed ", this.loadingEvent);
      this.device_local = JSON.parse(JSON.stringify(this.device));
      // for (const [key, value] of Object.entries(this.device_local.raw)) {
      //   // console.log(key, value);
      //   this.device_local[key] = value;
      // }
      // console.log("Device watch: ", this.device_local);
      await this.loadEvent();

      this.$refs.myChart.hideSeries(["Flow"]);
      this.$refs.myChart.hideSeries("Battery");
      this.$refs.myChart.hideSeries("Temp 1");
      this.$refs.myChart.hideSeries("Temp 2");
      this.$refs.myChart.hideSeries("Volume");
      this.$refs.myChart.hideSeries("Heat Sum");
      this.$refs.myChart.hideSeries("COP");

      if (!item.debug && item.status) {
        item.debug = item.status;
      }
      await this.getDeviceIFrame(this.device);


    },
    async startDate() {
      // this.applyDateRange();
      if (this.$moment(this.endDate, "YYYY-MM-DD").diff(this.startDate, "days") > 31) {
        let _endDate = this.$moment(this.startDate, "YYYY-MM-DD").add(31, "days");
        this.endDate = _endDate.format("YYYY-MM-DD");
      }
      
      await this.loadEvent();
      this.$refs.myChart.hideSeries(["Flow"]);
      this.$refs.myChart.hideSeries("Battery");
      this.$refs.myChart.hideSeries("Temp 1");
      this.$refs.myChart.hideSeries("Temp 2");
      this.$refs.myChart.hideSeries("Volume");
      this.$refs.myChart.hideSeries("Heat Sum");
      this.$refs.myChart.hideSeries("COP");
    },
    async endDate() {
      if (this.$moment(this.endDate, "YYYY-MM-DD").diff(this.startDate, "days") > 31) {
        let _startDate = this.$moment(this.endDate, "YYYY-MM-DD").subtract(31, "days");
        this.startDate = _startDate.format("YYYY-MM-DD");
      }
      // this.applyDateRange();
      //console.log("end Date");
      await this.loadEvent();
      // this.$refs.myChart.hideSeries('Power');
      this.$refs.myChart.hideSeries(["Flow"]);
      this.$refs.myChart.hideSeries("Battery");
      this.$refs.myChart.hideSeries("Temp 1");
      this.$refs.myChart.hideSeries("Temp 2");
      this.$refs.myChart.hideSeries("Volume");
      this.$refs.myChart.hideSeries("Heat Sum");
      this.$refs.myChart.hideSeries("COP");
    },
  }
};
</script>

<style>
.box {
  border: solid 1px red;
}
</style>
