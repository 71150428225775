import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  statusUpdate: void 0,
  statusDelete: void 0,
  statusHistory: void 0,
  statusCreateProvider: void 0,
  statusDeactivate: void 0,
  statusSend: void 0,
  statusSendProviderAll: void 0,
  Invoice: []
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
