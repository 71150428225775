import api from "../../../api";

export default {
  async getDeviceSetting({ commit }) {
    commit("REQUEST_DEVICE_SETTING");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEVICE_SETTING",
        await api.getDeviceSetting({})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DEVICE_SETTING");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateDeviceSetting({ commit }, item ) {
    commit("REQUEST_UPDATE_DEVICE_SETTING");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_DEVICE_SETTING",
        await api.updateDeviceSetting(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_DEVICE_SETTING");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createDeviceSetting({ commit }, item ) {
    commit("REQUEST_CREATE_DEVICE_SETTING");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_DEVICE_SETTING",
        await api.createDeviceSetting( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_DEVICE_SETTING");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
