import api from "../../../api";
import router from "../../../router";
import types from "./types";

export default {
  async signUp({ commit }, { email, password, displayname, dsgvo }) {
    commit("REQUEST_SIGNUP");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SIGNUP",
        await api.signUp({ email, password, displayname, dsgvo })
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SIGNUP");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async activateAccount({ commit }, { token }) {
    commit(types.REQUEST_ACTIVATE_ACCOUNT);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_ACTIVATE_ACCOUNT,
        await api.activateAccount({ token })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_ACTIVATE_ACCOUNT);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async forgotPassword({ commit }, { email }) {
    commit(types.REQUEST_FORGOT_PASSWORD);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_FORGOT_PASSWORD,
        await api.forgotPassword({ email })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_FORGOT_PASSWORD);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async resetPassword({ commit }, obj) {
    commit(types.REQUEST_RESET_PASSWORD);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_RESET_PASSWORD,
        await api.resetPassword(obj)
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_RESET_PASSWORD);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  // async update({ commit }, { id, email, password, displayname }) {
  //   commit("REQUEST_UPDATE");
  //   commit("SET_LOADING", true, { root: true });
  //   try {
  //     commit(
  //       "SUCCESS_REQUEST_UPDATE",
  //       await api.update({ id, email, password, displayname })
  //     );
  //     router.push({ name: "home" });
  //   } catch (err) {
  //     commit("FAILURE_REQUEST_UPDATE");
  //   } finally {
  //     commit("SET_LOADING", false, { root: true });
  //   }
  // },
  async getUser({ commit }) {
    commit("REQUEST_USERS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_USERS", await api.getUser());
    } catch (err) {
      commit("FAILURE_REQUEST_USERS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async readUser({ commit }, { id }) {
    commit("REQUEST_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_USER", await api.readUser({ id }));
    } catch (err) {
      commit("FAILURE_REQUEST_USER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateUser({ commit }, item) {
    commit("REQUEST_UPDATE_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_USER",
        await api.updateUser(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_USER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createUser({ commit }, item) {
    commit("REQUEST_CREATE_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_USER",
        await api.createUser(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_USER", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
