<template>
  <v-app>
    <div v-if="loading" ></div>
    <!-- class="loading-overlay" -->
    <div v-else ></div>
    <v-navigation-drawer
      v-model="drawer"
      dark
      app
      clipped
      stateless
      width="200"
      v-if="mySelf"
    >
      <!--  <v-toolbar textclass="transparent">
            <v-list class="pt-3">
              <v-list-item avatar>
                <v-list-item-avatar>
                  <img src="/img/icons/android-chrome-512x512.png">
                </v-list-item-avatar>
      
                <v-list-item-content>
                  <v-list-item-title>{{$t('Menu')}}</v-list-item-title>
                </v-list-item-content>
      
              </v-list-item>
            </v-list>
          </v-toolbar>
      -->
      <v-list class="pt-3">
        <!--<v-divider></v-divider>-->

        <v-list-item
          v-for="item in items"
          :key="item.title"
          v-on:click="
            drawer = false;
            pushRoute(item);
          "
          class="pt-2"
        >
          <v-list-item-action>
            <v-icon medium :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="text-capitalize">{{
              $t(item.title)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="pt-2" v-if="mySelf" v-on:click="logout()">
          <v-list-item-action>
            <v-icon medium color="white">logout</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title class="text-capitalize">{{
              $t("Logout")
            }}</v-list-item-title>
            <!--<router-link class="white--text"  to="/login" v-on:click.native="logout()" replace>Logout</router-link>-->
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pt-2">
          <v-list-item-content>
            <v-list-item-title class="caption">{{ 
              sw_name 
            }}</v-list-item-title>
            <v-list-item-title class="caption">{{
              sw_version
            }} {{ 
              developer 
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pt-2">
          <v-list-item-content>
            <v-list-item-title class="caption">{{
              web
            }}</v-list-item-title>
            <v-list-item-title class="caption">{{
              email
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-card flat>
      <!-- img="./img/tagger1.jpg" > -->
      <v-row  class="pb-2 pt-3">
        <v-col  cols="12" class="pl-1 ml-1 pr-1 mr-1" v-if="mySelf">
          
          <v-card class="card--flex-toolbar" flat>
            <v-app-bar
                dense
                clipped-left
                fixed
                app
                color="primary"
                flat
              >
            <!-- <v-toolbar clipped-left fixed app color="primary"> -->
              <v-icon large color="black" v-on:click="drawer = !drawer"
                >menu</v-icon
              >
              <!--<v-icon large :color="actualRoute($route.path).color" >{{ actualRoute($route.path).icon }}</v-icon>-->
              <!-- <v-toolbar-title class="white--text text-capitalize">{{
                $t($route.name)
              }}</v-toolbar-title> -->
              <font
                  class="white--text ml-5"
                  face="Lucida Grande"
                  size="4px"
                  color="#FF7A59"
                  >{{
                $t($route.name)
              }}</font
                >
              <v-spacer></v-spacer>
              <!--<router-link class="white--text" v-if="mySelf" to="/login" v-on:click.native="logout()" replace>Logout</router-link> -->
              <v-progress-circular
                color="blue"
                :indeterminate="loading"
                :size="30"
                :width="6"
                class="ma-5"
              ></v-progress-circular>
            </v-app-bar>
            <v-main dark>
              <router-view></router-view>
            </v-main>
          </v-card>
        </v-col>
        <v-col  cols="12" class="pl-1 ml-1 pr-1 mr-1" v-else>
          <v-card class="card--flex-toolbar" flat>
            <v-app-bar
                dense
                clipped-left
                fixed
                app
                color="primary"
                flat
              >
              <img height="45px" src="/sit72.png" />
              <!-- <v-toolbar-title class="white--text text-capitalize"
                >Schwarzl IT - Smart Energy Management System</v-toolbar-title
              > -->
              <font
                  class="secondary--text ml-5"
                  face="Lucida Grande"
                  size="4px"
                  color="#FF7A59"
                  >Schwarzl IT - Smart Energy Management System</font>
              <v-spacer></v-spacer>
              <router-link
                class="white--text"
                v-if="mySelf"
                to="/login"
                v-on:click.native="logout()"
                replace
                >Logout</router-link
              >
            </v-app-bar>
            <v-progress-linear
              color="blue"
              :active="loading"
              :height="5"
              class="ma-0"
              indeterminate
            ></v-progress-linear>
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import packageJson from "../package.json";

export default {
  name: "App",
  data() {
    return {
      drawer: false,
      items: [],
      //mini: true,
      right: null,
      sw_name: "Smart Energy Management",
      sw_version: "V" + packageJson.version, //"V1.7.33",
      developer: "Schwarzl IT",
      web: "www.schwarzl-it.com",
      email: "office@schwarzl-it.com"
    };
  },
  mounted() {
    //this.items = []
    //console.log("App Mounted");
    this.getMySelf();
    this.$store.authInterval = setInterval(async () => {
      //console.log("Auth Check Interval");
      this.getMySelf();
    }, 30000);
    // this.initData();
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
    }),
  },
  watch: {
    mySelf(value) {
      //console.log("Check permission");
      if (!value) {
        //console.log("No mySelf!");
        this.IntervalOff();
        
        this.$router.push({ name: "login" });
      }   
      this.items = [];
      try {
        // console.log(this.mySelf.roles)
        // console.log(this.mySelf.permissions)
        if (this.mySelf.roles.includes("superUser") || this.mySelf.roles.includes("user")) {
          if (!_.find(this.items, { title: "home" })) {
            const route = _.find(this.$router.options.routes, {
              name: "home",
            });
            this.items.push({
              title: route.name,
              icon: route.icon,
              color: route.color,
              path: route.path,
            });
          }
          if (!_.find(this.items, { title: "user" })) {
            const route = _.find(this.$router.options.routes, {
              name: "user",
            });
            this.items.push({
              title: route.name,
              icon: route.icon,
              color: route.color,
              path: route.path,
            });
          }
        }
        else if (this.mySelf.roles.includes("admin")) {
          this.$router.options.routes.forEach((route) => {
            if (!_.isUndefined(route.icon)) {
              if (!(route.name === "admin") && !(route.name === "location")) {
                this.items.push({
                  title: route.name,
                  icon: route.icon,
                  color: route.color,
                  path: route.path,
                });
              }
            }
          });
          if (!_.find(this.items, { title: "admin" })) {
            const route = _.find(this.$router.options.routes, {
              name: "admin",
            });
            this.items.push({
              title: route.name,
              icon: route.icon,
              color: route.color,
              path: route.path,
            });
          }
          if (!_.find(this.items, { title: "location" })) {
            const route = _.find(this.$router.options.routes, {
              name: "location",
            });
            this.items.push({
              title: route.name,
              icon: route.icon,
              color: route.color,
              path: route.path,
            });
          }
          
        } else if (this.mySelf.permissions.includes("project")) {
          this.$router.options.routes.forEach((route) => {
            if (!_.isUndefined(route.icon)) {
              if (
                !(route.name === "admin") &&
                !(route.name === "location") &&
                !(route.name === "device") &&
                !(route.name === "contract") &&
                !(route.name === "user")
              ) {
                this.items.push({
                  title: route.name,
                  icon: route.icon,
                  color: route.color,
                  path: route.path,
                });
              }
            }
          });
        }
      } catch {
        //
      }
    },
  },
  destroyed() {
    this.IntervalOff();
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),
    // ...mapActions("global", ["getGlobal"]),
    // async initData() {
    //   await this.getGlobal({});
    // },
    pushRoute(item) {
      let item_path = item.path;
      item_path = item_path.slice(1, item.path.length);
      //this.mini = true
      //this.drawer = false
      this.$router.push(item_path);
    },
    actualRoute(path) {
      let getRoute = _.find(this.items, { path });
      if (_.isUndefined(getRoute)) {
        getRoute = {
          color: "",
          icon: "",
          name: "",
        };
      }
      return getRoute;
    },
    IntervalOff() {
      try {
        if (!_.isUndefined(this.$store.authInterval)) {
          clearInterval(this.$store.authInterval);
          this.$store.authInterval = undefined;
        }
      } catch (e) {
        //
      }
    },
  },
};
</script>

<!-- .body {
  
  overflow-y: auto !important; // override Vuetify's default style

}

.html {
  
  overflow-y: auto !important; // override Vuetify's default style

}
body {
  
  overflow-y: auto !important; // override Vuetify's default style

}
html {
  
  overflow-y: auto !important; // override Vuetify's default style
} -->

<style>
.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}

.loading-overlay {
  position: fixed;
  z-index: 9997;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: black;
  opacity: 0.05;
}

.alertStyle {
  position: absolute;
  bottom: 5%;
  
}
</style>
