<template>
  <v-container  fluid >
    <v-row align-center >
      <v-col  md="2" cols="12"  v-if="GlobalAll">
        <v-select
          :items="deviceTypes"
          item-text="value"
          item-value="value"
          label="DeviceType"
          v-model="deviceType"
          return-object
        ></v-select>
      </v-col>
      <v-col  md="2" cols="12"  v-if="GlobalAll && deviceType">
        <v-select
          :items="deviceType.sub"
          item-text="value"
          item-value="value"
          label="DeviceTypeSub"
          v-model="deviceTypeSub"
          return-object
        ></v-select>
      </v-col>
      <v-col md="3" cols="12"  v-if="GlobalAll && deviceTypeSub">
        <!-- :items="deviceTypeSub.devices" -->
        <v-select     
          :items="selectedDevices"
          item-text="name"
          item-value="_id"
          return-object
          v-model="device"
          label="Select a Device"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="deviceTypeSub.value == 'electricity meter' && device">
        <HomeElectricityMeter :parentDevice="device" @refreshDevice="refreshDevice"></HomeElectricityMeter>
      </v-col>
      <v-col   v-else-if="deviceTypeSub.value == 'flow control' && device">
        <HomeFlowControl :device="device" @refreshDevice="refreshDevice"></HomeFlowControl>
      </v-col>
      <v-col   v-else-if="deviceTypeSub.value == 'heat control' && device">
        <HomeHeatControl :device="device" @refreshDevice="refreshDevice"></HomeHeatControl>
      </v-col>
      <v-col   v-else-if="deviceTypeSub.value == 'heat meter' && device">
        <HomeHeatMeter :device="device" :Locations="Locations" @refreshDevice="refreshDevice"></HomeHeatMeter>
      </v-col>
      <v-col   v-else-if="deviceTypeSub.value == 'display' && device">
        <HomeSensorDisplay :device="device" @refreshDevice="refreshDevice"></HomeSensorDisplay>
      </v-col>
      <v-col  v-else-if="deviceTypeSub.value == 'air control' && device">
        <HomeAirControl :device="device" @refreshDevice="refreshDevice"></HomeAirControl>
      </v-col>
      <v-col   v-else-if="deviceTypeSub.value == 'LowGhz Module' && device">
        <HomeSensor :device="device" @refreshDevice="refreshDevice"></HomeSensor>
      </v-col>
      <v-col   v-else-if="deviceTypeSub.value == 'pvTracker' && device">
        <HomePvTracker :device="device" @refreshDevice="refreshDevice"></HomePvTracker>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import HomeElectricityMeter from "./HomeElectricityMeter.vue";
import HomeFlowControl from "./HomeFlowControl.vue";
import HomeHeatControl from "./HomeHeatControl.vue";
import HomeHeatMeter from "./HomeHeatMeter.vue";
import HomeSensorDisplay from "./HomeSensorDisplay.vue";
import HomeAirControl from "./HomeAirControl.vue";
import HomeSensor from "./HomeSensor.vue";
import HomePvTracker from "./HomePvTracker.vue";

export default {
  name: "home",
  components: {
    HomeElectricityMeter,
    HomeFlowControl,
    HomeHeatControl,
    HomeHeatMeter,
    HomeSensorDisplay,
    HomeAirControl,
    HomeSensor,
    HomePvTracker,
  },
  data() {
    return {
      deviceType: "",
      deviceTypeSub: "",
      devices: [],
      device: null,
      statusDownload: "",
      deviceTypes: [],
      selectedDevices: [],
    };
  },
  async mounted() {
    //console.log("Start Home");

    await this.initData(); 

    let _deviceType = _.find(this.deviceTypes, { value: "heat" });
    if (_deviceType) {
      this.deviceType = _deviceType;
    } else {
      this.deviceType = this.deviceTypes[0];
    }
    
   
    //this.deviceType = _.find(this.deviceTypes, {value: power});
  },
  computed: {
    ...mapState({
      statusMySelf: ({ auth }) => auth.statusMySelf,
      GlobalAll: ({ global }) => global.GlobalAll,
      powerDevicesOnly: ({ device }) => device.powerDevicesOnly,
      Locations: ({ location }) => location.Locations,
      powerDevice: ({ device }) => device.powerDevice,
      loading: state => state.loading,
    }),


  },
  methods: {
    ...mapActions("device", ["getPowerDevicesOnly", "getDevice"]),
    ...mapActions("global", ["getGlobalAll"]),
    ...mapActions("location", ["getLocation"]),
    ...mapActions(
      "global", ["getGlobal"]
      ),

    async initData() {    
      // let _time = new Date();
     
      await this.getGlobalAll({});
      await this.getGlobal({});
      // if (this.GlobalAll.length == 0) {
      //   console.log("Redo Get Global");
      //   await this.getGlobalAll({});
      // }
      //console.log("Got Global");
      // console.log(new Date() - _time) 
      //_time = new Date();
      await this.getLocation({})
      if (this.Locations.length == 0) {
        console.log("Redo Get Location");
        await this.getLocation({});
      }

      //console.log("Got Location");
      // console.log("Long: ", new Date() - _time) 
     

      // Get all DeviceTypes
      let deviceTypes = _.filter(this.GlobalAll, {
        typeOf: "deviceTypes",
        parent: null,
      });
      // console.log("Got All deviceTypes");

      // Get all SubDeviceTypes
      // _time = new Date();
      for (let deviceType of deviceTypes) {
        const deviceSubTypes = _.filter(this.GlobalAll, {
          typeOf: "deviceTypes",
          parent: deviceType._id,
        });
        
        // for (let deviceSubType of deviceSubTypes) {
          // await this.getPowerDevicesOnly({ type: deviceSubType.value });
          // deviceSubType.devices = _.sortBy( this.powerDevicesOnly, "name");
        // }
        deviceType.sub = _.sortBy(deviceSubTypes, "value");
      }
      //console.log("Got All SubDeviceTypes");
      // console.log("Longest: ",new Date() - _time) 
      // _time = new Date();
     
      //Remove all subs with no devices
      // for (let deviceType of deviceTypes) {
      //   if (deviceType.sub) {
      //     //console.log(deviceType.sub);
      //     deviceType.sub = deviceType.sub.filter( obj => {
      //       //console.log(obj.devices.length > 0);
      //       if (obj.devices.length > 0) {
      //         return 1;
      //       } else {
      //         return 0;
      //       }
      //     })
      //   }
      // }
     

      //Remove all with no subs
      deviceTypes = deviceTypes.filter( obj => {
        if (obj.sub) {
          if (obj.sub[0]) {
            return 1;
          } 
        }
        // console.log(obj, _return);
        return 0;
      })
     

      // for (let deviceType of deviceTypes) {
        
      // }
     
      this.deviceTypes = _.sortBy(deviceTypes, "value");
     

    },

    async refreshDevice() {
      // console.log("refreshDevice");

      await this.getDevice(this.device);
      // console.log("refreshDevice", this.powerDevice, this.device);
      this.device = this.powerDevice;
      // console.log("this.device: " , this.device);
    },

   
  },
  watch: {
    async deviceType(item) {
      // console.log("Choose sub:", item.sub)
      this.deviceTypeSub = item.sub[0];
    },
    async deviceTypeSub(item) {
      // console.log("Choose device:", item);
      if (!item.devices) {
        // let _time = new Date();
        //console.log("Load devices");
        await this.getPowerDevicesOnly({ type: item.value });
        item.devices = _.sortBy( this.powerDevicesOnly, "name");
        //console.log(item.devices)
        // console.log("getPowerDevicesOnly: ",new Date() - _time) 
      }
      this.device = null;
      this.selectedDevices = item.devices;
      if (item.devices) {
        if (item.devices.length == 1) {
          this.device = item.devices[0];
        } 
        else if (item.devices.length == 0) {
          //No Heat Device found -> switch to electricity meter
          //console.log("No Heat Device found ->  this.deviceTypes ", this.deviceTypes)

          // this.deviceType = this.deviceTypes[0];
          this.deviceType = this.deviceTypes.find(obj => {
            return obj.value == "power";
          })
        }
      } 
    },
  },
};
</script>

<style>

/* .loading-overlay {
  position: fixed;
  z-index: 9997;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: black;
  opacity: 0.02;
} */

</style>