import api from "../../../api";

export default {
  async getPriceIndex({ commit }) {
    commit("REQUEST_PRICE_INDEX");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_PRICE_INDEX",
        await api.getPriceIndex({})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_PRICE_INDEX");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updatePriceIndex({ commit }, item ) {
    commit("REQUEST_UPDATE_PRICEINDEX");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_PRICEINDEX",
        await api.updatePriceIndex(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_PRICEINDEX");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createPriceIndex({ commit }, item ) {
    commit("REQUEST_CREATE_PRICEINDEX");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_PRICEINDEX",
        await api.createPriceIndex( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_PRICEINDEX");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
