<template>
  <div>
    <v-row style="min-height: 50px">
      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.startDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="Start Date"
              :value="startDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
        </template>
          <v-date-picker
            @input="
              () => {
                modals.startDate = false;
              }
            "
            min="2020-01-01"
            v-model="startDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.startDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col  md="2" cols="12"  >
        <v-dialog
          :disabled="!maxDate"
          persistent
          v-model="modals.endDate"
          lazy
          full-width
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="!maxDate"
              v-on="on"
              label="End Date"
              :value="endDate"
              prepend-icon="event"
              :rules="[(v) => !!v || 'required']"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              () => {
                modals.endDate = false;
              }
            "
            :min="startDate"
            :max="maxDate"
            v-model="endDate"
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn textinfo @click="modals.endDate = false">OK</v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row
      style="min-height: 50px"
      v-if="device"
  
    >
      <v-col  md="4" cols="12"  >
        <v-card style="min-height: 200px">
          <v-toolbar prominent class="lightgrey">
            <v-spacer></v-spacer>

            <v-btn
              text
              color="accent"
              @click="
                dayOfWeekChart.filterAll();
                dc.redrawAll();
              "
              >reset</v-btn
            >
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="downloadCSV(groups.weekOfYearGroup.all())">
                  <v-list-item-title>Export to CSV...</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-card-text>
            <v-row class="align-center" style="min-height: 100px">
              <div id="day-of-week-chart"></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col  md="4" cols="12"  >
        <v-card style="min-height: 200px">
          <v-toolbar prominent class="lightgrey">
            <v-spacer></v-spacer>
            <v-btn
              text 
              color="accent"
              @click="
                weekOfYearChart.filterAll();
                dc.redrawAll();
              "
              >reset</v-btn
            >
            <v-btn icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="align-center" style="min-height: 100px">
              <div id="week-of-year-chart"></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col  cols="12" >
        <v-card style="min-height: 200px">
          <v-toolbar prominent class="lightgrey">
            <v-select
              hide-details
              label="Select Resolution"
              :items="resolutionOptions"
              v-model="resolution"
              return-object
              @change="changeResolution"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="accent"
              @click="
                volumeChart.filterAll();
                dc.redrawAll();
              "
              >reset</v-btn
            >
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="downloadCSV(monthlyMoveCategoryFilteredData())"
                >
                  <v-list-item-title>Export to CSV...</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <v-card-text>
            <v-row
              class="align-center justify-center"
              style="min-height: 150px"
            >
              <div id="monthly-move-chart"></div>
            </v-row>
            <v-row
              class="align-center justify-center"
              style="min-height: 30px"
            >
              <div id="monthly-volume-chart"></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
 </div>
</template>

<script>
import * as dc from "dc";
import * as d3 from "d3";
import "dc/dc.min.css";
import "../assets/dc.custom.css";
import crossfilter from "crossfilter2";
import { mapActions, mapState } from "vuex";
import { downloadFile } from "../utils/files";
import { time6hour, time15minutes } from "../components/chart/utils";
import _ from "lodash";
//import { NullEngine } from 'babylonjs/index';


export default {
  name: "CrossFilterComponent",
  props: { parentDevice: null },
  components: {
  },
  data() {
    return {
      loadingEvent: false,
      device: {},
      statusDownload: "",
      resolutionAllOptions: ["1d", "6h", "1h", "15m"],
      resolution: "1d",
      modals: {
        startDate: false,
        endDate: false,
      },
      startDate: void 0,
      endDate: void 0,
      maxDate: void 0,
      dc: dc,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;

    this.ndx = crossfilter();

    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 7 * 1000 * 86400);

    this.startDate = startDate.toISOString().slice(0, 10);
    this.endDate = endDate.toISOString().slice(0, 10);
    this.maxDate = this.endDate;
  },
  async mounted() {

  },
  methods: {
    ...mapActions("device", [
      "getDevice",
    ]),
    ...mapActions("event", ["getDeviceEvents"]),

    loadEvent: async function () {
      if (!this.loadingEvent) {
        this.loadingEvent = true;
        //console.log("LOAD EVENT 1 ", this.parentDevice);
        this.device = {};
        this.parentDevice.from = this.startDate;
        await this.getDevice(this.parentDevice);
        this.device = this.powerDevice;
        //console.log("LOAD EVENTS 2 ", this.device);
        await this.getDeviceEvents({
          deviceId: this.device._id,
          // days: 1
          startDate: this.startDate,
          endDate: this.endDate,
        });
        this.changeResolution(this.resolution);
        this.loadingEvent = false;
      }
    },
    downloadCSV: async function (data) {
      //https://github.com/d3/d3-dsv#dsv_format

      let blob = new Blob([d3.csvFormat(data)], {
        type: "text/csv;charset=utf-8",
      });

      const fileName = `${this.device.name || "device"}.csv`;

      try {
        await downloadFile(blob, fileName, "text/csv;charset=utf-8");
      } catch (e) {
        this.statusDownload = "failure";
        await setTimeout(() => {
          this.statusDownload = "";
        }, 5000);
      }
    },
    monthlyMoveCategoryFilteredData: function () {
      const columns = {
        _id: "date",
        total: this.device.type + " sum",
      };
      const data = [];
      this.ndx.allFiltered().map((d) => {
        const row = {};
        for (let key in columns) {
          row[columns[key]] = d[key];
        }
        data.push(row);
      });
      return data;
    },
    clearCharts: function () {
      this.volumeChart.filterAll();
      this.compositeChart.filterAll();
      this.dayOfWeekChart.filterAll();
      this.weekOfYearChart.filterAll();
      this.ndx.remove();
    },
    rebuildCrossFilterWithEvents: function (data, device) {
      if (data[0]) {
        //console.log(`rebuild ${JSON.stringify(data[0])} and ${data.length} more...`);
        data.forEach(function (d) {
          d.dd = new Date(d._id);
          d.month = d3.timeMonth(d.dd);
          d.week = d3.timeWeek(d.dd);
          d.day = d3.timeDay(d.dd);
          d.hour = d3.timeHour(d.dd);
          d.minute = d3.timeMinute(d.dd);
          d.category = device.type;
          // d.total_negative = d.total_positive;
          // d.total = d.total * 0.5;
        });

        //filter out invalid dates
        const maxDate = new Date().getTime();
        const filtered = data.filter((d) => d.dd.getTime() < maxDate);

        this.ndx.add(filtered);
      }
    },
    changeResolution: function (resolution) {
      const cb = [
        // callback for when data is added to the current filter results
        (p, v) => {
          p.sum += v.total;
          p.sum_positive += v.total_positive;
          p.sum_negative += v.total_negative;
          return p;
        },
        // callback for when data is removed from the current filter results
        (p, v) => {
          p.sum -= v.total;
          p.sum_positive -= v.total_positive;
          p.sum_negative -= v.total_negative;
          return p;
        },
        // callback for initialize p
        () => ({
          sum: 0,
          sum_positive: 0,
          sum_negative: 0,
        }),
      ];

      const children = this.compositeChart.children();

      let timeInterval = d3.timeDay;
      let keyAccessor = (d) => d.key.toLocaleDateString();

      switch (resolution) {
        case "15m":
          timeInterval = time15minutes;
          keyAccessor = (d) => d.key.toLocaleTimeString();
          break;
        case "1h":
          timeInterval = d3.timeHour;
          keyAccessor = (d) => d.key.toLocaleTimeString();
          break;
        case "6h":
          timeInterval = time6hour;
          keyAccessor = (d) => d.key.toLocaleTimeString();
          break;
        case "1d":
          timeInterval = d3.timeDay;
          break;
        default:
          timeInterval = d3.timeDay;
          break;
      }

      this.groupFunc = (d) => timeInterval(d);
      this.groups.monthlyMoveCategoryGroup.dispose();
      this.groups.monthlyMoveCategoryGroup = this.dimensions.moveMonths
        .group(this.groupFunc)
        .reduce(cb[0], cb[1], cb[2]);

      // const xUnits = (start, end) => d3.timeMinute.range(start, end, 15);

      this.compositeChart.round(timeInterval.round).xUnits(timeInterval.range);
      this.compositeChart.keyAccessor((d) => d.key.toLocaleTimeString());

      this.volumeChart.group(this.groups.monthlyMoveCategoryGroup, "Power");
      this.volumeChart.round(timeInterval.round).xUnits(timeInterval.range);

      children[0].group(this.groups.monthlyMoveCategoryGroup, "Consumption");
      children[1].group(this.groups.monthlyMoveCategoryGroup, "Production");
      children[2].group(this.groups.monthlyMoveCategoryGroup, "Total");

      this.volumeChart.render();
      this.compositeChart.render();
    },
    applyDateRange() {
      const firstDate = new Date(this.startDate);
      const lastDate = new Date(this.endDate);
      this.compositeChart.x(d3.scaleTime().domain([firstDate, lastDate]));
      this.volumeChart.x(d3.scaleTime().domain([firstDate, lastDate]));

      dc.redrawAll();
    },
    handleResize() {
      //this.clearCharts();
      let resize_factor = 0;
      let resize_factor2 = 0;
      if (this.window.width < 600) {
        resize_factor = 0.5;
        resize_factor2 = 0.6;
      } else if (this.window.width >= 600 && this.window.width < 960) {
        resize_factor = 0.3;
        resize_factor2 = 0.8;
      } else {
        resize_factor = 0.25;
        resize_factor2 = 0.8;
      }
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.compositeChart.width(this.window.width * resize_factor2);
      this.compositeChart.legend(
        dc
          .legend()
          .x(this.window.width - this.window.width * (1 - resize_factor2) - 100)
          .y(10)
          .itemHeight(13)
          .gap(5)
      );
      this.volumeChart.width(this.window.width * resize_factor2);
      this.dayOfWeekChart.width(this.window.width * resize_factor);
      this.weekOfYearChart.width(this.window.width * resize_factor);
      dc.renderAll();
    },
    async initCharts() {
      // ### Create Chart Objects

      // Create chart objects associated with the container elements identified by the css selector.
      // Note: It is often a good idea to have these objects accessible at the global scope so that they can be modified or
      // filtered by other page controls.

      const dataCount = dc.dataCount("#data-count");
      this.dataCount = dataCount;

      const dayOfWeekChart = dc.rowChart("#day-of-week-chart");
      this.dayOfWeekChart = dayOfWeekChart;

      const weekOfYearChart = dc.rowChart("#week-of-year-chart");
      this.weekOfYearChart = weekOfYearChart;

      const compositeChart = dc.compositeChart("#monthly-move-chart");
      this.compositeChart = compositeChart;

      const volumeChart = dc.barChart("#monthly-volume-chart");
      this.volumeChart = volumeChart;

      //const debugValuesChart = dc.lineChart("#debug-values-chart");
      //this.debugValuesChart = debugValuesChart;

      const ndx = this.ndx;

      const all = ndx.groupAll();
      // const allusage = ndx.groupAll().reduceSum(function(d) {
      //   return d.usage;
      // });

      this.dataCount.dimension(ndx).group(all).html({
        some: "%filter-count out of %total-count records selected",
        all: "All records selected. Click on charts to apply filters",
      });

      // Dimension by full date
      // const dateDimension = ndx.dimension(d => d.dd); // Dimension by month

      /*                // Dimension by month
                  var moveMonths = ndx.dimension(function (d) {
                      return d.month;
                  });
  */ const moveMonths = ndx.dimension((d) => d.minute); // Group by total movement within month
      /* var monthlyMoveGroup = moveMonths.group().reduceSum(function (d) {
                      return d.usage;
                  });
  */
      this.groupFunc = (d) => d3.timeDay(d);
      const monthlyMoveCategoryGroup = moveMonths.group(this.groupFunc).reduce(
        // callback for when data is added to the current filter results
        (p, v) => {
          p.sum += v.total;
          p.sum_positive += v.total_positive;
          p.sum_negative += v.total_negative;
          return p;
        },
        // callback for when data is removed from the current filter results
        (p, v) => {
          p.sum -= v.total;
          p.sum_positive -= v.total_positive;
          p.sum_negative -= v.total_negative;

          return p;
        },
        // callback for initialize p
        () => ({
          sum: 0,
          sum_positive: 0,
          sum_negative: 0,
        })
      );


      // Counts per weekday
      const dayOfWeek = ndx.dimension((d) => {
        const day = d.dd.getDay();
        const name = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return day + "." + name[day];
      });
      const dayOfWeekGroup = dayOfWeek.group();

      const weekOfYear = ndx.dimension((d) => {
        const week = d3.timeMonday(d.week);
        const yearStart = new Date(Date.UTC(week.getUTCFullYear(), 0, 1));
        return Math.ceil(((week - yearStart) / 86400000 + 1) / 7);
      });
      const weekOfYearGroup = weekOfYear.group();

      this.dimensions = {
        dayOfWeek,
        weekOfYear,
        moveMonths,
      };

      this.groups = {
        dayOfWeekGroup,
        weekOfYearGroup,
        monthlyMoveCategoryGroup,
      };

      //### Define Chart Attributes
      // Define chart attributes using fluent methods. See the
      // [dc.js API Reference](https://github.com/dc-js/dc.js/blob/master/web/docs/api-latest.md) for more information
      //

      //#### Row Chart

      // Create a row chart and use the given css selector as anchor. You can also specify
      // an optional chart group for this chart to be scoped within. When a chart belongs
      // to a specific group then any interaction with such chart will only trigger redraw
      // on other charts within the same chart group.
      // <br>API: [Row Chart](https://github.com/dc-js/dc.js/blob/master/web/docs/api-latest.md#row-chart)
      dayOfWeekChart /* dc.rowChart('#day-of-week-chart', 'chartGroup') */
        .width(0)
        .height(180)
        .margins({ top: 20, left: 10, right: 10, bottom: 20 })
        .group(dayOfWeekGroup)
        .dimension(dayOfWeek)
        .ordinalColors(["#3182bd", "#6baed6", "#9ecae1", "#c6dbef", "#dadaeb"])
        .label(function (d) {
          return d.key.split(".")[1];
        })
        // Title sets the row text
        .title(function (d) {
          return d.value;
        })
        .elasticX(true)
        .xAxis()
        .ticks(4);

      weekOfYearChart
        .width(0)
        .height(180)
        .margins({ top: 20, left: 10, right: 10, bottom: 20 })
        .group(weekOfYearGroup)
        .dimension(weekOfYear)
        // Assign colors to each value in the x scale domain
        .ordinalColors(["#3182bd", "#6baed6", "#9ecae1", "#c6dbef", "#dadaeb"])
        .label(function (d) {
          return d.key + ". Week ";
        })
        // Title sets the row text
        .title(function (d) {
          return d.key;
        })
        .ordering(function (d) {
          return d.key;
        })
        .elasticX(true)
        .xAxis()
        .ticks(4);

      //#### Stacked Area Chart
      const dateFormatSpecifier = "%Y-%m-%dT%H:%M";
      const dateFormat = d3.timeFormat(dateFormatSpecifier);
      const numberFormat = d3.format(".2f");

      const nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      const pastDate = new Date(
        nowDate.getFullYear() - 1,
        nowDate.getMonth(),
        nowDate.getDate()
      );

      compositeChart
        .width(0)
        .height(200)
        .margins({ top: 30, right: 50, bottom: 25, left: 40 })
        //.mouseZoomable(true)
        // Specify a "range chart" to link its brush extent with the zoom of the current "focus chart".
        .rangeChart(volumeChart)
        .x(d3.scaleTime().domain([pastDate, nowDate]))
        .round(d3.timeHour.round) //selection
        .xUnits(d3.timeHours)
        //.xUnits(d3.timeDays)
        .elasticY(true)
        .renderHorizontalGridLines(true)
        .renderVerticalGridLines(true)
        //##### Legend
        // Position the legend relative to the chart origin and specify items' height and separation.
        .legend(dc.legend().x(960).y(10).itemHeight(13).gap(5))
        .brushOn(false)
        .compose([
          dc
            .barChart(compositeChart)
            .dimension(moveMonths)
            // .centerBar(true)
            .group(monthlyMoveCategoryGroup, "positive")
            .ordinalColors(["#ffdf14"])
            .valueAccessor(function (d) {
              return d.value.sum_positive;
            }),
          dc
            .barChart(compositeChart)
            .dimension(moveMonths)
            .ordinalColors(["#339933"])
            // .centerBar(true)
            .group(monthlyMoveCategoryGroup, "Production")
            .valueAccessor(function (d) {
              return d.value.sum_negative;
            }),
          dc
            .lineChart(compositeChart)
            .dimension(moveMonths)
            .group(monthlyMoveCategoryGroup, "total")
            .ordinalColors(["#14a8ff"])
            .valueAccessor(function (d) {
              return d.value.sum;
            }),
        ])
        .renderTitle(true)
        .title(function (d) {
          return (
            dateFormat(d.key) + "\n" + "Power:" + numberFormat(d.value.sum)
          );
        })
        .keyAccessor((d) => {
          return d.key.toLocaleDateString();
        })
        .valueAccessor((d) => {
          return [
            "",
            "Total: " + numberFormat(d.value.sum),
            "Consumption: " + numberFormat(d.value.sum_positive),
            "Production: " + numberFormat(-d.value.sum_negative),
          ].join("\n");
        })
        .xAxis()
        .ticks(this.window.width / 70 - 1);

      //#### Range Chart

      // Since this bar chart is specified as "range chart" for the area chart, its brush extent
      // will always match the zoom of the area chart.
      volumeChart
        .width(0)
        .height(50)
        .elasticY(true)
        .margins({ top: 0, right: 50, bottom: 20, left: 40 })
        .dimension(moveMonths)
        .group(monthlyMoveCategoryGroup, "power")
        .valueAccessor(function (d) {
          return d.value.sum;
        })
        // .centerBar(true)
        .gap(1)
        .x(d3.scaleTime().domain([pastDate, nowDate]))
        .round(d3.timeHour.round) //selection
        .xUnits(d3.timeHours)
        // .round(d3.timeDay.round)
        //.xUnits(d3.timeDays)
        .alwaysUseRounding(true)
        .transitionDuration(0)
        .xAxis()
        .ticks(this.window.width / 70 - 1);
      //.yAxis().ticks(0);


      //#### Rendering

      //render all charts on the page
      dc.renderAll();

      this.changeResolution(this.resolution);


      //#### Versions

      //Determine the current version of dc with `dc.version`
      d3.selectAll("#version").text(dc.version);

      // Determine latest stable version in the repo via Github API

      d3.json("https://api.github.com/repos/dc-js/dc.js/releases/latest").then(
        function (latestRelease) {
          //jshint camelcase: false
          //jscs:disable
          d3.selectAll("#latest").text(latestRelease.tag_name);
        }
      );

   
      this.handleResize();
    },
  },
  computed: {
    ...mapState({  
      powerDevice: ({ device }) => device.powerDevice,  
      allEvents: ({ event }) => event.events,
    }),
    events: function () {
      return this.allEvents;
    },
    resolutionOptions: function () {
      return this.resolutionAllOptions;
    },
  },
  watch: {
    parentDevice() {
      //console.log("device changed");
      this.loadEvent();
    },
    startDate() {
      // this.applyDateRange();
      //console.log("Start Date");
      this.loadEvent();
    },
    endDate() {
      // this.applyDateRange();
      //console.log("end Date");
      this.loadEvent();
    },
    events(val) {
      //console.log("EVENTS CHARTS");
      //this.$store.commit('SET_LOADING', true)
      this.initCharts();
      this.clearCharts();

      // this.maxDate = void 0;
      this.rebuildCrossFilterWithEvents(val, this.device);
      const items = this.ndx.all();

      if (items.length > 0) {
        const firstDate = new Date(items[0]._id);
        const lastDate = new Date(items[items.length - 1]._id);

        this.compositeChart.x(d3.scaleTime().domain([firstDate, lastDate]));
        this.volumeChart.x(d3.scaleTime().domain([firstDate, lastDate]));

        // this.startDate = firstDate.toISOString().slice(0, 10);
        // this.endDate = lastDate.toISOString().slice(0, 10);
        this.maxDate = new Date().toISOString().slice(0, 10); //this.endDate;

        const days =
          (new Date(this.endDate) - new Date(this.startDate)) / 864e5;

        if (days <= 2) {
          this.resolutionAllOptions = ["15m", "1h", "1d"];
        } else if (days <= 7) {
          this.resolutionAllOptions = ["1h", "6h", "1d"];
        } else if (days <= 92) {
          this.resolutionAllOptions = ["6h", "1d"];
        } else {
          this.resolutionAllOptions = ["1d"];
        }

        this.resolution = this.resolutionAllOptions[0];
      }
      dc.renderAll();
      dc.redrawAll();
      //this.$store.commit('SET_LOADING', false)
    },
  }
}
</script>


<style>

</style>