import api from "../../../api";

export default {
  async getDeviceCommand({ commit }) {
    commit("REQUEST_DEVICE_COMMAND");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEVICE_COMMAND",
        await api.getDeviceCommand({})
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_DEVICE_COMMAND");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateDeviceCommand({ commit }, item ) {
    commit("REQUEST_UPDATE_DEVICE_COMMAND");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_DEVICE_COMMAND",
        await api.updateDeviceCommand(item)
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_UPDATE_DEVICE_COMMAND");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createDeviceCommand({ commit }, item ) {
    commit("REQUEST_CREATE_DEVICE_COMMAND");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_DEVICE_COMMAND",
        await api.createDeviceCommand( item )
      );
    } catch (err) {
      if (!err.ok && err.status === 401) {
        commit("TO_LOGIN", true, { root: true });
      }
      commit("FAILURE_REQUEST_CREATE_DEVICE_COMMAND");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
