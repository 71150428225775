const REQUEST_POWER_DEVICES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_POWER_DEVICES = (state, { body }) => {
  //console.log("SUCCESS_REQUEST_POWER_DEVICES ", body)
  state.powerDevices = body;
  state.status = "success";
};

const FAILURE_REQUEST_POWER_DEVICES = state => {
  state.status = "failure";
};

const REQUEST_POWER_DEVICESONLY = state => {
  state.statusOnly = "fetching";
};

const SUCCESS_REQUEST_POWER_DEVICESONLY = (state, { body }) => {
  state.powerDevicesOnly = body;
  state.statusOnly = "success";
};

const FAILURE_REQUEST_POWER_DEVICESONLY = state => {
  state.statusOnly = "failure";
};

const REQUEST_UPDATE_DEVICELOCATION = state => {
  state.statusUpdateDeviceLocation = "fetching";
};

const SUCCESS_REQUEST_UPDATE_DEVICELOCATION = state => {
  state.statusUpdateDeviceLocation = "success";
};

const FAILURE_REQUEST_UPDATE_DEVICELOCATION = state => {
  state.statusUpdateDeviceLocation = "failure";
};

const REQUEST_DEVICESALL = state => {
  state.statusDevicesAll = "fetching";
};

const SUCCESS_REQUEST_DEVICESALL = (state, { body }) => {
  state.devicesAll = body;
  state.statusDevicesAll = "success";
};

const FAILURE_REQUEST_DEVICESALL = state => {
  state.statusDevicesAll = "failure";
};

const REQUEST_POWER_DEVICE = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_POWER_DEVICE = (state, { body }) => {
  state.powerDevice = body;
  state.status = "success";
};

const FAILURE_REQUEST_POWER_DEVICE = state => {
  state.status = "failure";
};

const REQUEST_UPDATE_DEVICECONFIG = state => {
  state.statusUpdateDeviceLocation = "fetching";
};

const SUCCESS_REQUEST_UPDATE_DEVICECONFIG = state => {
  state.statusUpdateDeviceLocation = "success";
};

const FAILURE_REQUEST_UPDATE_DEVICECONFIG = state => {
  state.statusUpdateDeviceLocation = "failure";
};

const REQUEST_POWER_DEVICE_DEBUG_VALUES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_POWER_DEVICE_DEBUG_VALUES = (state, { body }) => {
  state.debugValues = body;
  state.status = "success";
};

const FAILURE_REQUEST_POWER_DEVICE_DEBUG_VALUES = state => {
  state.status = "failure";
};

const REQUEST_UPDATE_SETDEVICE = state => {
  state.response = "";
  state.status = "fetching";
};

const SUCCESS_REQUEST_UPDATE_SETDEVICE = (state, { body }) => {
  state.response = body;
  state.status = "success";
};

const FAILURE_REQUEST_UPDATE_SETDEVICE = state => {
  state.status = "failure";
};

const REQUEST_IFRAME_DEVICE = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_IFRAME_DEVICE = (state, { body }) => {
  state.iframe = body;
  state.status = "success";
};

const FAILURE_REQUEST_IFRAME_DEVICE = state => {
  state.status = "failure";
};

export default {
  REQUEST_POWER_DEVICES,
  SUCCESS_REQUEST_POWER_DEVICES,
  FAILURE_REQUEST_POWER_DEVICES,
  REQUEST_POWER_DEVICESONLY,
  SUCCESS_REQUEST_POWER_DEVICESONLY,
  FAILURE_REQUEST_POWER_DEVICESONLY,
  REQUEST_UPDATE_DEVICELOCATION,
  SUCCESS_REQUEST_UPDATE_DEVICELOCATION,
  FAILURE_REQUEST_UPDATE_DEVICELOCATION,
  REQUEST_DEVICESALL,
  SUCCESS_REQUEST_DEVICESALL,
  FAILURE_REQUEST_DEVICESALL,
  REQUEST_POWER_DEVICE,
  SUCCESS_REQUEST_POWER_DEVICE,
  FAILURE_REQUEST_POWER_DEVICE,
  REQUEST_UPDATE_DEVICECONFIG,
  SUCCESS_REQUEST_UPDATE_DEVICECONFIG,
  FAILURE_REQUEST_UPDATE_DEVICECONFIG,
  REQUEST_POWER_DEVICE_DEBUG_VALUES,
  SUCCESS_REQUEST_POWER_DEVICE_DEBUG_VALUES,
  FAILURE_REQUEST_POWER_DEVICE_DEBUG_VALUES,
  REQUEST_UPDATE_SETDEVICE,
  SUCCESS_REQUEST_UPDATE_SETDEVICE,
  FAILURE_REQUEST_UPDATE_SETDEVICE,
  REQUEST_IFRAME_DEVICE,
  SUCCESS_REQUEST_IFRAME_DEVICE,
  FAILURE_REQUEST_IFRAME_DEVICE
};
