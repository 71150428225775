import api from "../../../api";

export default {
  async getMySelf({ commit }) {
    commit("REQUEST_MYSELF");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_MYSELF", await api.mySelf());
    } catch (err) {
      //console.log("FAILURE_REQUEST_MYSELF")
      commit("TO_LOGIN", true, { root: true });
      //commit("FAILURE_REQUEST_MYSELF");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async authenticate({ commit, dispatch }, { email, password }) {
    commit("REQUEST_AUTH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_AUTH",
        await api.authenticate({ email, password })
      );
    } catch (err) {
      commit("FAILURE_REQUEST_AUTH");
    } finally {
      commit("SET_LOADING", false, { root: true });

      dispatch("getMySelf");
    }
  },
  async logout() {
    window.localStorage.removeItem("Authorization");
    window.location.reload();
  }
};
